import React, { forwardRef } from "react"
import FielderTextField from "./FielderTextField"

const phoneInput = (props: any, ref: any) => {
  return (
    <FielderTextField
      {...props}
      fullWidth
      inputProps={{ maxLength: 20 }}
      inputRef={ref}
      type="tel"
    />
  )
}

export default forwardRef(phoneInput)
