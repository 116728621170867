/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
import React, { useState } from "react"
import Cookies from "js-cookie"
import { useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { useDropzone } from "react-dropzone"
import CircularProgress from "@mui/material/CircularProgress"
import Paper, { PaperProps } from "@mui/material/Paper"
import Box from "@mui/material/Box"
import FormHelperText from "@mui/material/FormHelperText"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Chip from "@mui/material/Chip"
import Autocomplete from "@mui/material/Autocomplete"
import difference from "lodash/difference"
import { Descendant } from "slate"
import Draggable from "react-draggable"

import SnackbarMessage from "~/components/SnackbarMessage"
import EmailAttachments from "~/components/EmailAttachments"
import RichTextComposer from "~/components/RichTextEditor/RichTextComposer"
import ErrorMessage from "~/components/ErrorMessage"
import SelectorField from "~/components/SelectorField"
import FielderTextField from "~/components/FielderTextField"
import FieldHelperText from "~/components/FieldHelperText"
import {
  flattenObject,
  isValidEmail,
  resolveTemplate,
  toHtml,
  ONE_MEGABYTE,
  isBlank,
  toText,
  deserializeFullHtml,
  getAddressSummary,
  formatDate,
} from "~/util"
import { GET_DATA_FOR_JOB_EMAIL_TEMPLATES } from "~/queries/getDataForJobEmailTemplates"
import { useAuth } from "~/context/AuthContext"
import {
  Attachment,
  EmailTemplate,
  Invoice,
  Job,
  Estimate,
  TemplateData,
  AttachmentType,
  Snack,
  WorkOrder,
  InboxMessage,
  MessageDirection,
  Checklist,
  MessageParticipant,
  EmailTemplateType,
} from "~/types"
import useGetUserOrganizationPreferences from "~/hooks/useGetUserOrganizationPreferences"
import JobTypeahead from "./JobTypeahead"

const TOTAL_ATTACHMENT_SIZE_LIMIT = 24 * ONE_MEGABYTE // the limit is really 25 MB, but use 24 mb to allow some space for generated PDFs

function getTotalAttachmentSize(attachments: Attachment[]): number {
  return attachments.reduce((acc, a) => acc + (a.sizeInBytes ?? 0), 0)
}

function getMessageParticipantDisplay(participant: MessageParticipant): string {
  return participant.name
    ? `${participant.name} <${participant.messageAddress}>`
    : participant.messageAddress
}

const INITIAL_BODY_VALUE: Descendant[] = [
  {
    type: "paragraph",
    children: [
      {
        text: "",
        type: "paragraph",
      },
    ],
  },
] as Descendant[]

export enum EmailDialogMode {
  COMPOSE_NEW = "COMPOSE_NEW",
  REPLY = "REPLY",
  REPLY_ALL = "REPLY_ALL",
  FORWARD = "FORWARD",
}

interface EmailMessage {
  jobId: string
  toRecipients: string[]
  ccRecipients?: string[]
  bccRecipients?: string[]
  subject?: string
  body?: string
  attachmentIds?: string[]
  estimateIds?: string[]
  invoiceIds?: string[]
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable bounds="parent" cancel={'[class*="MuiDialogContent-root"]'} handle=".drag-handle">
      <Paper {...props} />
    </Draggable>
  )
}

interface Props {
  readonly checklist?: Checklist
  readonly estimate?: Estimate
  readonly inboxConversationId?: string | null
  readonly invoice?: Invoice
  readonly job?: Job
  readonly isJobEditable?: boolean
  readonly showJob?: boolean
  readonly sending?: boolean
  readonly message?: InboxMessage | null
  readonly mode: EmailDialogMode
  readonly onCancel: () => void
  readonly onSend: (messageData: EmailMessage) => void
}

function EmailDialog({
  checklist,
  inboxConversationId,
  invoice,
  job,
  sending,
  isJobEditable = true,
  showJob = true,
  message,
  mode,
  onCancel,
  onSend,
  estimate,
}: Props) {
  const { user } = useAuth()
  const organizationPreferences = useGetUserOrganizationPreferences(user?.organization?.id)
  const { t } = useTranslation()
  const {
    getRootProps,
    getInputProps,
    open: openFileDialog,
  } = useDropzone({
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
      "text/plain": [".txt"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    },
    minSize: 0,
    maxSize: TOTAL_ATTACHMENT_SIZE_LIMIT,
    multiple: true,
    noClick: true,
    onDrop: (acceptedFiles) => {
      uploadFiles(acceptedFiles)
    },
    onDropRejected: (rejectedFiles) => {
      if (rejectedFiles.find((f) => f.errors[0].code === "file-too-large")) {
        const rejectedFile = rejectedFiles[0].file
        setSnack({
          messageKey: "component.emailDialog.error.file-upload.not-allowed.size",
          messageOptions: { fileName: rejectedFile.name },
          variant: "error",
        })
      }
    },
  })
  const [snack, setSnack] = useState<Snack>()
  const [uploadingNewAttachmentFiles, setUploadingNewAttachmentFiles] = useState<boolean>(false)
  const [estimates, setEstimates] = useState<Estimate[]>([])
  const [workOrders, setWorkOrders] = useState<WorkOrder[]>([])
  const [invoices, setInvoices] = useState<Invoice[]>([])
  const [selectedJob, setSelectedJob] = useState<Job | null | undefined>(job)
  const [emailTemplateData, setEmailTemplateData] = useState<TemplateData>()
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([])
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplate>()
  const [attachments, setAttachments] = useState<Attachment[]>([])
  const [tempRecipient, setTempRecipient] = useState<string>("")
  const [toRecipients, setToRecipients] = useState<string[]>(() => {
    if (message) {
      if (message.direction === MessageDirection.OUTGOING) {
        if (mode === EmailDialogMode.REPLY || mode === EmailDialogMode.REPLY_ALL) {
          return message?.toRecipients?.map((r) => r.messageAddress) ?? []
        }
      } else if (message.direction === MessageDirection.INCOMING) {
        if (mode === EmailDialogMode.REPLY || mode === EmailDialogMode.REPLY_ALL) {
          return [message?.sender?.messageAddress]
        }
      }
    } else {
      const contacts = []

      if (estimate?.billingContact?.email) {
        contacts.push(estimate?.billingContact?.email)
      }

      if (invoice?.billingContact?.email && !contacts.includes(invoice?.billingContact?.email)) {
        contacts.push(invoice?.billingContact?.email)
      }

      if (job?.siteContact?.email && !contacts.includes(job?.siteContact?.email)) {
        contacts.push(job?.siteContact?.email)
      }

      return contacts
    }

    return []
  })
  const [showCcRecipients, setShowCcRecipients] = useState<boolean>(false)
  const [showBccRecipients, setShowBccRecipients] = useState<boolean>(false)
  const [ccRecipients, setCcRecipients] = useState<string[]>(() => {
    if (message) {
      if (message.direction === MessageDirection.OUTGOING) {
        if (mode === EmailDialogMode.REPLY_ALL) {
          const recipients = message.ccRecipients?.map((r) => r.messageAddress) ?? []
          if (recipients.length > 0) {
            setShowCcRecipients(true)
          }
          return recipients
        }
      } else if (message.direction === MessageDirection.INCOMING) {
        if (mode === EmailDialogMode.REPLY_ALL) {
          let recipients = message.toRecipients?.map((r) => r.messageAddress) ?? []
          if (message.ccRecipients) {
            recipients = recipients.concat(message.ccRecipients.map((r) => r.messageAddress))
          }
          if (recipients.length > 0) {
            setShowCcRecipients(true)
          }
          return recipients
        }
      }
    }
    return []
  })
  const [bccRecipients, setBccRecipients] = useState<string[]>(() => {
    const emails = []
    if (organizationPreferences?.bccJobEmailToOrganizationEmail) {
      if (user?.organization?.email) {
        emails.push(user.organization.email)
      }
    }

    return emails
  })
  const [subject, setSubject] = useState<string>(() => {
    if (message) {
      if (mode === EmailDialogMode.REPLY || mode === EmailDialogMode.REPLY_ALL) {
        return `Re: ${message.subject}`
      } else if (mode === EmailDialogMode.FORWARD) {
        return `Fwd: ${message.subject}`
      } else {
        return message.subject
      }
    } else if (selectedJob?.address?.streetNumber && selectedJob?.address?.route) {
      return t("jobEmailDefaultSubject", {
        address: `${selectedJob.address.streetNumber} ${selectedJob.address.route}`,
      })
    } else if (selectedJob?.number) {
      return `${t("job")} #${selectedJob.number}`
    }
  })
  const [initialBody, setInitialBody] = useState<Descendant[]>(() => {
    if (message?.bodyHtml) {
      const deserialized = deserializeFullHtml(message.bodyHtml)
      if (mode === EmailDialogMode.FORWARD) {
        const forwardedMeta: Descendant[] = [
          {
            type: "paragraph",
            children: [{ text: "" }], // Add a blank line for separation
          },
          {
            type: "paragraph",
            children: [{ text: "" }], // Add another blank line for separation
          },
          {
            type: "paragraph",
            children: [
              {
                text: `------------------- ${t("component.emailDialog.forwardedMessage")} -------------------`,
              },
            ],
          },
          {
            type: "paragraph",
            children: [
              {
                text: `${t("component.emailDialog.from")}: ${getMessageParticipantDisplay(message.sender)}`,
              },
            ],
          },
          {
            type: "paragraph",
            children: [
              {
                text: `${t("component.emailDialog.date")}: ${formatDate(message.dateSent, t("format:dateFormat.longWithTime"), user?.organization?.timeZone)}`,
              },
            ],
          },
          {
            type: "paragraph",
            children: [{ text: `${t("component.emailDialog.subject")}: ${message.subject}` }],
          },
          {
            type: "paragraph",
            children: [
              {
                text: `${t("component.emailDialog.to")}: ${message.toRecipients
                  ?.map((r) => getMessageParticipantDisplay(r))
                  ?.join(", ")}`,
              },
            ],
          },
          message.ccRecipients?.length
            ? {
                type: "paragraph",
                children: [
                  {
                    text: `${t("component.emailDialog.cc")}: ${message.ccRecipients
                      ?.map((r) => getMessageParticipantDisplay(r))
                      ?.join(", ")}`,
                  },
                ],
              }
            : {
                type: "paragraph",
                children: [{ text: "" }], // Add a blank line for separation
              },
          {
            type: "paragraph",
            children: [{ text: "" }], // Add a blank line for separation
          },
        ]
        return [...forwardedMeta, ...deserialized, ...INITIAL_BODY_VALUE]
      } else if (mode === EmailDialogMode.REPLY || mode == EmailDialogMode.REPLY_ALL) {
        const replyMeta: Descendant[] = [
          {
            type: "paragraph",
            children: [{ text: "" }], // Add a blank line for separation
          },
          {
            type: "paragraph",
            children: [{ text: "" }], // Add another blank line for separation
          },
          {
            type: "paragraph",
            children: [{ text: "" }], // Add a blank line for separation
          },
          {
            type: "paragraph",
            children: [
              {
                text: `------------------- ${t("component.emailDialog.originalMessage")} -------------------`,
              },
            ],
          },
          {
            type: "paragraph",
            children: [
              {
                text: `${t("component.emailDialog.from")}: ${getMessageParticipantDisplay(message.sender)}`,
              },
            ],
          },
          {
            type: "paragraph",
            children: [
              {
                text: `${t("component.emailDialog.date")}: ${formatDate(message.dateSent, t("format:dateFormat.longWithTime"), user?.organization?.timeZone ?? "Etc/UTC")}`,
              },
            ],
          },
          {
            type: "paragraph",
            children: [{ text: "" }], // Add a blank line for separation
          },
        ]
        return [...replyMeta, ...deserialized, ...INITIAL_BODY_VALUE]
      } else {
        return deserialized
      }
    } else {
      return INITIAL_BODY_VALUE
    }
  })
  const [body, setBody] = useState<Descendant[]>(initialBody)
  const [errors, setErrors] = useState(() => ({
    general: null,
    job: null,
    toRecipients: null,
    subject: null,
    body: null,
  }))

  const { loading: dataLoading, refetch } = useQuery(GET_DATA_FOR_JOB_EMAIL_TEMPLATES, {
    variables: { id: selectedJob?.id },
    skip: !selectedJob?.id,
    fetchPolicy: "no-cache", // without no-cache, the dialog closes automatically after a new Estimate finishes loading
    onCompleted: (data) => {
      const apiData = data?.getJobById
      const jobData = {
        id: apiData?.id,
        number: apiData?.number,
        description: apiData?.description,
        desiredCompletionDate: apiData?.desiredCompletionDate,
        emailAddress: apiData?.emailAddress,
        address: apiData?.address,
        checklist: apiData?.checklist,
        siteContact: apiData?.siteContact,
        customer: apiData?.customer,
      }

      const rawEstimates = apiData?.estimates ?? []
      setEstimates(rawEstimates)

      const rawWorkOrders = apiData?.workOrders ?? []
      setWorkOrders(rawWorkOrders)

      const rawInvoices = apiData?.invoices ?? []
      setInvoices(rawInvoices)

      const availableFiles = apiData?.attachments?.filter(
        (a: Attachment) => !a.isArchived && (a.sizeInBytes ?? 0) < TOTAL_ATTACHMENT_SIZE_LIMIT
      )

      const estimateFiles = rawEstimates?.map((e: Estimate) => {
        // Create a fake Attachment for the Estimate, using the estimate's number as the name
        return {
          id: e.id,
          type: AttachmentType.ESTIMATE,
          contentType: "application/pdf",
          isArchived: false,
          name:
            e.pdfFileName && !isBlank(e.pdfFileName)
              ? e.pdfFileName
              : `${t("fileName.estimate", { number: e.number })}.pdf`,
          isSelected: e.id === estimate?.id,
        }
      })
      const workOrderFiles = rawWorkOrders?.map((w: WorkOrder) => {
        // Create a fake Attachment for the Estimate, using the estimate's number as the name
        return {
          id: w.id,
          type: AttachmentType.WORK_ORDER,
          contentType: "application/pdf",
          isArchived: false,
          name:
            w.pdfFileName && !isBlank(w.pdfFileName)
              ? w.pdfFileName
              : `${t("fileName.workOrder", { number: w.number })}.pdf`,
        }
      })
      // Create a fake Attachment for the Invoice, using the invoice's number as the name
      const invoiceFiles = rawInvoices?.map((i: Invoice) => {
        return {
          id: i.id,
          type: AttachmentType.INVOICE,
          contentType: "application/pdf",
          isArchived: false,
          name:
            i.pdfFileName && !isBlank(i.pdfFileName)
              ? i.pdfFileName
              : `${t("fileName.invoice", { number: i.number })}.pdf`,
          isSelected: i.id === invoice?.id,
        }
      })

      const orgFiles = apiData?.organization?.attachments?.filter((a: Attachment) => !a.isArchived)
      let allFiles = estimateFiles
        .concat(workOrderFiles)
        .concat(invoiceFiles)
        .concat(availableFiles)
        .concat(orgFiles)

      if (apiData?.checklist?.id) {
        // Create a fake Attachment for the Checklist
        allFiles = allFiles.concat({
          id: apiData.checklist.id,
          type: AttachmentType.CHECKLIST,
          contentType: "application/pdf",
          isArchived: false,
          name: `${t("fileName.checklist")}.pdf`,
          isSelected: checklist?.id === apiData.checklist.id,
        })
      }

      if (message && message.attachments && mode === EmailDialogMode.FORWARD) {
        allFiles = allFiles.concat(
          message.attachments.map((a) => ({
            ...a,
            isSelected: true,
            type: AttachmentType.EMAIL,
          }))
        )
      }

      setAttachments(allFiles)

      if (estimate) {
        setEmailTemplates(
          apiData?.organization?.emailTemplates?.filter(
            (t: EmailTemplate) => t.type === EmailTemplateType.ESTIMATE
          )
        )
      } else if (invoice) {
        setEmailTemplates(
          apiData?.organization?.emailTemplates?.filter(
            (t: EmailTemplate) => t.type === EmailTemplateType.INVOICE
          )
        )
      } else {
        setEmailTemplates(
          apiData?.organization?.emailTemplates?.filter(
            (t: EmailTemplate) => t.type === EmailTemplateType.JOB
          )
        )
      }

      const templateData: TemplateData = {
        job: jobData,
        organization: apiData?.organization,
        estimate: rawEstimates?.find((e: Estimate) => e.id === estimate?.id),
        invoice: rawInvoices?.find((i: Invoice) => i.id === invoice?.id),
      }

      const flattenedTemplateData = flattenObject(templateData, "", [
        "attachments",
        "estimates",
        "invoices",
        "emailTemplates",
        "lineItems",
        "__typename",
      ])

      setEmailTemplateData(flattenedTemplateData)
    },
  })

  function validate() {
    const updatedErrors = {} as typeof errors

    if (!selectedJob) {
      updatedErrors.job = t("component.emailDialog.validation.job.required")
    }

    if (!toRecipients || toRecipients.length === 0) {
      updatedErrors.toRecipients = t("component.emailDialog.validation.toRecipients.required")
    }

    if (!subject || subject.trim().length === 0) {
      updatedErrors.subject = t("component.emailDialog.validation.subject.required")
    }

    if (!body || toText(body).trim().length === 0) {
      updatedErrors.body = t("component.emailDialog.validation.body.required")
    }

    setErrors(updatedErrors)

    return !(
      updatedErrors.job ||
      updatedErrors.toRecipients ||
      updatedErrors.subject ||
      updatedErrors.body
    )
  }

  const uploadFile = async (file: File) => {
    const data = new FormData()
    data.append("file", file)
    return fetch(`${process.env.GATSBY_API_SERVER_URL}/attachment/email`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`,
      },
      body: data,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.errorCode) {
          setSnack({
            messageKey: `component.emailDialog.error.${responseJson.errorCode}`,
            messageOptions: { fileName: file.name },
            variant: "error",
          })
        } else {
          const attachment = responseJson.attachment
          const newAttachmentId = attachment?.id
          if (newAttachmentId) {
            setAttachments((prev) => [
              ...prev,
              {
                ...attachment,
                isSelected: true,
                type: AttachmentType.EMAIL,
              },
            ])
          }
        }
      })
  }

  const uploadFiles = async (files: File[]) => {
    if (files.length > 0) {
      const promises = files.map((file) => uploadFile(file))
      setUploadingNewAttachmentFiles(true)
      return await Promise.all(promises)
        .then(() => {
          refetch()
        })
        .catch((error) => {
          console.error("error: ", error)
        })
        .finally(() => {
          setUploadingNewAttachmentFiles(false)
        })
    } else {
      return []
    }
  }

  /**
   * This function will simply dump everything in the attachmentIds, without concern as to whether
   * the attachmentId represents an Estimate or Invoice.
   * The SEND button's click handler will separate the estimate and invoice IDs from the "general" attachment IDs.
   */
  const toggleAttachment = (attachmentId: string) => {
    setAttachments((prev) => {
      return prev.map((a) => {
        if (a.id === attachmentId) {
          return { ...a, isSelected: !a.isSelected }
        }
        return a
      })
    })
  }

  return (
    <Dialog
      PaperComponent={PaperComponent}
      aria-labelledby="compose-email-dialog"
      data-testid="ComposeEmailDialog"
      disableEscapeKeyDown
      fullWidth
      maxWidth="lg"
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          onCancel?.()
        }
      }}
      open
      sx={{
        maxHeight: "calc(100vh - 24px)",
      }}
    >
      <DialogTitle
        className="drag-handle"
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          cursor: "move",
          backgroundColor: "#FFFFFF",
          fontSize: "1.125rem",
        }}
      >
        {t("component.emailDialog.title")}
      </DialogTitle>
      <DialogContent>
        {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
        {errors.general ? (
          <ErrorMessage message={t("error.general.message")} title={t("error.general.title")} />
        ) : null}
        <Box
          sx={{
            backgroundColor: dialogBackgroundColor,
            padding: "1rem",
            marginBottom: "0.5rem",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={[classes.fieldContainer, { display: "flex", flexDirection: "row", gap: "1rem" }]}
            >
              <Box sx={{ flex: "1", display: "flex", flexDirection: "column" }}>
                {selectedJob && !isJobEditable ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0",
                      fontSize: "0.875rem",
                    }}
                  >
                    <Box sx={{ fontWeight: "600" }}>
                      {t("component.emailDialog.jobNumberLink", {
                        jobNumber: selectedJob.number,
                      })}
                    </Box>
                    <Box>{selectedJob.customer?.name}</Box>
                    <Box>{getAddressSummary(selectedJob.address)}</Box>
                  </Box>
                ) : isJobEditable ? (
                  <>
                    <JobTypeahead
                      currentJob={selectedJob}
                      error={!!errors.job}
                      label={t("job")}
                      onBlur={() => {
                        if (!selectedJob) {
                          setErrors({
                            ...errors,
                            job: t("component.emailDialog.validation.job.required"),
                          })
                        } else {
                          setErrors({ ...errors, job: null })
                        }
                      }}
                      onSelect={(j: Job | null) => {
                        if (j) {
                          setErrors({ ...errors, job: null })
                        }
                        setSelectedJob(j)
                      }}
                      required
                    />
                    <FieldHelperText
                      error={Boolean(errors.job)}
                      message={errors.job ? errors.job : t("component.emailDialog.job.helperText")}
                    />
                  </>
                ) : null}
              </Box>
              <Box sx={{ flex: "1", display: "flex", flexDirection: "column" }}>
                <SelectorField
                  emptyOptionLabel={t("component.emailDialog.template.empty") as string}
                  label={t("emailTemplate") as string}
                  margin="dense"
                  name="emailTemplate"
                  onChange={(selectedOption) => {
                    if (selectedOption && emailTemplateData) {
                      setSubject(selectedOption.subject)
                      const templateBody = JSON.parse(selectedOption.body)
                      const resolvedBody = resolveTemplate(templateBody, emailTemplateData, t)
                      setBody(resolvedBody)
                      setInitialBody(resolvedBody)

                      const currentTemplate = emailTemplate

                      const templateAttachmentIds =
                        currentTemplate?.attachments?.map((a) => a.id) ?? []
                      let updatedAttachments = attachments.map((a) => ({
                        ...a,
                        isSelected: a.isSelected || templateAttachmentIds.includes(a.id),
                      }))
                      setAttachments(updatedAttachments)
                      setEmailTemplate(selectedOption)
                    }
                  }}
                  options={emailTemplates}
                  sx={{
                    "& .MuiSelect-select.MuiInputBase-input": {
                      paddingBottom: "4px",
                    },
                    height: "100%",
                  }}
                  value={emailTemplate?.id ?? ""}
                  variant="outlined"
                />
                <FieldHelperText
                  error={false}
                  message={t("component.emailDialog.template.helperText")}
                />
              </Box>
              {!showJob ? <Box sx={{ flex: 1 }} /> : null}
            </Box>
            <Box
              sx={[
                classes.fieldContainer,
                { display: "flex", flexDirection: "column", gap: "0.5rem" },
              ]}
            >
              <Box>
                <Autocomplete
                  clearOnBlur
                  freeSolo
                  id="toRecipients"
                  multiple
                  onBlur={() => {
                    if (toRecipients.length === 0 && tempRecipient.trim().length === 0) {
                      setErrors({
                        ...errors,
                        toRecipients: t("component.emailDialog.validation.toRecipients.required"),
                      })
                    }
                    if (tempRecipient.trim().length > 0) {
                      setErrors({ ...errors, toRecipients: null })
                      setToRecipients([...toRecipients, tempRecipient])
                    }
                  }}
                  onChange={(event, newValue: (string | string[])[]) => {
                    const updatedRecipients = [...newValue]
                    setToRecipients(updatedRecipients as string[])
                    if (updatedRecipients.length > 0) {
                      setErrors({ ...errors, toRecipients: null })
                    }
                  }}
                  onInputChange={(event, value) => {
                    setTempRecipient(value)
                  }}
                  options={[]}
                  renderInput={(params) => {
                    return (
                      <FielderTextField
                        {...params}
                        error={!!errors.toRecipients}
                        label={t("to")}
                        margin="dense"
                        placeholder={t("component.emailDialog.emailFieldPlaceholder") as string}
                        required
                        variant="outlined"
                      />
                    )
                  }}
                  renderTags={(value, getTagProps) => {
                    return value.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        key={`${option}-${index}`}
                        label={option}
                        style={{
                          backgroundColor: isValidEmail(option) ? "#fff" : "#f03030",
                          color: isValidEmail(option) ? "#000" : "#fff",
                          fontWeight: 600,
                        }}
                        variant="outlined"
                      />
                    ))
                  }}
                  value={toRecipients}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FieldHelperText error message={errors.toRecipients ?? ""} />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      gap: "0.875rem",
                      fontSize: "0.875rem",
                    }}
                  >
                    {!showCcRecipients ? (
                      <Box
                        onClick={() => setShowCcRecipients(true)}
                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                      >
                        {t("component.emailDialog.addCc")}
                      </Box>
                    ) : null}
                    {!showBccRecipients ? (
                      <Box
                        onClick={() => setShowBccRecipients(true)}
                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                      >
                        {t("component.emailDialog.addBcc")}
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              </Box>
            </Box>
            {showCcRecipients ? (
              <Box sx={classes.fieldContainer}>
                <Autocomplete
                  clearOnBlur
                  freeSolo
                  id="ccRecipients"
                  multiple
                  onBlur={() => {
                    if (tempRecipient.trim().length > 0) {
                      setCcRecipients([...ccRecipients, tempRecipient])
                    }
                  }}
                  onChange={(event: any, newValue: (string | string[])[]) => {
                    setCcRecipients(newValue as string[])
                  }}
                  onInputChange={(event, value) => {
                    setTempRecipient(value)
                  }}
                  options={[]}
                  renderInput={(params) => {
                    return (
                      <FielderTextField
                        {...params}
                        label="CC"
                        margin="dense"
                        placeholder={t("component.emailDialog.emailFieldPlaceholder") as string}
                        variant="outlined"
                      />
                    )
                  }}
                  renderTags={(value, getTagProps) => {
                    return value.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        key={`${option}-${index}`}
                        label={option}
                        style={{
                          backgroundColor: isValidEmail(option) ? "#fff" : "#f03030",
                          color: isValidEmail(option) ? "#000" : "#fff",
                          fontWeight: 600,
                        }}
                        variant="outlined"
                      />
                    ))
                  }}
                  value={ccRecipients}
                />
              </Box>
            ) : null}
            {showBccRecipients ? (
              <Box sx={classes.fieldContainer}>
                <Autocomplete
                  clearOnBlur
                  freeSolo
                  id="bccRecipients"
                  multiple
                  onBlur={() => {
                    if (tempRecipient.trim().length > 0) {
                      setBccRecipients([...bccRecipients, tempRecipient])
                    }
                  }}
                  onChange={(event: any, newValue: (string | string[])[]) => {
                    setBccRecipients(newValue as string[])
                  }}
                  onInputChange={(event, value) => {
                    setTempRecipient(value)
                  }}
                  options={[]}
                  renderInput={(params) => {
                    return (
                      <FielderTextField
                        {...params}
                        label="BCC"
                        margin="dense"
                        placeholder={t("component.emailDialog.emailFieldPlaceholder") as string}
                        variant="outlined"
                      />
                    )
                  }}
                  renderTags={(value, getTagProps) => {
                    return value.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        key={`${option}-${index}`}
                        label={option}
                        style={{
                          backgroundColor: isValidEmail(option) ? "#fff" : "#f03030",
                          color: isValidEmail(option) ? "#000" : "#fff",
                          fontWeight: 600,
                        }}
                        variant="outlined"
                      />
                    ))
                  }}
                  value={bccRecipients}
                />
              </Box>
            ) : null}
            <Box sx={classes.fieldContainer}>
              <FielderTextField
                autoComplete="none"
                data-testid="subject"
                error={!!errors.subject}
                fullWidth
                id="subject"
                inputProps={{ maxLength: 988 }}
                label={t("subject")}
                margin="dense"
                name="subject"
                onBlur={() => {
                  if (!subject || subject.trim().length === 0) {
                    setErrors({
                      ...errors,
                      subject: t("component.emailDialog.validation.subject.required"),
                    })
                  }
                }}
                onChange={(e) => {
                  setSubject(e.target.value)
                  if (e.target.value) {
                    setErrors({ ...errors, subject: null })
                  }
                }}
                required
                value={subject}
                variant="outlined"
              />
              <FieldHelperText error message={errors.subject ?? ""} />
            </Box>
            <Box
              sx={{
                marginTop: "-0.3125rem",
                marginBottom: "1.25rem",
                minHeight: "58px",
              }}
            >
              <Box
                sx={{
                  marginLeft: "0.625rem",
                  marginBottom: "0.3125rem",
                  fontSize: "0.875rem",
                }}
              >
                {t("attachments")}
              </Box>
              <Box {...getRootProps({ css: "dropzone" })}>
                <input {...getInputProps()} />
                <EmailAttachments
                  availableFiles={attachments}
                  isUploading={uploadingNewAttachmentFiles}
                  onClickUploadFromComputer={openFileDialog}
                  onDeselectAttachment={toggleAttachment}
                  onSelectAttachment={toggleAttachment}
                />
              </Box>
              <Box sx={{ marginLeft: "0.625rem" }}>
                {getTotalAttachmentSize(attachments.filter((a) => a.isSelected)) >
                TOTAL_ATTACHMENT_SIZE_LIMIT ? (
                  <FormHelperText sx={{ color: "red" }}>
                    {t("component.emailDialog.attachmentTotalSizeWarning")}
                  </FormHelperText>
                ) : (
                  <FormHelperText>{t("component.emailDialog.attachmentHelperText")}</FormHelperText>
                )}
              </Box>
            </Box>
            <Box
              id="job-email-dialog-composer"
              sx={{
                flexGrow: 1,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                minHeight: "200px",
              }}
            >
              <RichTextComposer
                error={!!errors.body}
                onChange={(value: Descendant[]) => {
                  setErrors({ ...errors, body: null })
                  setBody(value)
                }}
                value={initialBody}
              />
              <FieldHelperText
                error={Boolean(errors.body)}
                message={errors.body ? errors.body : ""}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        className="drag-handle"
        sx={{
          backgroundColor: dialogBackgroundColor,
          borderTop: "1px solid #cdcdcd",
          padding: "1.5rem",
          paddingTop: "0.75rem",
          paddingBottom: "0.75rem",
          display: "flex",
          cursor: "move",
          justifyContent: "space-between",
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={sending}
          onClick={onCancel}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <Button
          color="primary"
          data-testid="sendButton"
          disabled={sending}
          onClick={() => {
            if (validate()) {
              const estimateIds = estimates?.map((e) => e?.id) as string[]
              const workOrderIds = workOrders?.map((w) => w?.id) as string[]
              const invoiceIds = invoices?.map((i) => i.id) as string[]
              const checkListId = attachments.find((a) => a.type === "CHECKLIST")?.id
              const customAttachments = []
              const attachmentIds = attachments?.filter((a) => a.isSelected).map((a) => a.id)
              const specialAttachmentIds = estimateIds.concat(workOrderIds).concat(invoiceIds)
              if (checkListId && attachmentIds.includes(checkListId)) {
                specialAttachmentIds.push(checkListId)
                customAttachments.push({
                  id: checkListId,
                  typeName: "CHECKLIST",
                })
              }
              const generalAttachmentIds = difference(attachmentIds, specialAttachmentIds).filter(
                (i) => !!i
              ) as string[]
              const selectedEstimateIds = estimates
                ?.filter((e) => attachmentIds.includes(e.id))
                ?.map((q) => q.id)
                .filter((i) => !!i) as string[]
              const selectedWorkOrderIds = workOrders
                ?.filter((e) => attachmentIds.includes(e.id))
                ?.map((q) => q.id)
                .filter((i) => !!i) as string[]
              const selectedInvoiceIds = invoices
                ?.filter((i) => attachmentIds.includes(i.id))
                ?.map((i) => i.id)
                .filter((i) => !!i) as string[]

              const htmlBody = toHtml(body)

              const payload = {
                jobId: selectedJob.id,
                toRecipients,
                ccRecipients,
                bccRecipients,
                subject,
                body: htmlBody,
                attachmentIds: generalAttachmentIds,
                estimateIds: selectedEstimateIds,
                workOrderIds: selectedWorkOrderIds,
                invoiceIds: selectedInvoiceIds,
                customAttachments,
                inboxConversationId,
              }

              onSend(payload)
            }
          }}
          sx={{
            fontWeight: "bold",
            "& svg": {
              fontSize: "1.0rem",
            },
            "& div": {
              marginLeft: "0.625rem",
              marginRight: "0.625rem",
            },
            minWidth: "150px",
          }}
          variant="contained"
        >
          {sending ? (
            <CircularProgress color="secondary" size={20} thickness={6.0} />
          ) : (
            <Box>{t("sendEmail")}</Box>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const dialogBackgroundColor = "#FFFFFF"
const classes = {
  fieldContainer: {
    marginBottom: "1.25rem",
  },
} as const

export default EmailDialog
