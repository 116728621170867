/* eslint-disable react/no-danger */
import React from "react"
import DOMPurify from "dompurify"

interface Props {
  readonly htmlContent: string
}

export function SafeEmailViewer({ htmlContent }: Props) {
  // Sanitize the HTML content
  const sanitizedHtml = DOMPurify.sanitize(htmlContent, {
    FORBID_TAGS: ["style", "meta", "head", "html", "body", "script"],
    FORBID_ATTR: ["style"],
    ADD_TAGS: ["iframe"],
    ADD_ATTR: ["allowfullscreen", "frameborder", "src"],
    WHOLE_DOCUMENT: false,
  })

  return (
    <div className="relative max-w-full overflow-hidden">
      {/* Email content container with strict styling boundaries */}
      <div
        className="prose prose-sm max-w-none bg-white rounded-lg shadow p-6 overflow-x-auto"
        style={{
          isolation: "isolate", // Creates a new stacking context
          contain: "content", // Contains layout/style effects
        }}
      >
        {/* Inner container for the sanitized HTML */}
        <div
          className="[&_*]:max-w-full [&_img]:h-auto [&_table]:w-full [&_td]:break-words"
          dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
        />
      </div>
    </div>
  )
}
