export enum DefaultPermission {
  CreateRegion = "CreateRegion",
  ReadRegion = "ReadRegion",
  UpdateRegion = "UpdateRegion",

  CreateTaxRateGroup = "CreateTaxRateGroup",
  ReadTaxRateGroup = "ReadTaxRateGroup",
  UpdateTaxRateGroup = "UpdateTaxRateGroup",
  SyncTaxRateGroup = "SyncTaxRateGroup",

  CreateJobStatus = "CreateJobStatus",
  ReadJobStatus = "ReadJobStatus",
  UpdateJobStatus = "UpdateJobStatus",

  CreateJobCategory = "CreateJobCategory",
  ReadJobCategory = "ReadJobCategory",
  UpdateJobCategory = "UpdateJobCategory",

  CreateJob = "CreateJob",
  ReadJob = "ReadJob",
  UpdateJob = "UpdateJob",

  SendJobEmail = "SendJobEmail",
  SendJobSms = "SendJobSms",

  CreateJobAssignment = "CreateJobAssignment",
  ReadJobAssignment = "ReadJobAssignment",
  UpdateJobAssignment = "UpdateJobAssignment",
  DeleteJobAssignment = "DeleteJobAssignment",
  CompleteJobAssignment = "CompleteJobAssignment",

  CreateJobWorkflow = "CreateJobWorkflow",
  ReadJobWorkflow = "ReadJobWorkflow",
  UpdateJobWorkflow = "UpdateJobWorkflow",

  CreateTask = "CreateTask",
  ReadTask = "ReadTask",
  UpdateTask = "UpdateTask",

  CreateEstimate = "CreateEstimate",
  ReadEstimate = "ReadEstimate",
  UpdateEstimate = "UpdateEstimate",
  SendEstimate = "SendEstimate",

  CreateEstimateTemplate = "CreateEstimateTemplate",
  ReadEstimateTemplate = "ReadEstimateTemplate",
  UpdateEstimateTemplate = "UpdateEstimateTemplate",
  DeleteEstimateTemplate = "DeleteEstimateTemplate",

  CreateWorkOrder = "CreateWorkOrder",
  ReadWorkOrder = "ReadWorkOrder",
  UpdateWorkOrder = "UpdateWorkOrder",

  CreateWorkOrderTemplate = "CreateWorkOrderTemplate",
  ReadWorkOrderTemplate = "ReadWorkOrderTemplate",
  UpdateWorkOrderTemplate = "UpdateWorkOrderTemplate",
  DeleteWorkOrderTemplate = "DeleteWorkOrderTemplate",

  CreateCustomerFlag = "CreateCustomerFlag",
  ReadCustomerFlag = "ReadCustomerFlag",
  UpdateCustomerFlag = "UpdateCustomerFlag",
  DeleteCustomerFlag = "DeleteCustomerFlag",

  CreateInvoice = "CreateInvoice",
  ReadInvoice = "ReadInvoice",
  UpdateInvoice = "UpdateInvoice",
  SendInvoice = "SendInvoice",

  CreateChecklist = "CreateChecklist",
  ReadChecklist = "ReadChecklist",
  UpdateChecklist = "UpdateChecklist",

  CreateChecklistTemplate = "CreateChecklistTemplate",
  ReadChecklistTemplate = "ReadChecklistTemplate",
  UpdateChecklistTemplate = "UpdateChecklistTemplate",
  DeleteChecklistTemplate = "DeleteChecklistTemplate",

  CreateContractTemplate = "CreateContractTemplate",
  ReadContractTemplate = "ReadContractTemplate",
  UpdateContractTemplate = "UpdateContractTemplate",
  DeleteContractTemplate = "DeleteContractTemplate",

  CreateEstimateRequest = "CreateEstimateRequest",
  ReadEstimateRequest = "ReadEstimateRequest",
  UpdateEstimateRequest = "UpdateEstimateRequest",
  CreateUser = "CreateUser",
  ReadUser = "ReadUser",
  UpdateUser = "UpdateUser",
  SendAccountActivationCode = "SendAccountActivationCode",

  CreateOrganization = "CreateOrganization",
  ReadOrganization = "ReadOrganization",
  UpdateOrganization = "UpdateOrganization",
  ArchiveOrganization = "ArchiveOrganization",
  ImpersonateOrganization = "ImpersonateOrganization",

  CreateSubscription = "CreateSubscription",
  SyncSubscription = "SyncSubscription",

  ReadOrganizationPlugin = "ReadOrganizationPlugin",
  UpdateOrganizationPlugin = "UpdateOrganizationPlugin",
  DeleteOrganizationPlugin = "DeleteOrganizationPlugin",

  CreateCustomer = "CreateCustomer",
  ReadCustomer = "ReadCustomer",
  UpdateCustomer = "UpdateCustomer",

  ReadItem = "ReadItem",
  CreateItem = "CreateItem",
  UpdateItem = "UpdateItem",
  ImportItem = "ImportItem",

  CreateOrganizationItem = "CreateOrganizationItem",
  ReadOrganizationItem = "ReadOrganizationItem",
  UpdateOrganizationItem = "UpdateOrganizationItem",
  SyncOrganizationItem = "SyncOrganizationItem",

  CreateOrganizationItemList = "CreateOrganizationItemList",
  ReadOrganizationItemList = "ReadOrganizationItemList",
  UpdateOrganizationItemList = "UpdateOrganizationItemList",
  DeleteOrganizationItemList = "DeleteOrganizationItemList",

  CreateProductOrder = "CreateProductOrder",
  ReadProductOrder = "ReadProductOrder",
  UpdateProductOrder = "UpdateProductOrder",

  ReadPermission = "ReadPermission",
  ReadRole = "ReadRole",
  CreateRole = "CreateRole",
  UpdateRole = "UpdateRole",

  ReadEmailTemplate = "ReadEmailTemplate",
  CreateEmailTemplate = "CreateEmailTemplate",
  UpdateEmailTemplate = "UpdateEmailTemplate",
  DeleteEmailTemplate = "DeleteEmailTemplate",

  ReadSubscriptionOption = "ReadSubscriptionOption",

  ReadInbox = "ReadInbox",
}
