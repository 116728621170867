/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from "react"
import { Navigate, useLocation, NavigateProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import TableContainer from "@mui/material/TableContainer"
import TablePagination from "@mui/material/TablePagination"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import VisibilityIcon from "@mui/icons-material/Visibility"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import Skeleton from "@mui/material/Skeleton"
import EmptyState from "~/components/EmptyState"
import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import SearchField from "~/components/SearchField"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import SortableTableHeader from "~/components/SortableTableHeader"
import TablePaginationActions from "~/components/TablePaginationActions"
import { ALL_ESTIMATE_REQUESTS } from "~/queries/allEstimateRequests"
import {
  formatAddress,
  isBlank,
  ESTIMATE_REQUESTS,
  createDayJS,
  NOT_SPECIFIED,
  useDebounce,
} from "~/util"
import { EstimateRequest, Snack } from "~/types"
import { useAuth } from "~/context/AuthContext"

interface LocationState {
  snack?: Snack
}

interface EstimateRequestEdge {
  node: EstimateRequest
}

interface ApolloError {
  message?: string
}

function getContactName(estimateRequest: EstimateRequest) {
  let name = ""
  if (estimateRequest.firstName) {
    name = estimateRequest.firstName
  }

  if (estimateRequest.lastName) {
    name = `${name} ${estimateRequest.lastName}`
  }

  return name
}

const NUM_COLUMNS = 5

function EstimateRequestList() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const location = useLocation()
  const [snack, setSnack] = useState<Snack | undefined>(() => {
    const state = location.state as LocationState
    if (state?.snack) {
      return state.snack
    }
  })
  const [filter, setFilter] = useState<string>("")
  const [after, setAfter] = useState<number | null>()
  const [before, setBefore] = useState<number | null>()
  const [first, setFirst] = useState<number | null>(10)
  const [last, setLast] = useState<number | null>()
  const [sortBy, setSortBy] = useState<string>("createdAt")
  const [sortDir, setSortDir] = useState<"DESC" | "ASC">("DESC")
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const debouncedSearchTerm = useDebounce(filter, 500)
  const timeZone = user?.organization?.timeZone ?? "Etc/UTC"

  const { loading, error, data } = useQuery(ALL_ESTIMATE_REQUESTS, {
    variables: {
      filter: debouncedSearchTerm,
      after,
      before,
      first,
      last,
      sortBy,
      sortDir,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    context: {
      debounceKey: "ALL_ESTIMATE_REQUEST_LISTS",
      debounceTimeout: 50,
    },
  })

  function onGoToFirstPage() {
    setAfter(null)
    setBefore(null)
    setFirst(rowsPerPage)
    setLast(null)
  }

  function onGoToLastPage() {
    setAfter(null)
    setBefore(null)
    setFirst(null)
    setLast(rowsPerPage)
  }

  function onGoToPrevious() {
    setAfter(null)
    setBefore(pageInfo.startCursor)
    setFirst(null)
    setLast(rowsPerPage)
  }

  function onGoToNext() {
    setAfter(pageInfo.endCursor)
    setBefore(null)
    setFirst(rowsPerPage)
    setLast(null)
  }

  function handleChangeRowsPerPage(event: any) {
    const newPageSize = +event.target.value
    setRowsPerPage(newPageSize)
    setPage(0)
    setAfter(null)
    setBefore(null)
    setFirst(newPageSize)
    setLast(null)
  }

  function sort(propertyName: string) {
    const newSortBy = propertyName
    const newSortDir = propertyName !== sortBy ? "ASC" : sortDir === "ASC" ? "DESC" : "ASC"
    setSortBy(newSortBy)
    setSortDir(newSortDir)
    setPage(0)
    setAfter(null)
    setBefore(null)
    setFirst(rowsPerPage)
    setLast(null)
  }

  const handleSearchChange = (val: string) => {
    setFilter(val)
    setPage(0)
    setAfter(null)
    setBefore(null)
    setFirst(rowsPerPage)
    setLast(null)
  }

  function handleRowClick(id: string) {
    setRedirectTo({ to: `/app/estimate-requests/detail/${id}`, replace: false })
  }

  const pageInfo = data?.allEstimateRequests?.pageInfo || {}
  const estimateRequests = data?.allEstimateRequests?.edges?.filter(
    (e: EstimateRequestEdge) => e.node.status === "NEW" || e.node.status === "IN_PROGRESS"
  )

  const handleCopyHtmlSnippet = async () => {
    const baseUrl = window.location.origin
    const href = `${baseUrl}/app/estimate-requests/create/${user?.organization?.id}`

    const htmlSnippet = `<a
      href="${href}"
      style="
        padding: 1rem;
        border: none;
        color: #121212;
        background-color: #ffc601;
        border-radius: 4px;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;
        text-decoration: none;
        text-align: center;
        user-select: none;
        outline-style: none;
        box-shadow: 1px 2px 4px #afafaf;
        display: block;
      "
    >
      ${t("page.estimateRequestList.estimateRequestButtonSnippetLabel")}
    </a>`
    try {
      await navigator.clipboard.writeText(htmlSnippet)
      setSnack({
        messageKey: "page.estimateRequestList.snippetCopied",
        variant: "success",
      })
    } catch (err) {
      setSnack({
        messageKey: "page.estimateRequestList.snippetCopyError",
        variant: "error",
      })
    }
  }

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t(ESTIMATE_REQUESTS.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={ESTIMATE_REQUESTS}>
        <Box
          sx={(theme) => {
            return {
              margin: "0 0.5rem",
              paddingBottom: "3rem",
              height: "100%",
              [theme.breakpoints.up("md")]: {
                margin: "0 1.25rem",
                marginRight: "80px", // make sure the intercom chat bubble doesn't block the pagination controls
                paddingBottom: "12.5rem",
              },
            }
          }}
        >
          <PageHeader icon={ESTIMATE_REQUESTS.icon} leafTitleKey={ESTIMATE_REQUESTS.titleKey} />
          <Box
            sx={{
              marginBottom: "1.25rem",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Box
              sx={(theme) => ({
                width: "25rem",
                [theme.breakpoints.down("xs")]: {
                  width: "100%",
                },
              })}
            >
              <SearchField
                onChange={handleSearchChange}
                placeholder={t("searchEstimateRequests")}
                term={filter}
                testID="SearchField"
              />
            </Box>
            <Button
              onClick={handleCopyHtmlSnippet}
              startIcon={<ContentCopyIcon />}
              sx={{
                whiteSpace: "nowrap",
              }}
              variant="outlined"
            >
              {t("page.estimateRequestList.copyEstimateRequestButton")}
            </Button>
          </Box>
          {!error && !loading && estimateRequests?.length == 0 && (
            <Box
              style={{
                paddingTop: "1.875rem",
                paddingBottom: "1.875rem",
                // backgroundColor: "yellow",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                alignItems: "center",
                gap: "4rem",
              }}
            >
              {isBlank(filter) && (
                <Box
                  sx={{
                    marginTop: "4rem",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    gap: "2rem",
                    maxWidth: "37rem",
                  }}
                >
                  <EmptyState title={t("page.estimateRequestList.emptyState.title")} />
                  <Box
                    sx={{
                      fontSize: "1rem",
                      color: (theme) => theme.fielderColors.mutedText,
                    }}
                  >
                    {t("page.estimateRequestList.emptyState.copyEstimateRequestButtonMessage")}
                  </Box>
                </Box>
              )}
              {!isBlank(filter) && (
                <EmptyState title={t("page.estimateRequestList.noMatchingResults.title")}>
                  <Box>{t("page.estimateRequestList.noMatchingResults.message")}</Box>
                </EmptyState>
              )}
            </Box>
          )}
          {!error && (loading || estimateRequests?.length > 0) ? (
            <Paper
              sx={{
                overflowX: "auto",
              }}
            >
              <TableContainer
                sx={{
                  maxHeight: "calc(75vh)",
                  height: "calc(75vh - 50px)",
                }}
              >
                <Table
                  sx={[
                    {
                      width: "100%",
                      tableLayout: "fixed",
                    },
                    estimateRequests?.length == 0 && { height: "100%" },
                  ]}
                >
                  <TableHead>
                    <TableRow>
                      <SortableTableHeader
                        isActiveSort={sortBy === "createdAt"}
                        label={t("dateSubmitted")}
                        onClick={() => sort("createdAt")}
                        sortDir={sortDir}
                        sx={classes.tableHead}
                        width="10%"
                      />
                      <SortableTableHeader
                        isActiveSort={sortBy === "businessName"}
                        label={t("companyName")}
                        onClick={() => sort("businessName")}
                        sortDir={sortDir}
                        sx={classes.tableHead}
                        width="30%"
                      />
                      <SortableTableHeader
                        isActiveSort={sortBy === "firstName"}
                        label={t("contactName")}
                        onClick={() => sort("firstName")}
                        sortDir={sortDir}
                        sx={classes.tableHead}
                        width="20%"
                      />
                      <SortableTableHeader
                        isActiveSort={sortBy === "address.addressString"}
                        label={t("jobAddress")}
                        onClick={() => sort("address.addressString")}
                        sortDir={sortDir}
                        sx={classes.tableHead}
                        width="30%"
                      />
                      <TableCell sx={classes.tableHead} width="10%">
                        {" "}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={estimateRequests?.length == 0 ? { height: "100%" } : {}}>
                    {!error &&
                      !loading &&
                      estimateRequests?.map((edge: EstimateRequestEdge, index: number) => {
                        const { node } = edge
                        const dateCreated = createDayJS(node.createdAt, timeZone)
                        return (
                          <TableRow
                            data-testid={`estimateRequestRow${index}`}
                            key={node.id}
                            onClick={() => handleRowClick(node.id)}
                            sx={classes.dataRow}
                          >
                            <TableCell>
                              {dateCreated ? (
                                <Box>
                                  <Box>
                                    {dateCreated.format(t("format:dateFormat.short") as string)}
                                  </Box>
                                  <Box>
                                    {dateCreated.format(t("format:dateFormat.time") as string)}
                                  </Box>
                                </Box>
                              ) : (
                                NOT_SPECIFIED
                              )}
                            </TableCell>
                            <TableCell>{node.businessName}</TableCell>
                            <TableCell>{getContactName(node)}</TableCell>
                            <TableCell>
                              <Box sx={classes.jobAddress}>
                                {formatAddress(node.address.addressString)}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Button
                                aria-label={t("viewEstimateRequest") as string}
                                variant="outlined"
                              >
                                <VisibilityIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    {loading
                      ? [...Array(rowsPerPage).keys()].map((i) => (
                          <TableRow key={i} sx={classes.dataRow}>
                            {[...Array(NUM_COLUMNS).keys()].map((j) => (
                              <TableCell key={j}>
                                <Skeleton variant="text" />
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      : null}
                    {error ? (
                      <TableRow>
                        <TableCell align="center" colSpan={NUM_COLUMNS}>
                          <Box
                            style={{
                              color: "rgb(244, 67, 54)",
                              fontWeight: 500,
                              fontSize: "1.25rem",
                              lineHeight: 1.6,
                            }}
                          >
                            {t("errorLabel")}
                            {": "}
                            {(error as ApolloError).message || t("unknownError")}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                ActionsComponent={() => (
                  <TablePaginationActions
                    hasNextPage={pageInfo.hasNextPage ?? false}
                    hasPreviousPage={pageInfo.hasPreviousPage ?? false}
                    onGoToFirstPage={onGoToFirstPage}
                    onGoToLastPage={onGoToLastPage}
                    onGoToNext={onGoToNext}
                    onGoToPrevious={onGoToPrevious}
                  />
                )}
                component="div"
                count={-1}
                labelDisplayedRows={() => ``}
                labelRowsPerPage={t("rowsPerPage") as string}
                onPageChange={() => undefined}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25, 50, 100]}
              />
            </Paper>
          ) : null}
        </Box>
      </MainLayout>
    </>
  )
}

const classes = {
  tableHead: {
    backgroundColor: "#f9f9f9",
    zIndex: 1000,
    position: "sticky",
    top: 0,
  },
  dataRow: {
    height: "3.75rem",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f8f8f8",
    },
  },
  jobAddress: {
    boxSizing: "border-box",
    overflow: "hidden",
    whiteSpace: "wrap",
    textOverflow: "ellipsis",
    maxWidth: "200px",
    maxHeight: "2.5rem",
  },
} as const

export default EstimateRequestList
