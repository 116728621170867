import React, { useState, useMemo, useCallback } from "react"
import { useTranslation } from "react-i18next"
import capitalize from "capitalize"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Paper from "@mui/material/Paper"

import SectionHeader from "~/components/SectionHeader"
import SectionContent from "~/components/SectionContent"
import FieldHelperText from "~/components/FieldHelperText"
import FielderTextField from "~/components/FielderTextField"
import AddressAutocompleteField from "~/components/AddressAutocompleteField"
import PhoneNumberInput from "~/components/CustomPhoneNumberInput"
import RegionSelect from "~/components/RegionSelect"
import SaveButton from "~/components/SaveButton"
import { isBlank, isValidEmail } from "~/util"
import { useAuth } from "~/context/AuthContext"
import { usePrompt } from "~/hooks/usePrompt"
import {
  Organization,
  Region,
  Address,
  User,
  EditOrganizationInput,
  LanguageCode,
  Snack,
} from "~/types"
import UserSelect from "~/components/UserSelect"
import LanguageSelect from "~/components/LanguageSelect"
import SnackbarMessage from "~/components/SnackbarMessage"
import UserStatusPill from "~/app/settings/users/components/UserStatusPill"
import FranchiseeSubscriptionInfo from "./FranchiseeSubscriptionInfo"
import QuickTip from "./QuickTip"
import OwnerInfoStatic from "./OwnerInfoStatic"
import StaticField from "~/components/StaticField"
import OrganizationStatusPill from "./OrganizationStatusPill"
import ConfirmationDialog from "~/components/ConfirmationDialog"
import { isDraft, isDraftOrPending, isPendingWithUnverifiedOwner } from "../utils/franchiseeUtils"

function handleFocus(e) {
  e.target.select()
}

interface Props {
  readonly franchisee?: Organization
  readonly handleSubmit: (franchisee: EditOrganizationInput) => void
  readonly loading?: boolean
  readonly onCancel: () => void
}

function FranchiseeForm({ franchisee, handleSubmit, loading, onCancel }: Props) {
  const { t } = useTranslation()
  const id = React.useMemo(() => franchisee?.id, [franchisee?.id])
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [snack, setSnack] = useState<Snack | undefined>()
  const [showConfirmSendInvitationDialog, setShowConfirmSendInvitationDialog] =
    useState<boolean>(false)
  const [tradeName, setTradeName] = useState<string>(franchisee?.tradeName ?? "")
  const [legalName, setLegalName] = useState<string>(franchisee?.legalName ?? "")
  const [legalNameSameAsTradeName, setLegalNameSameAsTradeName] = useState<boolean>(
    franchisee?.legalName === franchisee?.tradeName
  )
  const [accountManager, setAccountManager] = useState<User | null>(
    franchisee?.accountManager ?? null
  )
  const [accountNumber, setAccountNumber] = useState<string>(franchisee?.accountNumber ?? "")
  const [email, setEmail] = useState<string>(franchisee?.email ?? "")
  const [phoneNumber, setPhoneNumber] = useState<string>(franchisee?.phoneNumber ?? "")
  const [websiteURL, setWebsiteURL] = useState<string>(franchisee?.websiteURL ?? "")
  const [address, setAddress] = useState<Partial<Address> | null>(franchisee?.address ?? null)
  const [region, setRegion] = useState<Region | null>(franchisee?.region ?? null)
  const [languageCode, setLanguageCode] = useState<LanguageCode | undefined | null>(
    franchisee?.languageCode
  )
  const ownerUser = useMemo(() => franchisee?.owner, [franchisee?.owner])
  const [ownerEmail, setOwnerEmail] = useState<string>(ownerUser?.email ?? "")
  const [ownerFirstName, setOwnerFirstName] = useState<string>(ownerUser?.firstName ?? "")
  const [ownerLastName, setOwnerLastName] = useState<string>(ownerUser?.lastName ?? "")
  const [ownerMobilePhoneNumber, setOwnerMobilePhoneNumber] = useState<string>(
    ownerUser?.mobilePhoneNumber ?? ""
  )
  const [shouldSendInvitation, setShouldSendInvitation] = useState<boolean>(false)
  const { user } = useAuth()
  const [errors, setErrors] = useState<{
    tradeName?: string | null
    legalName?: string | null
    region?: string | null
    languageCode?: string | null
    address?: string | null
    email?: string | null
    ownerFirstName?: string | null
    ownerEmail?: string | null
    accountNumber?: string | null
  }>({
    tradeName: null,
    legalName: null,
    region: null,
    languageCode: null,
    address: null,
    email: null,
    ownerFirstName: null,
    ownerEmail: null,
    accountNumber: null,
  })

  usePrompt(t("messages.unsavedChangesNavPrompt"), isDirty)

  const handleChangeAddress = useCallback((value) => {
    setAddress(value)
    setIsDirty(true)
    setErrors((prev) => ({
      ...prev,
      address: null,
    }))
  }, [])

  const handleChangeAccountManager = useCallback((selection) => {
    setIsDirty(true)
    setAccountManager(selection)
  }, [])

  const handleBlurRegion = useCallback(() => {
    if (!region) {
      setErrors((prev) => ({
        ...prev,
        region: t("component.franchiseeForm.validation.region.required"),
      }))
    }
  }, [region, t])

  const handleChangeRegion = useCallback((selection: Region | null) => {
    setIsDirty(true)
    setRegion(selection)
    if (selection?.languageCode) {
      setLanguageCode(selection.languageCode)
    }
    setErrors((prev) => ({
      ...prev,
      region: null,
      languageCode: selection?.languageCode ? null : prev.languageCode,
    }))
  }, [])

  const handleBlurLanguageCode = useCallback(() => {
    if (!languageCode) {
      setErrors((prev) => ({
        ...prev,
        languageCode: t("component.franchiseeForm.validation.languageCode.required"),
      }))
    }
  }, [languageCode, t])

  const handleChangeLanguageCode = useCallback((selection?: LanguageCode | null) => {
    setIsDirty(true)
    setLanguageCode(selection)
    setErrors((prev) => ({
      ...prev,
      languageCode: null,
    }))
  }, [])

  const handleChangeMainPhone = (event: { target: { value: any } }) => {
    const value = event.target.value
    setPhoneNumber(value ?? "")
    setIsDirty(true)
  }

  const handleChangeOwnerPhone = (event: { target: { value: any } }) => {
    const value = event.target.value
    setOwnerMobilePhoneNumber(value ?? "")
    setIsDirty(true)
  }

  const handleBlurOwnerEmail = useCallback(() => {
    if (!isValidEmail(ownerEmail)) {
      setErrors((prev) => ({
        ...prev,
        ownerEmail: t("component.franchiseeForm.validation.ownerEmail.invalid"),
      }))
    } else if (isBlank(ownerEmail)) {
      setErrors((prev) => ({
        ...prev,
        ownerEmail: t("component.franchiseeForm.validation.ownerEmail.required"),
      }))
    } else {
      setErrors((prev) => ({ ...prev, ownerEmail: null }))
    }
  }, [ownerEmail, t])

  const handleChangeOwnerEmail = useCallback(
    (e) => {
      const updatedOwnerEmail = e.target.value
      setOwnerEmail(updatedOwnerEmail)
      setIsDirty(true)
      if (isBlank(updatedOwnerEmail)) {
        setErrors((prev) => ({
          ...prev,
          ownerEmail: t("component.franchiseeForm.validation.ownerEmail.required"),
        }))
      } else {
        setErrors((prev) => ({ ...prev, ownerEmail: null }))
      }
    },
    [t]
  )

  const handleBlurOwnerFirstName = useCallback(() => {
    if (!isBlank(ownerFirstName)) {
      capitalize.words(ownerFirstName, true)
    }
  }, [ownerFirstName])

  const handleChangeOwnerFirstName = useCallback((e) => {
    setOwnerFirstName(e.target.value)
    setIsDirty(true)
  }, [])

  const handleBlurOwnerLastName = useCallback(() => {
    if (!isBlank(ownerLastName)) {
      capitalize.words(ownerLastName, true)
    }
  }, [ownerLastName])

  const handleChangeOwnerLastName = useCallback((e) => {
    setOwnerLastName(e.target.value)
    setIsDirty(true)
  }, [])

  const validateAllFields = (shouldSendInvitation: boolean): boolean => {
    const newErrors = { ...errors }

    newErrors.tradeName = isBlank(tradeName)
      ? t("component.franchiseeForm.validation.tradeName.required")
      : null
    newErrors.region = !region ? t("component.franchiseeForm.validation.region.required") : null
    newErrors.languageCode = !languageCode
      ? t("component.franchiseeForm.validation.languageCode.required")
      : null
    newErrors.address = !address ? t("component.franchiseeForm.validation.address.required") : null

    if (
      (shouldSendInvitation ||
        Boolean(ownerUser?.id) ||
        !isBlank(ownerFirstName) ||
        !isBlank(ownerLastName) ||
        !isBlank(ownerMobilePhoneNumber)) &&
      isBlank(ownerEmail)
    ) {
      // the ownerEmail is required if sending the invitation or if there is already an existing owner
      // or if the user has entered the owner's first name, last name, or mobile phone number without the ownerEmail
      newErrors.ownerEmail = t("component.franchiseeForm.validation.ownerEmail.required")
    } else if (!isBlank(ownerEmail) && !isValidEmail(ownerEmail)) {
      newErrors.ownerEmail = t("component.franchiseeForm.validation.ownerEmail.invalid")
    } else {
      newErrors.ownerEmail = null
    }

    setErrors(newErrors)

    return !Object.values(newErrors).some((value) => value !== null)
  }

  const handleSave = (shouldSendInvitation: boolean) => {
    const isFormValid = validateAllFields(shouldSendInvitation)
    setShouldSendInvitation(shouldSendInvitation)

    if (isFormValid) {
      handleSubmit({
        id,
        tradeName,
        legalName: legalNameSameAsTradeName ? tradeName : legalName,
        email,
        phoneNumber: phoneNumber.length > 3 ? phoneNumber : "",
        websiteURL,
        address: address ?? undefined,
        regionId: region?.id, // should be ignored by the backend when editing - changing the region currently isn't supported
        languageCode,
        accountNumber,
        accountManagerUserId: accountManager?.id,
        ownerUserId: ownerUser?.id,
        ownerEmail,
        ownerFirstName,
        ownerLastName,
        ownerMobilePhoneNumber,
        shouldSendInvitation,
      })
    } else {
      setSnack({
        messageKey: "component.franchiseeForm.validation.general.formHasErrors",
        variant: "error",
      })
    }
  }

  return (
    <Box
      sx={(theme) => ({
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: "1rem",
        [theme.breakpoints.up("md")]: {
          gridTemplateColumns: "1fr 1fr",
        },
      })}
    >
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Box sx={{ gridRow: "1 / span 3", display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Paper>
          <SectionHeader>
            <label>{t("basicInfo")}</label>
          </SectionHeader>
          <Divider />
          <SectionContent
            sx={(theme) => {
              return {
                [theme.breakpoints.up("md")]: {
                  padding: "1.25rem 2rem",
                },
              }
            }}
          >
            {franchisee?.id ? (
              <Box sx={classes.fieldContainer}>
                <StaticField
                  label={t("status")}
                  showBottomDivider={false}
                  value={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "0.25rem",
                      }}
                    >
                      <Box>
                        <OrganizationStatusPill status={franchisee.status} />
                      </Box>
                    </Box>
                  }
                />
              </Box>
            ) : null}
            <Box sx={classes.fieldContainer}>
              <FielderTextField
                error={!!errors.tradeName}
                fullWidth
                inputProps={{ maxLength: 255 }}
                label={t("tradeName")}
                margin="normal"
                name="tradeName"
                onBlur={() => {
                  if (isBlank(tradeName)) {
                    setErrors({
                      ...errors,
                      tradeName: t("component.franchiseeForm.validation.tradeName.required"),
                    })
                  } else {
                    if (errors.tradeName) {
                      setErrors({ ...errors, tradeName: null })
                    }
                    setTradeName(capitalize.words(tradeName, true))
                  }
                }}
                onChange={(e) => {
                  const updatedTradeName = e.target.value
                  setTradeName(updatedTradeName)
                  if (legalNameSameAsTradeName) {
                    setLegalName(updatedTradeName)
                  }
                  if (isBlank(updatedTradeName)) {
                    setErrors({
                      ...errors,
                      tradeName: t("component.franchiseeForm.validation.tradeName.required"),
                    })
                  } else if (errors.tradeName) {
                    setErrors({ ...errors, tradeName: null })
                  }
                  setIsDirty(true)
                }}
                onFocus={handleFocus}
                required
                type="text"
                value={tradeName}
                variant="outlined"
              />
              {errors.tradeName ? (
                <FieldHelperText error message={errors.tradeName} />
              ) : (
                <FieldHelperText message={t("component.franchiseeForm.helperText.tradeName")} />
              )}
            </Box>
            <Box sx={classes.fieldContainer}>
              {!legalNameSameAsTradeName ? (
                <FielderTextField
                  autoFocus
                  error={!!errors.legalName}
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  label={t("legalName")}
                  margin="normal"
                  name="legalName"
                  onBlur={() => {
                    if (isBlank(legalName)) {
                      setErrors({
                        ...errors,
                        legalName: t("component.franchiseeForm.validation.legalName.required"),
                      })
                    } else {
                      if (errors.legalName) {
                        setErrors({ ...errors, legalName: null })
                      }
                      setLegalName(capitalize.words(legalName, true))
                    }
                  }}
                  onChange={(e) => {
                    const updatedLegalName = e.target.value
                    setLegalName(updatedLegalName)
                    if (isBlank(updatedLegalName)) {
                      setErrors({
                        ...errors,
                        legalName: t("component.franchiseeForm.validation.legalName.required"),
                      })
                    } else if (errors.legalName) {
                      setErrors({ ...errors, legalName: null })
                    }
                    setIsDirty(true)
                  }}
                  onFocus={handleFocus}
                  type="text"
                  value={legalName}
                  variant="outlined"
                />
              ) : (
                <Box sx={{ fontWeight: "600", fontSize: "0.75rem" }}>{t("legalName")}</Box>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={legalNameSameAsTradeName}
                    name="legalNameSameAsTradeName"
                    onChange={(e) => {
                      setLegalNameSameAsTradeName(e.target.checked)

                      if (e.target.checked) {
                        setLegalName(tradeName)
                        setErrors({ ...errors, legalName: null })
                      } else {
                        setLegalName("")
                      }
                    }}
                  />
                }
                label={t("legalNameSameAsTradeName") as string}
              />
              {errors.legalName ? (
                <FieldHelperText error message={errors.legalName} />
              ) : (
                <FieldHelperText message={t("component.franchiseeForm.helperText.legalName")} />
              )}
            </Box>
            <Box sx={classes.fieldContainer}>
              <RegionSelect
                // eslint-disable-next-line react/jsx-no-leaked-render
                disabled={franchisee && !isDraft(franchisee)}
                error={!!errors.region}
                label={t("region") as string}
                name="region"
                onBlur={handleBlurRegion}
                onChange={handleChangeRegion}
                required
                value={region}
              />
              {errors.region ? (
                <FieldHelperText error message={errors.region} />
              ) : (
                <FieldHelperText message={t("component.franchiseeForm.helperText.region")} />
              )}
            </Box>
            <Box sx={classes.fieldContainer}>
              <LanguageSelect
                error={Boolean(errors.languageCode)}
                label={t("language") as string}
                name="language"
                onBlur={handleBlurLanguageCode}
                onChange={handleChangeLanguageCode}
                required
                value={languageCode}
              />
              {errors.languageCode ? (
                <FieldHelperText error message={errors.languageCode} />
              ) : (
                <FieldHelperText message={t("component.franchiseeForm.helperText.languageCode")} />
              )}
            </Box>
            <Box sx={classes.fieldContainer}>
              <UserSelect
                label={t("accountManager") as string}
                name="accountManager"
                onChange={handleChangeAccountManager}
                roleNames={["ACCOUNT_MANAGER_LEVEL_1"]}
                selectedUser={accountManager}
              />
            </Box>
            <Box sx={classes.fieldContainer}>
              <FielderTextField
                error={!!errors.accountNumber}
                fullWidth
                inputProps={{ maxLength: 255 }}
                label={t("accountNumber")}
                margin="normal"
                name="accountNumber"
                onBlur={() => {
                  if (errors.accountNumber) {
                    setErrors({ ...errors, accountNumber: null })
                  }
                  setAccountNumber(capitalize.words(accountNumber, true))
                }}
                onChange={(e) => {
                  const updatedAccountNumber = e.target.value
                  setAccountNumber(updatedAccountNumber)

                  if (errors.accountNumber) {
                    setErrors({ ...errors, accountNumber: null })
                  }
                  setIsDirty(true)
                }}
                onFocus={handleFocus}
                type="text"
                value={accountNumber}
                variant="outlined"
              />
              {errors.accountNumber ? (
                <FieldHelperText error message={errors.accountNumber} />
              ) : null}
            </Box>
            <Box sx={classes.fieldContainer}>
              <AddressAutocompleteField
                defaultValue={address?.addressString}
                hasError={!!errors.address}
                label={t("address") as string}
                onBlur={() => {
                  if (isBlank(address?.addressString)) {
                    setErrors({
                      ...errors,
                      address: t("component.franchiseeForm.validation.address.required"),
                    })
                  } else {
                    setErrors({ ...errors, address: null })
                  }
                }}
                onChange={handleChangeAddress}
                required
              />
              {errors.address ? (
                <FieldHelperText error message={errors.address} />
              ) : (
                <FieldHelperText message={t("component.franchiseeForm.helperText.address")} />
              )}
            </Box>
            <Box sx={classes.fieldContainer}>
              <PhoneNumberInput
                label={t("phoneNumber")}
                margin="normal"
                onChange={handleChangeMainPhone}
                value={phoneNumber}
              />
            </Box>
            <Box sx={classes.fieldContainer}>
              <FielderTextField
                error={!!errors.email}
                fullWidth
                inputProps={{ maxLength: 50 }}
                label={t("emailAddress")}
                margin="normal"
                name="email"
                onBlur={(e) => {
                  const updatedEmail = e.target.value
                  if (!isBlank(updatedEmail) && !isValidEmail(updatedEmail)) {
                    setErrors({
                      ...errors,
                      email: t("component.franchiseeForm.validation.email.invalid"),
                    })
                  } else {
                    setErrors({ ...errors, email: null })
                  }
                }}
                onChange={(e) => {
                  const updatedEmail = e.target.value
                  setEmail(updatedEmail)
                  setIsDirty(true)
                  if (isValidEmail(updatedEmail)) {
                    setErrors({ ...errors, email: null })
                  }
                }}
                onFocus={handleFocus}
                required={false}
                type="email"
                value={email}
              />
              {errors.email ? (
                <FieldHelperText error message={errors.email} />
              ) : (
                <FieldHelperText message={t("component.franchiseeForm.helperText.email")} />
              )}
            </Box>
            <Box sx={classes.fieldContainer}>
              <FielderTextField
                fullWidth
                inputProps={{ maxLength: 255 }}
                label={t("websiteAddress")}
                margin="normal"
                name="websiteURL"
                onChange={(e) => {
                  setWebsiteURL(e.target.value)
                  setIsDirty(true)
                }}
                onFocus={handleFocus}
                required={false}
                type="url"
                value={websiteURL}
              />
            </Box>
            {!isDraftOrPending(franchisee) ? (
              <ButtonPanel
                franchisee={franchisee}
                handleSave={handleSave}
                loading={loading}
                onCancel={onCancel}
                ownerEmail={ownerEmail}
                setShowConfirmSendInvitationDialog={setShowConfirmSendInvitationDialog}
                shouldSendInvitation={shouldSendInvitation}
                sx={{ padding: 0, marginTop: "1.5rem" }}
                t={t}
              />
            ) : null}
          </SectionContent>
        </Paper>
        {!franchisee?.id || isDraft(franchisee) || isPendingWithUnverifiedOwner(franchisee) ? (
          <Paper>
            <SectionHeader>
              <label>{t("ownerInfo")}</label>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.5rem",
                  alignItems: "center",
                }}
              >
                {!isDraft(franchisee) ? (
                  <Box>
                    {franchisee?.id && franchisee?.owner?.status ? (
                      <UserStatusPill status={franchisee.owner.status} />
                    ) : null}
                  </Box>
                ) : null}
              </Box>
            </SectionHeader>
            <Divider />
            <SectionContent
              sx={(theme) => {
                return {
                  [theme.breakpoints.up("md")]: {
                    padding: "1.25rem 2rem",
                  },
                }
              }}
            >
              <Box sx={classes.fieldContainer}>
                <FielderTextField
                  error={!!errors.ownerEmail}
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  label={t("emailAddress")}
                  margin="normal"
                  name="ownerEmail"
                  onBlur={handleBlurOwnerEmail}
                  onChange={handleChangeOwnerEmail}
                  onFocus={handleFocus}
                  required={Boolean(ownerUser?.id)}
                  type="email"
                  value={ownerEmail}
                />
                {errors.ownerEmail ? (
                  <FieldHelperText error message={errors.ownerEmail} />
                ) : (
                  <FieldHelperText message={t("component.franchiseeForm.helperText.ownerEmail")} />
                )}
              </Box>
              <Box sx={classes.fieldContainer}>
                <FielderTextField
                  error={!!errors.ownerFirstName}
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  label={t("firstName")}
                  margin="normal"
                  name="ownerFirstName"
                  onBlur={handleBlurOwnerFirstName}
                  onChange={handleChangeOwnerFirstName}
                  onFocus={handleFocus}
                  type="name"
                  value={ownerFirstName}
                />
              </Box>
              <Box sx={classes.fieldContainer}>
                <FielderTextField
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  label={t("lastName")}
                  margin="normal"
                  name="ownerLastName"
                  onBlur={handleBlurOwnerLastName}
                  onChange={handleChangeOwnerLastName}
                  onFocus={handleFocus}
                  type="name"
                  value={ownerLastName}
                />
              </Box>
              <Box sx={classes.fieldContainer}>
                <PhoneNumberInput
                  label={t("mobilePhone")}
                  margin="normal"
                  onChange={handleChangeOwnerPhone}
                  value={ownerMobilePhoneNumber}
                />
              </Box>
            </SectionContent>
          </Paper>
        ) : isDraftOrPending(franchisee) ? (
          <OwnerInfoStatic franchisee={franchisee} />
        ) : null}
        {isDraftOrPending(franchisee) ? (
          <Paper>
            <ButtonPanel
              franchisee={franchisee}
              handleSave={handleSave}
              loading={loading}
              onCancel={onCancel}
              ownerEmail={ownerEmail}
              setShowConfirmSendInvitationDialog={setShowConfirmSendInvitationDialog}
              shouldSendInvitation={shouldSendInvitation}
              t={t}
            />
          </Paper>
        ) : null}
      </Box>
      {isDraftOrPending(franchisee) ? (
        <Box>
          <QuickTip
            messageKey={
              !franchisee?.id
                ? "component.franchiseeForm.quickTip.message.create"
                : isDraft(franchisee)
                  ? "component.franchiseeForm.quickTip.message.draft"
                  : "component.franchiseeForm.quickTip.message.pending"
            }
          />
        </Box>
      ) : null}
      {franchisee?.id && !isDraftOrPending(franchisee) ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <OwnerInfoStatic franchisee={franchisee} />
          <FranchiseeSubscriptionInfo franchisee={franchisee} />
        </Box>
      ) : null}
      {showConfirmSendInvitationDialog && ownerEmail ? (
        <ConfirmationDialog
          description={t("component.franchiseeForm.messages.confirmSendInvitation.description", {
            email: ownerEmail,
          })}
          id="confirm-send-invitation"
          isLoading={loading}
          negativeButtonTitle={t("no")}
          onCancel={() => {
            setShowConfirmSendInvitationDialog(false)
          }}
          onConfirm={() => {
            if (ownerEmail) {
              handleSave(true)
            } else {
              setShowConfirmSendInvitationDialog(false)
            }
          }}
          positiveButtonTitle={t("yes")}
          title={t("component.franchiseeForm.messages.confirmSendInvitation.title")}
          titleBackgroundColor="#ffffff"
        />
      ) : null}
    </Box>
  )
}

interface ButtonPanelProps {
  readonly franchisee?: Organization
  readonly loading?: boolean
  readonly onCancel: () => void
  readonly handleSave: (shouldSendInvitation: boolean) => void
  readonly setShowConfirmSendInvitationDialog: (show: boolean) => void
  readonly ownerEmail: string
  readonly shouldSendInvitation: boolean
  readonly t: (key: string) => string
  readonly sx?: any
}

function ButtonPanel({
  franchisee,
  loading,
  onCancel,
  handleSave,
  setShowConfirmSendInvitationDialog,
  ownerEmail,
  shouldSendInvitation,
  t,
  sx,
}: ButtonPanelProps) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1rem",
        ...sx,
      }}
    >
      <Button
        color="secondary"
        data-testid="cancelButton"
        disabled={loading}
        onClick={onCancel}
        variant="outlined"
      >
        {t("cancel")}
      </Button>
      {!franchisee || isDraft(franchisee) ? (
        <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <SaveButton
            disabled={loading}
            label={t("component.franchiseeForm.saveDraft") as string}
            // eslint-disable-next-line react/jsx-no-leaked-render
            loading={loading && !shouldSendInvitation}
            onClick={() => handleSave(false)}
            sx={{
              minWidth: "140px",
              backgroundColor: "#e3e3e3",
              "&:hover": {
                backgroundColor: "#d6d6d6",
              },
            }}
          />
          <SaveButton
            disabled={loading || !ownerEmail || !isValidEmail(ownerEmail)}
            label={t("component.franchiseeForm.saveAndSendInvitation") as string}
            // eslint-disable-next-line react/jsx-no-leaked-render
            // loading={loading && shouldSendInvitation}
            onClick={() => setShowConfirmSendInvitationDialog(true)}
            sx={{ minWidth: "140px" }}
          />
        </Box>
      ) : (
        <SaveButton
          disabled={loading}
          label={t("component.franchiseeForm.save") as string}
          loading={loading}
          onClick={() => handleSave(false)}
          sx={{ minWidth: "140px" }}
        />
      )}
    </Box>
  )
}

const classes = {
  fieldContainer: {
    marginBottom: "1.25rem",
  },
  staticFieldContainer: {
    padding: "1.25rem",
    paddingTop: "0.3125rem",
    paddingBottom: "0.3125rem",
    "& label": {
      fontWeight: 700,
      marginBottom: "0.25rem",
    },
    "& hr": {
      marginTop: "0.625rem",
    },
  },
  value: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: "20px",
  },
} as const

export default FranchiseeForm
