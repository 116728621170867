import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { nanoid } from "nanoid"
import { useMutation, useQuery, gql } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import CircularProgress from "@mui/material/CircularProgress"
import Paper from "@mui/material/Paper"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/EditOutlined"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import WarningIcon from "@mui/icons-material/Warning"
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrashOutlined"
import SendIcon from "@mui/icons-material/ForwardToInboxOutlined"
import Skeleton from "@mui/material/Skeleton"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"

import { ALL_ESTIMATES_FOR_JOB } from "~/queries/allEstimatesForJob"
import { SEND_ESTIMATE_TO_PLUGIN } from "~/queries/sendEstimateToPlugin"
import { EDIT_ESTIMATE } from "~/queries/editEstimate"
import { SEND_JOB_EMAIL } from "~/queries/sendJobEmail"
import {
  calculateSubtotal,
  calculateTotal,
  createDayJS,
  formatDate,
  formatDiscountValueForInput,
  formatMoney,
  formatPersonName,
  getJobDocumentDisplayNumber,
  isNumeric,
  NOT_SPECIFIED,
  parseGraphQLErrorCode,
} from "~/util"
import { useAuth } from "~/context/AuthContext"
import PdfIcon from "~/components/icons/PdfIcon"
import FielderIconButton from "~/components/FielderIconButton"
import NoResultsRow from "~/components/NoResultsRow"
import SnackbarMessage from "~/components/SnackbarMessage"
import EstimateDialog from "./EstimateDialog"
import { Job, Snack, Estimate, DefaultPermission, DiscountType, EstimateStatus } from "~/types"
import useGetAccountingPlugin from "~/hooks/useGetAccountingPlugin"
import EmailDialog, { EmailDialogMode } from "~/components/EmailDialog"
import { EstimateStatusPill } from "./EstimateStatusPill"

const GET_ESTIMATE_FILE = gql`
  query GetEstimateFile($id: ID!) {
    getEstimateById(id: $id) {
      id
      number
      pdfFile
    }
  }
`

const NUM_COLUMNS = 6

interface JobEstimateTabProps {
  readonly job: Job
}

function JobEstimateTab({ job }: JobEstimateTabProps) {
  const { t } = useTranslation()
  const { user, hasPermissions, isImpersonating } = useAuth()
  const [emailDialogOpen, setEmailDialogOpen] = useState<boolean>(false)
  const [estimateDialogOpen, setEstimateDialogOpen] = useState<boolean>(false)
  const [estimateToEdit, setEstimateToEdit] = useState<Estimate>()
  const [loadEstimateFileId, setLoadEstimateFileId] = useState<string>()
  const [snack, setSnack] = useState<Snack>()
  const [includeArchived, setIncludeArchived] = useState<boolean>(false)
  const [openArchiveDialog, setOpenArchiveDialog] = useState<boolean>(false)
  const canEdit = !isImpersonating && hasPermissions?.([DefaultPermission.UpdateEstimate])
  const location = useLocation()
  const [stateEstimateId, setStateEstimateId] = useState<string | undefined>(() => {
    const state = location?.state as { estimateId?: string }
    return state?.estimateId
  })
  const { accountingPlugin } = useGetAccountingPlugin(user?.organization?.id)

  const timeZone = user?.organization?.timeZone ?? "Etc/UTC"

  const { loading: fileLoading } = useQuery(GET_ESTIMATE_FILE, {
    variables: {
      id: loadEstimateFileId,
    },
    skip: !loadEstimateFileId,
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setLoadEstimateFileId(undefined)

      const pdfString = data?.getEstimateById?.pdfFile
      if (!pdfString) {
        return
      }

      const byteCharacters = atob(pdfString)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const file = new Blob([byteArray], { type: "application/pdf;base64" })
      // eslint-disable-next-line compat/compat
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL)
    },
    onError: (error) => {
      setLoadEstimateFileId(undefined)
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const {
    data: allEstimatesData,
    loading: allEstimatesLoading,
    error: allEstimatesError,
  } = useQuery(ALL_ESTIMATES_FOR_JOB, {
    variables: {
      id: job.id,
      includeArchived,
    },
    fetchPolicy: "cache-and-network",
  })

  const estimates = [...(allEstimatesData?.getJobById?.estimates ?? [])].sort(
    (a, b) => b.number - a.number
  )

  if (stateEstimateId) {
    const estimate = estimates.find((estimate) => estimate.id === stateEstimateId)
    if (estimate) {
      handleEdit(estimate)
      setStateEstimateId(undefined)
    }
  }

  const [archiveEstimate, { loading: archiveEstimateLoading }] = useMutation(EDIT_ESTIMATE, {
    onCompleted: (data) => {
      setEstimateToEdit(undefined)
    },
    onError: (error) => {
      Sentry.captureException(error)
    },
    refetchQueries: () => {
      return ["AllEstimatesForJob"]
    },
  })

  const [sendEstimate, { loading: sendEstimateLoading }] = useMutation(SEND_JOB_EMAIL, {
    onCompleted: () => {
      setEmailDialogOpen(false)
      setSnack({ messageKey: "messages.messageSent", variant: "success" })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
    refetchQueries: () => {
      return ["AllEstimatesForJob"]
    },
  })

  const [sendEstimateToPlugin, { loading: sendEstimateToPluginLoading }] = useMutation(
    SEND_ESTIMATE_TO_PLUGIN,
    {
      onCompleted: () => {
        setSnack({
          messageKey: "page.estimate.sentToPlugin.success",
          messageOptions: { pluginName: accountingPlugin?.pluginProvider?.name ?? "the plugin" },
          variant: "success",
        })
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = error ? parseGraphQLErrorCode(error) : "page.estimate.sentToPlugin.error"
        setSnack({
          messageKey: errorCode,
          messageOptions: { pluginName: accountingPlugin?.pluginProvider?.name ?? "the plugin" },
          variant: "error",
        })
      },
      refetchQueries: () => {
        return ["AllEstimatesForJob"]
      },
    }
  )

  function handleSendToPlugin(estimate: Estimate) {
    sendEstimateToPlugin({
      variables: {
        estimateId: estimate.id,
      },
    })
  }

  function handleEdit(estimate: Estimate) {
    const editableLineItems = estimate.lineItems
      ?.map((li) => {
        return {
          ...li,
          organizationItemId: li.organizationItem?.id,
          key: li.id,
          quantity: isNumeric(li.quantity) ? `${li.quantity}` : "",
          unitPrice: isNumeric(li.unitPrice) ? `${li.unitPrice}` : "",
          taxRateGroup: li.taxRateGroup,
          total: li.total,
        }
      })
      .sort((a, b) => (a.number ?? 0) - (b.number ?? 0))

    const discount = {
      value: estimate.discount ? formatDiscountValueForInput(estimate.discount) : 0,
      type: estimate.discount?.type ?? DiscountType.PERCENTAGE,
    }

    const editableEstimate = {
      id: estimate.id,
      job: job,
      organization: job.organization,
      number: estimate.number,
      currencyCode: estimate.currencyCode ?? user?.organization?.currencyCode,
      billingContact: estimate.billingContact?.id ? estimate.billingContact : null,
      jobAddress: job.address,
      status: estimate.status,
      discount: discount,
      footerTitle: estimate.footerTitle,
      footerBody: estimate.footerBody,
      contractJson: estimate.contractJson,
      expirationDate: estimate.expirationDate,
      subTotal: calculateSubtotal(estimate.lineItems),
      total: calculateTotal(estimate.lineItems, discount.value, discount.type),
      taxSummary: estimate.taxSummary,
      createdAt: estimate.createdAt,
      description: estimate.description,
      notes: estimate.notes,
      lineItems: editableLineItems,
      customerVisibility: estimate.customerVisibility,
    }

    setEstimateToEdit(editableEstimate)
    setEstimateDialogOpen(true)
  }

  function createNewEstimate() {
    return {
      jobAddress: job.address,
      description: job.description || "",
      notes: "",
      currencyCode: user?.organization?.currencyCode,
      status: EstimateStatus.PENDING_SUBMISSION,
      lineItems: [
        {
          number: 1,
          key: nanoid(),
          unitPrice: "",
          quantity: "1",
          organizationItemId: "",
          taxRateGroup: { id: "" },
        },
      ],
      subTotal: 0,
      discount: {
        value: "",
        type: DiscountType.PERCENTAGE,
      },
      total: 0,
      totalTax: 0,
      footerTitle: "",
      footerBody: "",
      contractJson: null,
      job,
      organization: job.organization,
    }
  }

  const isLoading =
    sendEstimateToPluginLoading ||
    sendEstimateLoading ||
    archiveEstimateLoading ||
    allEstimatesLoading

  return (
    <>
      {estimateDialogOpen && estimateToEdit ? (
        <EstimateDialog
          estimate={estimateToEdit}
          onCancel={() => setEstimateDialogOpen(false)}
          onSave={(estimate) => {
            setEstimateDialogOpen(false)
            if (estimate) {
              setSnack({ messageKey: "messages.changesSaved", variant: "success" })
            } else {
              setSnack({ messageKey: "messages.error", variant: "error" })
            }
          }}
          open={estimateDialogOpen}
          user={user}
        />
      ) : null}
      {emailDialogOpen && estimateToEdit ? (
        <EmailDialog
          estimate={estimateToEdit}
          isJobEditable={false}
          job={job}
          mode={EmailDialogMode.COMPOSE_NEW}
          onCancel={() => {
            setEmailDialogOpen(false)
          }}
          onSend={(payload) => {
            sendEstimate({ variables: payload })
          }}
          sending={sendEstimateLoading}
        />
      ) : null}
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Paper
        sx={{
          overflowX: "auto",
          marginBottom: "1.25rem",
          maxHeight: "500px",
          height: "31.25rem",
        }}
      >
        <Table
          stickyHeader
          sx={[
            {
              width: "100%",
              minWidth: "68rem",
            },
            estimates?.length === 0 && { height: "100%" },
          ]}
        >
          <TableHead>
            <TableRow>
              <TableCell sx={classes.tableHead}>{t("estimateNumber")}</TableCell>
              <TableCell sx={classes.tableHead}>{t("dateCreated")}</TableCell>
              <TableCell sx={classes.tableHead}>{t("total")}</TableCell>
              <TableCell sx={classes.tableHead}>{t("status")}</TableCell>
              <TableCell sx={classes.tableHead} />
              <TableCell sx={[classes.tableHead, { textAlign: "right" }]}>
                {!isImpersonating && hasPermissions?.([DefaultPermission.CreateEstimate]) ? (
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                    <Box sx={{ marginRight: "1rem" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={includeArchived}
                            onChange={(event, checked) => {
                              setIncludeArchived(checked)
                            }}
                            value="includeArchived"
                          />
                        }
                        label={t("includeArchived") as string}
                      />
                    </Box>
                    <Button
                      aria-label={t("createEstimate")}
                      color="primary"
                      data-testid="createEstimateButton"
                      onClick={() => {
                        setEstimateToEdit(createNewEstimate())
                        setEstimateDialogOpen(true)
                      }}
                      sx={{
                        fontWeight: "bold",
                        "& svg": {
                          fontSize: "1.0rem",
                        },
                        "& div": {
                          marginLeft: "0.625rem",
                          marginRight: "0.625rem",
                        },
                      }}
                      variant="contained"
                    >
                      <AddIcon />
                      <Box>{t("createEstimate")}</Box>
                    </Button>
                  </Box>
                ) : null}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={[estimates?.length === 0 && { height: "100%" }]}>
            {!allEstimatesError && !allEstimatesLoading && estimates?.length === 0 && (
              <NoResultsRow colSpan={NUM_COLUMNS} />
            )}
            {!allEstimatesError &&
              estimates?.map((node) => {
                const createdAt = createDayJS(node.createdAt, timeZone)

                return (
                  <TableRow
                    key={node.id}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      if (canEdit) {
                        handleEdit(node)
                      } else {
                        setLoadEstimateFileId(node.id)
                      }
                    }}
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                        backgroundColor: "#f8f8f8",
                      },
                    }}
                  >
                    <TableCell>{getJobDocumentDisplayNumber(node)}</TableCell>
                    <TableCell>
                      <Box>
                        <Box>
                          {createdAt
                            ? createdAt.format(t("format:dateFormat.shortWithAtTime") as string)
                            : NOT_SPECIFIED}
                        </Box>
                        <Box>{formatPersonName(node.createdBy)}</Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {formatMoney(
                        node.currencyCode ?? user?.organization?.currencyCode,
                        node.total
                      )}
                    </TableCell>
                    <TableCell>
                      <EstimateStatusPill estimateStatus={node.status} />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", flexDirection: "row", gap: "2rem" }}>
                        {node.issuedDate ? (
                          <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box>{t("sentToCustomer")}</Box>
                            <Box>
                              {formatDate(
                                node.issuedDate,
                                t("format:dateFormat.shortWithAtTime"),
                                timeZone,
                                user
                              )}
                            </Box>
                          </Box>
                        ) : null}
                        {node.pluginProvider && node.dateSentToPluginProvider ? (
                          <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <a
                              href={node.pluginProviderUrl}
                              onClick={(e) => {
                                e.stopPropagation()
                              }}
                              rel="noreferrer"
                              style={{
                                textDecoration: "none",
                              }}
                              target="_blank"
                            >
                              <Box
                                sx={{
                                  display: "inline-flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: "0.15rem",
                                }}
                              >
                                <Box component="span" sx={{ textDecoration: "underline" }}>
                                  {t(`sentToPlugin.${node.pluginProvider.id}`)}
                                </Box>
                                <OpenInNewIcon sx={{ fontSize: "0.75rem" }} />
                              </Box>
                              <Box sx={{ color: (theme) => theme.fielderColors.text }}>
                                {formatDate(
                                  node.dateSentToPluginProvider,
                                  t("format:dateFormat.shortWithAtTime"),
                                  timeZone,
                                  user
                                )}
                              </Box>
                            </a>
                          </Box>
                        ) : null}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          gap: "0.25rem",
                        }}
                      >
                        {node.isArchived ? (
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "auto",
                              backgroundColor: "rgba(218, 21, 5, 0.1)",
                              padding: "4px 8px",
                              borderRadius: "4px",
                            }}
                          >
                            <WarningIcon
                              style={{
                                fontSize: "1.125rem",
                                color: "#DA1505",
                                marginRight: "0.625rem",
                              }}
                            />
                            <span>{t("thisEstimateHasBeenArchived")}</span>
                          </Box>
                        ) : null}
                        {!isImpersonating &&
                        accountingPlugin?.pluginProvider?.id === "quickbooks" &&
                        hasPermissions?.([DefaultPermission.SendEstimate]) &&
                        !node.isArchived ? (
                          <FielderIconButton
                            disabled={Boolean(isLoading && estimateToEdit?.id === node.id)}
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setEstimateToEdit(node)
                              handleSendToPlugin(node)
                            }}
                            sx={classes.rowAction}
                            title={
                              t(`sendToPlugin.${accountingPlugin.pluginProvider.id}`) as string
                            }
                          >
                            {sendEstimateToPluginLoading && estimateToEdit?.id === node.id ? (
                              <CircularProgress size={20} thickness={6.0} />
                            ) : (
                              <img height={20} src={accountingPlugin.logoPath} width={20} />
                            )}
                          </FielderIconButton>
                        ) : null}
                        {!isImpersonating &&
                        hasPermissions?.([DefaultPermission.SendEstimate]) &&
                        !node.isArchived ? (
                          <FielderIconButton
                            disabled={Boolean(
                              (sendEstimateLoading || archiveEstimateLoading) &&
                                estimateToEdit?.id === node.id
                            )}
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setEstimateToEdit(node)
                              setEmailDialogOpen(true)
                            }}
                            sx={classes.rowAction}
                            title={t("sendToCustomer") as string}
                          >
                            {sendEstimateLoading && estimateToEdit?.id === node.id ? (
                              <CircularProgress size={20} thickness={6.0} />
                            ) : (
                              <SendIcon />
                            )}
                          </FielderIconButton>
                        ) : null}
                        {!node.isArchived && (
                          <FielderIconButton
                            disabled={
                              ((sendEstimateLoading || archiveEstimateLoading) &&
                                estimateToEdit?.id === node.id) ||
                              (fileLoading && loadEstimateFileId === node.id)
                            }
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setLoadEstimateFileId(node.id)
                            }}
                            sx={classes.rowAction}
                            title={t("viewPDF") as string}
                          >
                            {fileLoading && loadEstimateFileId === node.id ? (
                              <CircularProgress color="secondary" size={20} thickness={6.0} />
                            ) : (
                              <PdfIcon />
                            )}
                          </FielderIconButton>
                        )}
                        {canEdit ? (
                          <FielderIconButton
                            aria-label={t("edit") as string}
                            disabled={Boolean(
                              (sendEstimateLoading || archiveEstimateLoading) &&
                                estimateToEdit?.id === node.id
                            )}
                            sx={classes.rowAction}
                            title={t("edit") as string}
                          >
                            <EditIcon />
                          </FielderIconButton>
                        ) : null}
                        {!isImpersonating &&
                        hasPermissions?.([DefaultPermission.UpdateEstimate]) ? (
                          <FielderIconButton
                            disabled={Boolean(
                              archiveEstimateLoading && estimateToEdit?.id === node.id
                            )}
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setEstimateToEdit(node)
                              setOpenArchiveDialog(true)
                            }}
                            sx={classes.rowAction}
                            title={(node.isArchived ? t("unarchive") : t("archive")) as string}
                          >
                            {archiveEstimateLoading && estimateToEdit?.id === node.id ? (
                              <CircularProgress size={20} thickness={6.0} />
                            ) : node.isArchived ? (
                              <RestoreFromTrashIcon />
                            ) : (
                              <DeleteIcon />
                            )}
                          </FielderIconButton>
                        ) : null}
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              })}
            {allEstimatesLoading
              ? [...Array(NUM_COLUMNS).keys()].map((i) => (
                  <TableRow key={i}>
                    {[...Array(NUM_COLUMNS).keys()].map((j) => (
                      <TableCell key={j}>
                        <Skeleton variant="text" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : null}
            {allEstimatesError ? (
              <TableRow>
                <TableCell align="center" colSpan={NUM_COLUMNS}>
                  <Typography color="error" variant="h6">
                    {t("errorLabel")}
                    {": "}
                    {allEstimatesError.message}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </Paper>
      {openArchiveDialog && estimateToEdit ? (
        <Dialog
          aria-describedby="archive-dialog-description"
          aria-labelledby="archive-dialog-title"
          onClose={() => setOpenArchiveDialog(false)}
          open={openArchiveDialog}
        >
          <DialogTitle id="archive-dialog-title">{t("areYouSure")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="archive-dialog-description">
              {estimateToEdit.isArchived
                ? t("component.unarchiveEstimateDialog.confirmationPrompt", {
                    estimateNumber: getJobDocumentDisplayNumber(estimateToEdit),
                  })
                : t("component.archiveEstimateDialog.confirmationPrompt", {
                    estimateNumber: getJobDocumentDisplayNumber(estimateToEdit),
                  })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setOpenArchiveDialog(false)}>
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              onClick={() => {
                archiveEstimate({
                  variables: {
                    id: estimateToEdit.id,
                    jobId: job.id,
                    isArchived: !estimateToEdit.isArchived,
                  },
                })
                setOpenArchiveDialog(false)
              }}
            >
              {t("yes")}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  )
}

const classes = {
  tableHead: {
    backgroundColor: "#f9f9f9",
    zIndex: 1000,
    position: "sticky",
    top: 0,
  },
  rowAction: {
    marginLeft: "0.5rem",
  },
} as const

export default JobEstimateTab
