import gql from "graphql-tag"
import { useQuery } from "@apollo/client"

import { useAuth } from "~/context/AuthContext"
import { DefaultPermission } from "~/types"


export const GET_ESTIMATE_REQUEST_COUNT = gql`
  query GetEstimateRequestCount($id: ID!) {
    getOrganizationById(id: $id) {
      id
      estimateRequestCount(filter: { statuses: [NEW] })
    }
  }
`

export default function useGetEstimateRequestCount(): number {
  const { user, hasPermissions } = useAuth()
  const { data } = useQuery(GET_ESTIMATE_REQUEST_COUNT, {
    variables: {
      id: user?.organization?.id,
    },
    skip: !user?.organization?.id || !hasPermissions?.([DefaultPermission.ReadEstimateRequest]), // Do not make the network request if the user does not have the required permissions! Otherwise they may not be able to do anything in the app.
    pollInterval: 60000,
    fetchPolicy: "network-only",
  })
  const organization = data?.getOrganizationById
  return organization?.estimateRequestCount ?? 0
}
