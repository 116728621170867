import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import BusinessOutlined from "@mui/icons-material/BusinessOutlined"
import WorkOutlineOutlined from "@mui/icons-material/WorkOutlineOutlined"
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined"

import TaskIcon from "~/components/icons/TaskIcon"
import InventoryIcon from "~/components/icons/InventoryIcon"
import {
  Address,
  Customer,
  Estimate,
  Invoice,
  Item,
  Job,
  ProductOrder,
  SearchResult,
  SearchResultType,
  Task,
  TaskStatus,
} from "~/types"
import { formatDate, formatMoney, formatPersonName, truncate } from "~/util"
import { useAuth } from "~/context/AuthContext"

function formatAddress(address: Address): string {
  return `${address.streetNumber} ${address.route}, ${address.locality}`
}

interface JobDetailsProps {
  readonly job: Job
}

function JobDetails({ job }: JobDetailsProps) {
  const { t } = useTranslation()

  return (
    <Box sx={classes.resultContainer}>
      <WorkOutlineOutlined />
      <Box sx={classes.resultDetailsContainer}>
        <Box sx={classes.title}>
          {t("job")} #{job.number} - {job.customer.name}
        </Box>
        <Box
          sx={[
            classes.subdetailsContainer,
            {
              color: (theme) => theme.fielderColors.mutedText,
            },
          ]}
        >
          {formatAddress(job.jobAddress)}
        </Box>
      </Box>
      <Box
        sx={[
          classes.resultStatusContainer,
          {
            backgroundColor: job.workflowStep?.jobStatus?.mediumColor,
            color: "#FFFFFF",
            fontWeight: "600",
          },
        ]}
      >
        {job.workflowStep?.jobStatus.name}
      </Box>
    </Box>
  )
}

interface CustomerDetailsProps {
  readonly customer: Customer
}
function CustomerDetails({ customer }: CustomerDetailsProps) {
  const { t } = useTranslation()

  return (
    <Box sx={classes.resultContainer}>
      <BusinessOutlined />
      <Box sx={classes.resultDetailsContainer}>
        <Box sx={classes.title}>
          {t("customer")} - {customer.name}
        </Box>
        <Box
          sx={[
            classes.subdetailsContainer,
            {
              color: (theme) => theme.fielderColors.mutedText,
            },
          ]}
        >
          {customer.address ? formatAddress(customer.address) : ""}
        </Box>
      </Box>
    </Box>
  )
}

interface ItemDetailsProps {
  readonly item: Item
}
function ItemDetails({ item }: ItemDetailsProps) {
  const { t } = useTranslation()

  return (
    <Box sx={classes.resultContainer}>
      <InventoryIcon />
      <Box sx={classes.resultDetailsContainer}>
        <Box sx={classes.title}>
          {t("item")} - {item.code}
        </Box>
        <Box
          sx={[
            classes.subdetailsContainer,
            {
              color: (theme) => theme.fielderColors.mutedText,
            },
          ]}
        >
          {truncate(item.name, 80)}
        </Box>
      </Box>
    </Box>
  )
}

interface TaskDetailsProps {
  readonly task: Task
}
function TaskDetails({ task }: TaskDetailsProps) {
  const { t } = useTranslation()
  const taskStatusColor =
    task.status === TaskStatus.NOT_STARTED
      ? "#fff7b3"
      : task.status == TaskStatus.IN_PROGRESS
        ? "#b3e0ff"
        : "#b7f2aa"

  return (
    <Box sx={classes.resultContainer}>
      <TaskIcon />
      <Box sx={classes.resultDetailsContainer}>
        <Box sx={classes.title}>
          {t("task")} - {truncate(task.taskDescription, 50)}
        </Box>
        <Box
          sx={[
            classes.subdetailsContainer,
            {
              color: (theme) => theme.fielderColors.mutedText,
            },
          ]}
        >
          <Box>
            {t("assignedTo")}: {formatPersonName(task.assignee)}
          </Box>
        </Box>
      </Box>
      <Box sx={[classes.resultStatusContainer, { backgroundColor: taskStatusColor }]}>
        {t(`taskStatus.${task.status}`)}
      </Box>
    </Box>
  )
}

interface EstimateDetailsProps {
  readonly estimate: Estimate
}
function EstimateDetails({ estimate }: EstimateDetailsProps) {
  const { t } = useTranslation()

  return (
    <Box sx={classes.resultContainer}>
      <ReceiptOutlinedIcon />
      <Box sx={classes.resultDetailsContainer}>
        <Box sx={classes.title}>
          {t("estimate")}
          <Box
            sx={{
              marginLeft: "1rem",
              fontWeight: "550",
            }}
          >
            {formatMoney(estimate.currencyCode, estimate.total)}
          </Box>
        </Box>
        <Box
          sx={[
            classes.subdetailsContainer,
            {
              color: (theme) => theme.fielderColors.mutedText,
            },
          ]}
        >
          <Box>
            {t("component.search.forJobAt", {
              jobNumber: estimate.job.number,
              jobAddress: formatAddress(estimate.job.address),
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

interface InvoiceDetailsProps {
  readonly invoice: Invoice
}
function InvoiceDetails({ invoice }: InvoiceDetailsProps) {
  const { t } = useTranslation()

  return (
    <Box sx={classes.resultContainer}>
      <ReceiptOutlinedIcon />
      <Box sx={classes.resultDetailsContainer}>
        <Box sx={classes.title}>
          {t("invoice")}
          <Box
            sx={{
              marginLeft: "1rem",
              fontWeight: "550",
            }}
          >
            {formatMoney(invoice.currencyCode, invoice.total)}
          </Box>
        </Box>
        <Box
          sx={[
            classes.subdetailsContainer,
            {
              color: (theme) => theme.fielderColors.mutedText,
            },
          ]}
        >
          <Box>
            {t("component.search.forJobAt", {
              jobNumber: invoice.job.number,
              jobAddress: formatAddress(invoice.job.address),
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

interface ProductOrderDetailsProps {
  readonly productOrder: ProductOrder
}

function ProductOrderDetails({ productOrder }: ProductOrderDetailsProps) {
  const { t } = useTranslation()
  const { user } = useAuth()

  let productOrderStatusColor = "#606060"
  switch (productOrder.status) {
    case "DRAFT":
      productOrderStatusColor = "#606060"
      break
    case "SUBMITTED":
      productOrderStatusColor = "#367792"
      break
    case "CANCELED":
      productOrderStatusColor = "#7E0000"
      break
    case "IN_PROGRESS":
      productOrderStatusColor = "#49804F"
      break
    case "SHIPPING":
      productOrderStatusColor = "#3F536D"
      break
    case "INVOICING":
      productOrderStatusColor = "#3F6D4F"
      break
    case "COMPLETED":
      productOrderStatusColor = "#0B0B0B"
      break
    default:
      productOrderStatusColor = "#606060"
      break
  }

  return (
    <Box sx={classes.resultContainer}>
      <ReceiptOutlinedIcon />
      <Box sx={classes.resultDetailsContainer}>
        <Box sx={classes.title}>
          {t("productOrder")} #{productOrder.number}
          <Box
            sx={{
              marginLeft: "1rem",
              fontWeight: "550",
            }}
          >
            {formatMoney(productOrder.currencyCode, productOrder.total)}
          </Box>
        </Box>
        <Box
          sx={[
            classes.subdetailsContainer,
            {
              color: (theme) => theme.fielderColors.mutedText,
            },
          ]}
        >
          {productOrder.dateSubmitted ? (
            <Box>
              {t("dateSubmitted")}:{" "}
              {formatDate(
                productOrder.dateSubmitted,
                t("format:dateFormat.short"),
                user?.organization?.timeZone
              )}
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box
        sx={[
          classes.resultStatusContainer,
          { backgroundColor: productOrderStatusColor, color: "#fff" },
        ]}
      >
        {t(`productOrderStatus.${productOrder.status}`)}
      </Box>
    </Box>
  )
}

interface SearchResultItemProps {
  readonly searchResult: SearchResult
}

function SearchResultItem({ searchResult }: SearchResultItemProps) {
  switch (searchResult.type) {
    case SearchResultType.JOB:
      return <JobDetails job={searchResult.record as Job} />
    case SearchResultType.CUSTOMER:
      return <CustomerDetails customer={searchResult.record as Customer} />
    case SearchResultType.TASK:
      return <TaskDetails task={searchResult.record as Task} />
    case SearchResultType.ESTIMATE:
      return <EstimateDetails estimate={searchResult.record as Estimate} />
    case SearchResultType.INVOICE:
      return <InvoiceDetails invoice={searchResult.record as Invoice} />
    case SearchResultType.PRODUCT_ORDER:
      return <ProductOrderDetails productOrder={searchResult.record as ProductOrder} />
    case SearchResultType.ITEM:
      return <ItemDetails item={searchResult.record as Item} />
    default:
      return null
  }
}

const classes = {
  resultContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    alignItems: "center",
    flexGrow: 1,
  },
  resultDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "0.1rem",
    flexGrow: 1,
  },
  resultStatusContainer: {
    borderRadius: "4px",
    padding: "0.25rem 0.5rem",
    fontSize: "0.8rem",
    fontWeight: "500",
    marginLeft: "1rem",
    justifySelf: "flex-end",
  },
  title: {
    fontWeight: "500",
    fontSize: "0.9rem",
    display: "flex",
    flexDirection: "row",
  },
  subdetailsContainer: {
    marginTop: "-0.25rem",
    fontSize: "0.75rem",
    display: "flex",
    flexDirection: "column",
  },
} as const

export default SearchResultItem
