import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import { Seo } from "../../components"
import greenCloud from "../../images/cloud_done_green.png"
import PublicAppBar from "../../components/PublicAppBar"

const FORM_WIDTH = 600

function EstimateRequestConfirmation() {
  const { t } = useTranslation()

  return (
    <>
      <Seo lang="en" title={t("page.estimateRequest.title")} />
      <PublicAppBar />
      <Box
        component="main"
        sx={(theme) => ({
          width: "auto",
          margin: "0 auto",
          [theme.breakpoints.up(FORM_WIDTH)]: {
            width: FORM_WIDTH,
            marginLeft: "auto",
            marginRight: "auto",
          },
        })}
      >
        <Paper
          elevation={0}
          sx={(theme) => ({
            backgroundColor: "transparent",
            margin: "0 auto",
            marginTop: "3rem",
            marginBottom: "3rem",
            padding: "1rem",
            [theme.breakpoints.up(FORM_WIDTH)]: {
              marginTop: "6rem",
              marginBottom: "6rem",
              padding: "3rem",
            },
            fontSize: "1.25rem",
          })}
        >
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <p>
                <strong>{t("page.estimateRequest.thankYou")}</strong>
              </p>
              <p>{t("page.estimateRequest.confirmationMsg")}</p>
              <p
                style={{
                  textAlign: "center",
                }}
              >
                <img src={greenCloud} />
              </p>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  )
}

export default EstimateRequestConfirmation
