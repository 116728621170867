import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "@apollo/client"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import FormControlLabel from "@mui/material/FormControlLabel"
import * as Sentry from "@sentry/react"
import capitalize from "capitalize"

import AddressAutocompleteField from "~/components/AddressAutocompleteField"
import ErrorMessage from "~/components/ErrorMessage"
import FielderTextField from "~/components/FielderTextField"
import FieldHelperText from "~/components/FieldHelperText"
import PhoneNumberInput from "~/components/CustomPhoneNumberInput"
import HardhatIcon from "~/components/icons/HardhatIcon"
import { CREATE_CONTACT } from "~/queries/createContact"
import { getUserHqMarker } from "~/util/mapUtils"
import { isValidEmail, isBlank } from "~/util/stringUtils"
import type { Address, Customer, User } from "~/types/apiTypes"
import type { Coordinate } from "~/types/appTypes"
import SaveButton from "~/components/SaveButton"

interface CreateContactDialogProps {
  readonly canBeBillingContact?: boolean
  readonly canBeJobSiteContact?: boolean
  readonly customer: Customer
  readonly onCancel: () => void
  readonly onSave: (contact: Customer) => void
  readonly showCanBeBillingContactToggle?: boolean
  readonly showCanBeJobSiteContactToggle?: boolean
  readonly user: User
}

function CreateContactDialog({
  canBeBillingContact,
  canBeJobSiteContact,
  customer,
  onCancel,
  onSave,
  showCanBeBillingContactToggle,
  showCanBeJobSiteContactToggle,
  user,
}: CreateContactDialogProps) {
  const { t } = useTranslation()
  const [address, setAddress] = useState<Address | null>()
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [jobTitle, setJobTitle] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [isBillingContact, setIsBillingContact] = useState<boolean>(Boolean(canBeBillingContact))
  const [isJobSiteContact, setIsJobSiteContact] = useState<boolean>(Boolean(canBeJobSiteContact))
  const [errors, setErrors] = useState(() => ({
    firstName: null,
    lastName: null,
    email: null,
  }))

  const userHqMarker = getUserHqMarker(user) // this is needed to center the address lookup

  const [createContact, { loading, error }] = useMutation(CREATE_CONTACT, {
    onCompleted: (data) => {
      onSave?.(data.createContact.contact)
    },
    onError: (error) => {
      Sentry.captureException(error)
    },
    refetchQueries: ["GetCustomerContacts"],
    awaitRefetchQueries: true,
  })

  function isValid() {
    const hasErrors = !Object.values(errors).every((o) => o === null)
    if (hasErrors) {
      return false
    }

    return (isBlank(email) || isValidEmail(email)) && !isBlank(firstName) && !isBlank(lastName)
  }

  function handleSubmit() {
    const variables = {
      firstName,
      lastName,
      jobTitle,
      email,
      phoneNumber,
      canBeBillingContact: isBillingContact,
      canBeJobSiteContact: isJobSiteContact,
      customerId: customer.id,
      address,
    }

    if (customer.id) {
      // if we're dealing with an existing customer record, then save the contact
      createContact({
        variables,
      })
    } else {
      // otherwise just pass the values on to the caller...
      onSave?.({
        ...variables,
        id: "NEW",
      })
    }
  }

  return (
    <Dialog
      aria-labelledby="create-contact-dialog"
      data-testid="CreateContactDialog"
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      open
    >
      <DialogTitle
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        {t("createContact")}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            paddingBottom: 0,
            paddingTop: "1.5rem",
            px: "1rem",
          }}
        >
          {error ? (
            <ErrorMessage message={t("error.general.message")} title={t("error.general.title")} />
          ) : null}
          <Box sx={classes.fieldContainer}>
            <FielderTextField
              autoComplete="off"
              data-testid="firstName"
              error={!!errors.firstName}
              fullWidth
              id="firstName"
              inputProps={{ maxLength: 50 }}
              label={t("firstName")}
              name="firstName"
              onBlur={() => {
                if (!firstName) {
                  setErrors({
                    ...errors,
                    firstName: t("component.createContactDialog.validation.firstName.required"),
                  })
                } else {
                  setFirstName(capitalize.words(firstName))
                  setErrors({
                    ...errors,
                    firstName: null,
                  })
                }
              }}
              onChange={(e) => {
                setFirstName(e.target.value)
              }}
              onFocus={(e) => e.target.select()}
              required
              value={firstName}
            />
            {errors.firstName ? (
              <FieldHelperText data-testid="firstNameError" error message={errors.firstName} />
            ) : null}
          </Box>
          <Box sx={classes.fieldContainer}>
            <FielderTextField
              autoComplete="off"
              data-testid="lastName"
              error={!!errors.lastName}
              fullWidth
              id="lastName"
              inputProps={{ maxLength: 50 }}
              label={t("lastName")}
              name="lastName"
              onBlur={() => {
                if (!lastName) {
                  setErrors({
                    ...errors,
                    lastName: t("component.createContactDialog.validation.lastName.required"),
                  })
                } else {
                  setLastName(capitalize.words(lastName))
                  setErrors({
                    ...errors,
                    lastName: null,
                  })
                }
              }}
              onChange={(e) => {
                setLastName(e.target.value)
              }}
              onFocus={(e) => e.target.select()}
              required
              value={lastName}
            />
            {errors.lastName ? (
              <FieldHelperText data-testid="lastNameError" error message={errors.lastName} />
            ) : null}
          </Box>
          <Box sx={classes.fieldContainer}>
            <FielderTextField
              autoComplete="off"
              data-testid="jobTitle"
              fullWidth
              id="jobTitle"
              inputProps={{ maxLength: 50 }}
              label={t("jobTitle")}
              name="jobTitle"
              onBlur={() => {
                if (jobTitle) {
                  setJobTitle(capitalize.words(jobTitle, true))
                }
              }}
              onChange={(e) => setJobTitle(e.target.value)}
              onFocus={(e) => e.target.select()}
              value={jobTitle}
            />
          </Box>
          <Box sx={classes.fieldContainer}>
            <FielderTextField
              autoComplete="off"
              data-testid="email"
              error={!!errors.email}
              fullWidth
              id="email"
              inputProps={{ maxLength: 50 }}
              label={t("email")}
              name="email"
              onBlur={() => {
                if (!isBlank(email) && !isValidEmail(email)) {
                  setErrors({
                    ...errors,
                    email: t("component.createContactDialog.validation.email.invalid"),
                  })
                } else {
                  setErrors({
                    ...errors,
                    email: null,
                  })
                }
              }}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={(e) => e.target.select()}
              value={email}
            />
            {errors.email ? (
              <FieldHelperText data-testid="emailError" error message={errors.email} />
            ) : null}
          </Box>
          <Box sx={classes.fieldContainer}>
            <PhoneNumberInput
              label={t("phoneNumber")}
              margin="normal"
              onChange={(event: { target: { value: React.SetStateAction<string> } }) => {
                setPhoneNumber(event.target.value)
              }}
              value={phoneNumber}
            />
          </Box>
          <Box sx={classes.fieldContainer}>
            <AddressAutocompleteField
              centerCoordinate={userHqMarker as Coordinate}
              label={t("address") as string}
              name="contact-address"
              onChange={(value) => setAddress(value)}
            />
          </Box>
          {showCanBeJobSiteContactToggle ? (
            <Box sx={[classes.fieldContainer, classes.checkboxContainer]}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isJobSiteContact}
                    name="canBeJobSiteContact"
                    onChange={(e) => setIsJobSiteContact(e.target.checked)}
                  />
                }
                label={t("canBeJobSiteContact") as string}
              />
              <Box sx={classes.iconContainer}>
                <HardhatIcon height={18} width={18} />
              </Box>
            </Box>
          ) : null}
          {showCanBeBillingContactToggle ? (
            <Box sx={[classes.fieldContainer, classes.checkboxContainer]}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isBillingContact}
                    name="canBeBillingContact"
                    onChange={(e) => {
                      setIsBillingContact(e.target.checked)
                    }}
                  />
                }
                label={t("canBeBillingContact") as string}
              />
              <Box sx={classes.iconContainer}>
                <AttachMoneyIcon fontSize="inherit" />
              </Box>
            </Box>
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          px: "1.5rem",
          paddingBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onCancel}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <SaveButton disabled={!isValid()} loading={loading} onClick={handleSubmit} />
      </DialogActions>
    </Dialog>
  )
}

const classes = {
  fieldContainer: {
    marginBottom: "1.25rem",
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  iconContainer: {
    fontSize: "1.125rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginRight: "0.625rem",
  },
} as const

export default CreateContactDialog
