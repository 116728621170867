import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { nanoid } from "nanoid"
import { useMutation, useQuery, gql } from "@apollo/client"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import WarningIcon from "@mui/icons-material/Warning"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/EditOutlined"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrashOutlined"
import SendIcon from "@mui/icons-material/ForwardToInboxOutlined"
import Skeleton from "@mui/material/Skeleton"
import Tooltip from "@mui/material/Tooltip"

import { ALL_INVOICES_FOR_JOB } from "~/queries/allInvoicesForJob"
import { ALL_ESTIMATES_FOR_JOB } from "~/queries/allEstimatesForJob"
import { EDIT_INVOICE } from "~/queries/editInvoice"
import { SEND_JOB_EMAIL } from "~/queries/sendJobEmail"
import { SEND_INVOICE_TO_PLUGIN } from "~/queries/sendInvoiceToPlugin"
import {
  calculateSubtotal,
  calculateTotal,
  createDayJS,
  formatDate,
  formatDiscountValueForInput,
  formatMoney,
  formatPersonName,
  getJobDocumentDisplayNumber,
  isNumeric,
  NOT_SPECIFIED,
  parseGraphQLErrorCode,
} from "~/util"
import { useAuth } from "~/context/AuthContext"
import PdfIcon from "~/components/icons/PdfIcon"
import FielderIconButton from "~/components/FielderIconButton"
import NoResultsRow from "~/components/NoResultsRow"
import SnackbarMessage from "~/components/SnackbarMessage"
import InvoiceDialog from "./InvoiceDialog"
import {
  Job,
  DefaultPermission,
  Invoice,
  Snack,
  DiscountType,
  InvoiceStatus,
  CurrencyCode,
  NetTerms,
} from "~/types"
import useGetAccountingPlugin from "~/hooks/useGetAccountingPlugin"
import EmailDialog, { EmailDialogMode } from "~/components/EmailDialog"

const GET_INVOICE_FILE = gql`
  query GetInvoiceFile($id: ID!) {
    getInvoiceById(id: $id) {
      id
      number
      pdfFile
    }
  }
`

const NUM_COLUMNS = 8

interface JobInvoicesTabProps {
  readonly job: Job
}

function JobInvoicesTab({ job }: JobInvoicesTabProps) {
  const { t } = useTranslation()
  const { user, hasPermissions, isImpersonating } = useAuth()
  const [emailDialogOpen, setEmailDialogOpen] = useState<boolean>(false)
  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState<boolean>(false)
  const [invoiceToEdit, setInvoiceToEdit] = useState<Invoice>()
  const [loadInvoiceFileId, setLoadInvoiceFileId] = useState<string>()
  const [snack, setSnack] = useState<Snack>()
  const [includeArchived, setIncludeArchived] = useState<boolean>(false)
  const [openArchiveDialog, setOpenArchiveDialog] = useState<boolean>(false)
  const location = useLocation()
  const [stateInvoiceId, setStateInvoiceId] = useState<string | undefined>(() => {
    const state = location?.state as { invoiceId?: string }
    return state?.invoiceId
  })
  const { accountingPlugin } = useGetAccountingPlugin(user?.organization?.id)
  const timeZone = user?.organization?.timeZone ?? "Etc/UTC"

  const { loading: fileLoading } = useQuery(GET_INVOICE_FILE, {
    variables: {
      id: loadInvoiceFileId,
    },
    skip: !loadInvoiceFileId,
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setLoadInvoiceFileId(undefined)
      const pdfString = data?.getInvoiceById?.pdfFile
      if (!pdfString) {
        return
      }
      const byteCharacters = atob(pdfString)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const file = new Blob([byteArray], { type: "application/pdf;base64" })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL)
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const { data: allEstimatesData } = useQuery(ALL_ESTIMATES_FOR_JOB, {
    variables: {
      id: job.id,
    },
  })

  const {
    loading: invoicesLoading,
    error,
    data,
  } = useQuery(ALL_INVOICES_FOR_JOB, {
    variables: {
      jobId: job.id,
      includeArchived,
    },
    fetchPolicy: "cache-and-network",
  })

  const [archiveInvoice, { loading: archiveInvoiceLoading }] = useMutation(EDIT_INVOICE, {
    onCompleted: () => {
      setInvoiceToEdit(undefined)
    },
    onError: (error) => {
      Sentry.captureException(error)
    },
    refetchQueries: () => {
      return ["AllInvoicesForJob"]
    },
  })

  const loading = invoicesLoading

  const canEdit = (invoice: Invoice) => {
    const hasPermission = hasPermissions?.([DefaultPermission.UpdateInvoice])
    return hasPermission && (!accountingPlugin || !invoice.externalId) && !isImpersonating
  }

  const [sendInvoiceEmail, { loading: sendInvoiceEmailLoading }] = useMutation(SEND_JOB_EMAIL, {
    onCompleted: () => {
      setEmailDialogOpen(false)
      setSnack({ messageKey: "messages.messageSent", variant: "success" })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
    refetchQueries: () => {
      return ["AllInvoicesForJob"]
    },
  })

  const [sendInvoiceToPlugin, { loading: sendInvoiceToPluginLoading }] = useMutation(
    SEND_INVOICE_TO_PLUGIN,
    {
      onCompleted: () => {
        setSnack({
          messageKey: "page.invoice.send.success",
          variant: "success",
        })
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = error ? parseGraphQLErrorCode(error) : "page.invoice.send.error"
        setSnack({
          messageKey: errorCode,
          variant: "error",
        })
      },
      refetchQueries: () => {
        return ["AllInvoicesForJob"]
      },
    }
  )

  function handleSendToPlugin(invoice: Invoice) {
    sendInvoiceToPlugin({
      variables: {
        invoiceId: invoice.id,
      },
    })
  }

  function handleEdit(invoice: Invoice) {
    const editableLineItems = invoice.lineItems
      ?.map((li) => {
        return {
          ...li,
          organizationItemId: li.organizationItem?.id,
          key: li.id,
          quantity: isNumeric(li.quantity) ? `${li.quantity}` : "",
          unitPrice: isNumeric(li.unitPrice) ? `${li.unitPrice}` : "",
          taxRateGroup: li.taxRateGroup,
          total: li.total,
        }
      })
      .sort((a, b) => (a.number ?? 0) - (b.number ?? 0))

    const discount = {
      value: invoice.discount ? formatDiscountValueForInput(invoice.discount) : 0,
      type: invoice.discount?.type ?? DiscountType.PERCENTAGE,
    }

    const editableInvoice = {
      id: invoice.id,
      job: invoice.job,
      number: invoice.number,
      currencyCode: invoice.currencyCode,
      billingContact: invoice.billingContact,
      jobAddress: invoice.job.address,
      status: invoice.status,
      discount: discount,
      issuedDate: invoice.issuedDate,
      netTerms: invoice.netTerms,
      dueDate: invoice.dueDate,
      subTotal: calculateSubtotal(invoice.lineItems),
      total: calculateTotal(invoice.lineItems, discount.value, discount.type),
      taxSummary: invoice.taxSummary,
      createdAt: invoice.createdAt,
      description: invoice.description,
      notes: invoice.notes,
      lineItems: editableLineItems,
      customerVisibility: invoice.customerVisibility,
    }

    setInvoiceToEdit(editableInvoice)
    setInvoiceDialogOpen(true)
  }

  function createNewInvoice() {
    return {
      jobAddress: job.address,
      description: job.description ?? "",
      notes: "",
      currencyCode: user?.organization?.currencyCode ?? CurrencyCode.USD,
      issuedDate: null,
      netTerms: NetTerms.NET_30, // this will be overridden by Organization default settings
      dueDate: null,
      status: InvoiceStatus.DRAFT,
      lineItems: [
        {
          number: 1,
          key: nanoid(),
          unitPrice: "",
          quantity: "1",
          organizationItemId: "",
          taxRateGroup: { id: "" },
        },
      ],
      subTotal: 0,
      discount: {
        value: "",
        type: DiscountType.PERCENTAGE,
      },
      total: 0,
      totalTax: 0,
      job,
      organization: job.organization,
    }
  }

  function renderSendInvoiceButton(invoice: Invoice) {
    if (
      accountingPlugin?.pluginProvider &&
      !isImpersonating &&
      hasPermissions?.([DefaultPermission.SendInvoice])
    ) {
      return (
        <FielderIconButton
          disabled={Boolean(sendInvoiceToPluginLoading && invoiceToEdit?.id === invoice.id)}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setInvoiceToEdit(invoice)
            handleSendToPlugin(invoice)
          }}
          title={t(`sendToPlugin.${accountingPlugin.pluginProvider.id}`) as string}
        >
          {sendInvoiceToPluginLoading && invoiceToEdit?.id === invoice.id ? (
            <CircularProgress size={20} thickness={6.0} />
          ) : (
            <img
              alt={accountingPlugin.name}
              height={20}
              src={accountingPlugin.logoPath}
              width={20}
            />
          )}
        </FielderIconButton>
      )
    } else if (!isImpersonating && hasPermissions?.([DefaultPermission.SendInvoice])) {
      return (
        <FielderIconButton
          disabled={Boolean(sendInvoiceEmailLoading && invoiceToEdit?.id === invoice.id)}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setInvoiceToEdit(invoice)
            setEmailDialogOpen(true)
          }}
          title={t("sendToCustomer") as string}
        >
          {sendInvoiceEmailLoading && invoiceToEdit?.id === invoice.id ? (
            <CircularProgress size={20} thickness={6.0} />
          ) : (
            <SendIcon />
          )}
        </FielderIconButton>
      )
    }
  }

  const invoices =
    data?.allInvoices?.edges?.map((edge) => edge.node)?.sort((a, b) => b.number - a.number) || []

  if (stateInvoiceId) {
    const invoice = invoices.find((invoice) => invoice.id === stateInvoiceId)
    if (invoice) {
      handleEdit(invoice)
      setStateInvoiceId(undefined)
    }
  }

  return (
    <>
      {invoiceDialogOpen && invoiceToEdit ? (
        <InvoiceDialog
          invoice={invoiceToEdit}
          onCancel={() => {
            setInvoiceDialogOpen(false)
          }}
          onSave={(invoice) => {
            setInvoiceDialogOpen(false)
            if (invoice) {
              setSnack({ messageKey: "messages.changesSaved", variant: "success" })
            } else {
              setSnack({ messageKey: "messages.error", variant: "error" })
            }
          }}
          open={invoiceDialogOpen}
          user={user}
        />
      ) : null}
      {emailDialogOpen && invoiceToEdit ? (
        <EmailDialog
          invoice={invoiceToEdit}
          isJobEditable={false}
          job={job}
          mode={EmailDialogMode.COMPOSE_NEW}
          onCancel={() => {
            setEmailDialogOpen(false)
          }}
          onSend={(payload) => {
            sendInvoiceEmail({ variables: payload })
          }}
          sending={sendInvoiceEmailLoading}
        />
      ) : null}
      {snack ? (
        <SnackbarMessage
          onClose={() => {
            setSnack(undefined)
          }}
          snack={snack}
        />
      ) : null}
      <Paper
        sx={{
          overflowX: "auto",
          marginBottom: "1.25rem",
          maxHeight: "500px",
          height: "31.25rem",
        }}
      >
        <Table
          stickyHeader
          sx={[
            {
              width: "100%",
              minWidth: "72rem",
            },
            invoices?.length === 0 && { height: "100%" },
          ]}
        >
          <TableHead>
            <TableRow>
              <TableCell sx={classes.tableHead}>{t("invoice")}</TableCell>
              <TableCell sx={classes.tableHead}>{t("dateCreated")}</TableCell>
              <TableCell sx={classes.tableHead}>{t("total")}</TableCell>
              <TableCell sx={classes.tableHead}>{t("status")}</TableCell>
              <TableCell sx={classes.tableHead}>{t("dueDate")}</TableCell>
              <TableCell sx={classes.tableHead}>{t("billingContact")}</TableCell>
              <TableCell sx={classes.tableHead} />
              <TableCell style={{ textAlign: "right" }} sx={classes.tableHead}>
                {!isImpersonating && hasPermissions?.([DefaultPermission.CreateInvoice]) ? (
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                    <Box sx={{ marginRight: "1rem" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={includeArchived}
                            onChange={(event, checked) => {
                              setIncludeArchived(checked)
                            }}
                            value="includeArchived"
                          />
                        }
                        label={t("includeArchived") as string}
                      />
                    </Box>
                    <Button
                      aria-label={t("createInvoice") as string}
                      color="primary"
                      data-testid="createInvoiceButton"
                      onClick={() => {
                        setInvoiceToEdit(createNewInvoice())
                        setInvoiceDialogOpen(true)
                      }}
                      sx={{
                        fontWeight: "bold",
                        "& svg": {
                          fontSize: "1.0rem",
                        },
                        "& div": {
                          marginLeft: "0.625rem",
                          marginRight: "0.625rem",
                        },
                      }}
                      variant="contained"
                    >
                      <AddIcon />
                      <Box>{t("createInvoice")}</Box>
                    </Button>
                  </Box>
                ) : null}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={invoices?.length === 0 ? { height: "100%" } : {}}>
            {!error && !loading && invoices?.length === 0 && <NoResultsRow colSpan={NUM_COLUMNS} />}
            {!error &&
              invoices?.map((node) => {
                const createdAt = createDayJS(node.createdAt, timeZone)
                const dueDate = createDayJS(node.dueDate, timeZone)
                return (
                  <TableRow
                    key={node.id}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      if (canEdit(node)) {
                        handleEdit(node)
                      } else {
                        setLoadInvoiceFileId(node.id)
                      }
                    }}
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                        backgroundColor: "#f8f8f8",
                      },
                    }}
                  >
                    <TableCell>{getJobDocumentDisplayNumber(node)}</TableCell>
                    <TableCell>
                      <Box>
                        <Box>
                          {createdAt
                            ? createdAt.format(t("format:dateFormat.shortWithAtTime") as string)
                            : NOT_SPECIFIED}
                        </Box>
                        <Box>{formatPersonName(node.createdBy)}</Box>
                      </Box>
                    </TableCell>
                    <TableCell>{formatMoney(node.currencyCode, node.total)}</TableCell>
                    <TableCell>{t(`invoiceStatus.${node.status}`)}</TableCell>
                    <TableCell>
                      {dueDate
                        ? dueDate.format(t("format:dateFormat.short") as string)
                        : NOT_SPECIFIED}
                    </TableCell>
                    <TableCell>
                      {node.billingContact ? (
                        <Box
                          style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                        >
                          <span>{formatPersonName(node.billingContact)}</span>
                          {node.billingContact.isArchived ? (
                            <Tooltip title={t("billingContactIsArchivedWarning")}>
                              <WarningIcon
                                style={{
                                  fontSize: "1rem",
                                  marginLeft: "0.625rem",
                                  color: "#f57c00",
                                }}
                              />
                            </Tooltip>
                          ) : null}
                        </Box>
                      ) : (
                        NOT_SPECIFIED
                      )}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", flexDirection: "row", gap: "2rem" }}>
                        {node.issuedDate ? (
                          <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box>{t("sentToCustomer")}</Box>
                            <Box>
                              {formatDate(
                                node.issuedDate,
                                t("format:dateFormat.shortWithAtTime"),
                                timeZone,
                                user
                              )}
                            </Box>
                          </Box>
                        ) : null}
                        {node.pluginProvider && node.dateSentToPluginProvider ? (
                          <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <a
                              href={node.pluginProviderUrl}
                              onClick={(e) => {
                                e.stopPropagation()
                              }}
                              rel="noreferrer"
                              style={{
                                textDecoration: "none",
                              }}
                              target="_blank"
                            >
                              <Box
                                sx={{
                                  display: "inline-flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: "0.15rem",
                                }}
                              >
                                <Box component="span" sx={{ textDecoration: "underline" }}>
                                  {t(`sentToPlugin.${node.pluginProvider.id}`)}
                                </Box>
                                <OpenInNewIcon sx={{ fontSize: "0.75rem" }} />
                              </Box>
                              <Box sx={{ color: (theme) => theme.fielderColors.text }}>
                                {formatDate(
                                  node.dateSentToPluginProvider,
                                  t("format:dateFormat.shortWithAtTime"),
                                  timeZone,
                                  user
                                )}
                              </Box>
                            </a>
                          </Box>
                        ) : null}
                        {node.isArchived ? (
                          <Box
                            style={{
                              display: "flex",
                              alignSelf: "center",
                              marginRight: "auto",
                              backgroundColor: "rgba(218, 21, 5, 0.1)",
                              padding: "4px 8px",
                              borderRadius: "4px",
                            }}
                          >
                            <WarningIcon
                              style={{
                                fontSize: "1.125rem",
                                color: "#DA1505",
                                marginRight: "0.625rem",
                              }}
                            />
                            <span>{t("thisInvoiceHasBeenArchived")}</span>
                          </Box>
                        ) : null}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          flexGrow: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "0.25rem",
                          }}
                        >
                          {node.status === "APPROVED" &&
                            !node.externalId &&
                            !node.isArchived &&
                            renderSendInvoiceButton(node)}
                          {!node.isArchived ? (
                            <FielderIconButton
                              disabled={
                                ((sendInvoiceEmailLoading || archiveInvoiceLoading) &&
                                  invoiceToEdit?.id === node.id) ||
                                (fileLoading && loadInvoiceFileId === node.id)
                              }
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setLoadInvoiceFileId(node.id)
                              }}
                              title={t("viewPDF") as string}
                            >
                              {fileLoading && loadInvoiceFileId === node.id ? (
                                <CircularProgress color="secondary" size={20} thickness={6.0} />
                              ) : (
                                <PdfIcon />
                              )}
                            </FielderIconButton>
                          ) : null}
                          {canEdit(node) ? (
                            <FielderIconButton
                              aria-label={t("edit") as string}
                              disabled={Boolean(
                                (sendInvoiceEmailLoading || archiveInvoiceLoading) &&
                                  invoiceToEdit?.id === node.id
                              )}
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                handleEdit(node)
                              }}
                              title={t("edit") as string}
                            >
                              <EditIcon />
                            </FielderIconButton>
                          ) : null}
                          {canEdit(node) && hasPermissions?.([DefaultPermission.UpdateInvoice]) ? (
                            <FielderIconButton
                              disabled={Boolean(
                                archiveInvoiceLoading && invoiceToEdit?.id === node.id
                              )}
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setInvoiceToEdit(node)
                                setOpenArchiveDialog(true)
                              }}
                              title={node.isArchived ? t("unarchive") : t("archive")}
                            >
                              {archiveInvoiceLoading && invoiceToEdit?.id === node.id ? (
                                <CircularProgress size={20} thickness={6.0} />
                              ) : node.isArchived ? (
                                <RestoreFromTrashIcon />
                              ) : (
                                <DeleteIcon />
                              )}
                            </FielderIconButton>
                          ) : null}
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              })}
            {loading
              ? [...Array(NUM_COLUMNS).keys()].map((i) => (
                  <TableRow key={i}>
                    {[...Array(NUM_COLUMNS).keys()].map((j) => (
                      <TableCell key={j}>
                        <Skeleton variant="text" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : null}
            {error ? (
              <TableRow>
                <TableCell align="center" colSpan={NUM_COLUMNS}>
                  <Typography color="error" variant="h6">
                    {t("errorLabel")}
                    {": "}
                    {error.message}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </Paper>
      {openArchiveDialog && invoiceToEdit ? (
        <Dialog
          aria-describedby="archive-dialog-description"
          aria-labelledby="archive-dialog-title"
          onClose={() => setOpenArchiveDialog(false)}
          open={openArchiveDialog}
        >
          <DialogTitle id="archive-dialog-title">{t("areYouSure")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="archive-dialog-description">
              {invoiceToEdit.isArchived
                ? t("component.unarchiveInvoiceDialog.confirmationPrompt", {
                    invoiceNumber: getJobDocumentDisplayNumber(invoiceToEdit),
                  })
                : t("component.archiveInvoiceDialog.confirmationPrompt", {
                    invoiceNumber: getJobDocumentDisplayNumber(invoiceToEdit),
                  })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setOpenArchiveDialog(false)}>
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              onClick={() => {
                archiveInvoice({
                  variables: {
                    id: invoiceToEdit.id,
                    jobId: job.id,
                    isArchived: !invoiceToEdit.isArchived,
                  },
                })
                setOpenArchiveDialog(false)
              }}
            >
              {t("yes")}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  )
}

const classes = {
  tableHead: {
    backgroundColor: "#f9f9f9",
    zIndex: 1000,
    position: "sticky",
    top: 0,
  },
} as const

export default JobInvoicesTab
