import React, { useState } from "react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Divider from "@mui/material/Divider"
import EditNoteIcon from "@mui/icons-material/EditNoteOutlined"
import SendOutlinedIcon from "@mui/icons-material/SendOutlined"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined"
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined"
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined"
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined"
import QueueOutlinedIcon from "@mui/icons-material/QueueOutlined"
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined"
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined"
import { CardMedia } from "@mui/material"

import { GET_JOB_LOG } from "~/queries/getJobLog"
import { capitalize, createDayJS, formatDate, formatMoney, formatPersonName } from "~/util"
import { useAuth } from "~/context/AuthContext"
import { TaskIcon, SnackbarMessage } from "~/components"
import { Snack, User, EventType, AuditEntry, Job, AttachmentPreviewSize } from "~/types"
import { FileTypeIcon } from "~/components/icons/FileTypeIcon"

function renderIconForEventType(eventType: EventType) {
  switch (eventType) {
    case "WORK_ORDER_UPDATED":
    case "WORK_ORDER_CREATED":
    case "INVOICE_UPDATED":
    case "INVOICE_CREATED":
    case "ESTIMATE_UPDATED":
    case "ESTIMATE_CREATED":
      return <ReceiptOutlinedIcon />
    case "ESTIMATE_ACCEPTED":
      return <ThumbUpAltOutlinedIcon />
    case "INVOICE_SENT":
    case "ESTIMATE_SENT":
      return <SendOutlinedIcon />
    case "INVOICE_ARCHIVED":
    case "ESTIMATE_ARCHIVED":
    case "JOB_ARCHIVED":
      return <ArchiveOutlinedIcon />
    case "JOB_NOTE_CREATED":
    case "JOB_NOTE_UPDATED":
    case "JOB_NOTE_DELETED":
    case "CHECKLIST_LINE_ITEM_NOTE_CREATED":
      return <EditNoteIcon />
    case "JOB_ATTACHMENT_CREATED":
    case "JOB_ATTACHMENT_DELETED":
      return <AttachFileOutlinedIcon />
    case "JOB_ASSIGNMENT_CREATED":
    case "JOB_ASSIGNMENT_UPDATED":
    case "JOB_ASSIGNMENT_DELETED":
      return <AssignmentIndOutlinedIcon />
    case "JOB_CREATED":
      return <QueueOutlinedIcon />
    case "JOB_UPDATED":
      return <EditOutlinedIcon />
    case "SMS_SENT":
      return <SmsOutlinedIcon />
    case "EMAIL_SENT":
    case "EMAIL_RECEIVED":
    case "JOB_WORKFLOW_STEP_EVENT_EMAIL_NOTIFICATION_SENT":
      return <MailOutlineIcon />
    case "JOB_TASK_CREATED":
    case "JOB_TASK_UPDATED":
      return <TaskIcon />
    case "CHECKLIST_CREATED":
    case "CHECKLIST_UPDATED":
      return <ListAltOutlinedIcon />
    default:
      return <Box />
  }
}

function renderEventDetail(eventData: AuditEntry, user: Partial<User>, t: any) {
  const labelStyle = {
    fontWeight: 600,
  }
  switch (eventData.eventType) {
    case "INVOICE_UPDATED":
    case "INVOICE_CREATED":
    case "INVOICE_SENT":
    case "INVOICE_ARCHIVED":
      return (
        <>
          <Box>
            <span style={labelStyle}>{t("invoiceNumber")}</span>
            {": "}
            {eventData.number}
          </Box>
          <Box>
            <span style={labelStyle}>{t("total")}</span>
            {": "}
            {formatMoney(
              user.organization?.currencyCode,
              eventData.total,
              t("format:currency.long")
            )}
          </Box>
        </>
      )
    case "ESTIMATE_UPDATED":
    case "ESTIMATE_CREATED":
    case "ESTIMATE_SENT":
    case "ESTIMATE_ARCHIVED":
    case "ESTIMATE_ACCEPTED":
      return (
        <>
          <Box>
            <span style={labelStyle}>{t("estimateNumber")}</span>
            {": "}
            {eventData.number}
          </Box>
          <Box>
            <span style={labelStyle}>{t("total")}</span>
            {": "}
            {formatMoney(
              user.organization?.currencyCode,
              eventData.total,
              t("format:currency.long")
            )}
          </Box>
        </>
      )
    case "WORK_ORDER_UPDATED":
    case "WORK_ORDER_CREATED":
      return (
        <>
          <Box>
            <span style={labelStyle}>{t("workOrderNumber")}</span>
            {": "}
            {eventData.number}
          </Box>
          <Box>
            <span style={labelStyle}>{t("status")}</span>
            {": "}
            {capitalize(eventData.status)}
          </Box>
        </>
      )
    case "JOB_NOTE_CREATED":
    case "JOB_NOTE_UPDATED":
    case "JOB_NOTE_DELETED":
    case "CHECKLIST_LINE_ITEM_NOTE_CREATED":
      return <Box>{eventData.note}</Box>
    case "JOB_ATTACHMENT_CREATED":
      const smallPreview = eventData.attachment.previews?.find(
        (p) => p.previewSize === AttachmentPreviewSize.SMALL
      )
      return (
        <Box>
          <a href={eventData.attachment.signedUrl} rel="noreferrer" target="_blank">
            {smallPreview?.signedUrl ? (
              <img
                alt={eventData.attachment.name}
                height={150}
                loading="lazy"
                src={smallPreview.signedUrl}
                style={{
                  display: "block",
                  objectPosition: "center center",
                  objectFit: "cover",
                  backgroundColor: "#212121",
                  borderRadius: "4px",
                }}
                title={eventData.attachment.name}
              />
            ) : (
              <CardMedia
                sx={{
                  height: "150px",
                  cursor: "pointer",
                }}
              >
                <FileTypeIcon
                  fileName={eventData.attachment.name}
                  style={{
                    color: "black",
                    width: "100%",
                    height: "100%",
                    padding: "1.875rem",
                    background: "#FFC601",
                  }}
                />
              </CardMedia>
            )}
          </a>
        </Box>
      )
    case "JOB_ATTACHMENT_DELETED":
      return <Box />
    case "JOB_ASSIGNMENT_CREATED":
    case "JOB_ASSIGNMENT_UPDATED":
    case "JOB_ASSIGNMENT_DELETED":
      return (
        <>
          <Box>
            <span style={labelStyle}>{t("assignedTo")}</span>
            {": "}
            {eventData.assignees?.map((a) => formatPersonName(a)).join(", ")}
          </Box>
          <Box>
            <span style={labelStyle}>{t("startDate")}</span>
            {": "}
            {formatDate(
              eventData.startDate,
              t("format:dateFormat.longWithTime"),
              user?.organization?.timeZone,
              user
            )}
          </Box>
          <Box>
            <span style={labelStyle}>{t("status")}</span>
            {": "}
            {capitalize(eventData.status)}
          </Box>
        </>
      )
    case "JOB_CREATED":
      return <Box />
    case "JOB_UPDATED":
      return (
        <Box>
          <span style={labelStyle}>{t("status")}</span>
          {": "}
          {eventData.status}
        </Box>
      )
    case "JOB_ARCHIVED":
      return <Box />
    case "SMS_SENT":
    case "EMAIL_SENT":
      return (
        <>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
            <span style={labelStyle}>{t("to")}:</span>
            <span>{eventData.recipientEmails.join(", ")}</span>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
            <span style={labelStyle}>{t("subject")}:</span>
            <span>{eventData.subject}</span>
          </Box>
          <Box sx={{ display: "flex", marginTop: "1rem" }}>
            <Box dangerouslySetInnerHTML={{ __html: eventData.body }} />
          </Box>
        </>
      )
    case "EMAIL_RECEIVED":
      return (
        <>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
            <span style={labelStyle}>{t("subject")}:</span>
            <span>{eventData.subject}</span>
          </Box>
          <Box sx={{ display: "flex", marginTop: "1rem" }}>
            <Box dangerouslySetInnerHTML={{ __html: eventData.body }} />
          </Box>
        </>
      )
    case "JOB_WORKFLOW_STEP_EVENT_EMAIL_NOTIFICATION_SENT":
      return (
        <>
          <Box>
            <span style={labelStyle}>{t("recipients")}</span>
            {": "}
            {eventData.recipientEmails}
          </Box>
          <Box>
            <span style={labelStyle}>{t("message")}</span>
            {": "}
            {eventData.message}
          </Box>
        </>
      )
    case "JOB_TASK_CREATED":
    case "JOB_TASK_UPDATED":
      return (
        <>
          <Box>
            <span style={labelStyle}>{t("task")}</span>
            {": "}
            {eventData.description}
          </Box>
          <Box>
            <span style={labelStyle}>{t("assignedTo")}</span>
            {": "}
            {eventData.assigneeName}
          </Box>
          <Box>
            <span style={labelStyle}>{t("dueDate")}</span>
            {": "}
            {formatDate(
              eventData.dueDate,
              t("format:dateFormat.short"),
              user?.organization?.timeZone,
              user
            )}
          </Box>
          <Box>
            <span style={labelStyle}>{t("status")}</span>
            {": "}
            {capitalize(eventData.status)}
          </Box>
        </>
      )
    case "CHECKLIST_CREATED":
    case "CHECKLIST_UPDATED":
      return (
        <Box>
          <span style={labelStyle}>{t("numberOfPiles")}</span>
          {": "}
          {eventData.totalLineItems}
        </Box>
      )
    default:
      return <Box />
  }
}

interface JobLogTabProps {
  readonly job: Job
}

function JobLogTab({ job }: JobLogTabProps) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [snack, setSnack] = useState<Snack>()

  const { loading, data, fetchMore } = useQuery(GET_JOB_LOG, {
    variables: {
      id: job.id,
      first: 50,
      sortDir: "DESC",
      sortBy: "createdAt",
    },
    fetchPolicy: "cache-and-network",
  })

  const auditEntries = data?.getJobById?.auditLog?.edges
    ?.map((e) => e.node)
    ?.sort((a, b) => {
      return dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1
    })
  const endCursor = data?.getJobById?.auditLog?.pageInfo?.endCursor
  const hasMore = endCursor && data?.getJobById?.auditLog?.pageInfo?.hasNextPage

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "calc(100vh - 200px)" }}>
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Paper
        sx={{
          padding: "1.25rem",
          marginTop: 0,
          marginBottom: "1rem",
          flex: 1,
          overflowY: "scroll",
        }}
      >
        {loading ? (
          <CircularProgress size={20} thickness={6.0} />
        ) : (
          <>
            {auditEntries?.map((entry, idx) => {
              const createdAt = createDayJS(entry.createdAt, user?.organization?.timeZone)
              return (
                <Box key={entry.id}>
                  <Box sx={classes.entryContainer}>
                    <Box
                      sx={{
                        marginRight: "0.625rem",
                      }}
                    >
                      {renderIconForEventType(entry.eventType)}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "0.875rem",
                      }}
                    >
                      <Box
                        sx={{
                          fontWeight: 700,
                        }}
                      >
                        {t(`eventType.${entry.eventType}`)}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          fontSize: "0.75rem",
                          color: (theme) => theme.fielderColors.mutedText,
                        }}
                      >
                        <Box sx={{ display: "flex", flexDirection: "row", gap: "0.35rem" }}>
                          <Box sx={{ display: "flex", flexDirection: "row", gap: "0.15rem" }}>
                            <span>{createdAt.format(t("format:dateFormat.short") as string)}</span>
                            <span>{createdAt.format(t("format:dateFormat.time") as string)}</span>
                          </Box>
                          <span> &bull;</span>
                          <span>
                            {entry.createdBy.id === "SYSTEM"
                              ? t("customer")
                              : formatPersonName(entry.createdBy)}
                          </span>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          fontSize: "0.8125rem",
                          marginTop: "0.625rem",
                        }}
                      >
                        {renderEventDetail(entry.eventData, user, t)}
                      </Box>
                    </Box>
                  </Box>
                  {hasMore || idx < auditEntries.length - 1 ? <Divider /> : null}
                </Box>
              )
            })}
            {hasMore ? (
              <Box
                sx={[
                  classes.entryContainer,
                  {
                    justifyContent: "center",
                    marginTop: "1.875rem",
                  },
                ]}
              >
                <Button
                  onClick={() => {
                    fetchMore({
                      variables: {
                        after: endCursor,
                      },
                      updateQuery: (previousResult, { fetchMoreResult }) => {
                        const newEntries = fetchMoreResult?.getJobById?.auditLog?.edges
                        return {
                          getJobById: {
                            ...fetchMoreResult?.getJobById,
                            auditLog: {
                              pageInfo: {
                                ...fetchMoreResult?.getJobById?.auditLog?.pageInfo,
                              },
                              edges: [
                                ...previousResult?.getJobById?.auditLog?.edges,
                                ...newEntries,
                              ],
                            },
                          },
                        }
                      },
                    })
                  }}
                  variant="text"
                >
                  {t("loadMore")}
                </Button>
              </Box>
            ) : null}
          </>
        )}
      </Paper>
    </Box>
  )
}

const classes = {
  entryContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "1.25rem",
    marginBottom: "1.25rem",
  },
}

export default JobLogTab
