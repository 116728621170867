import React from "react"
import Box from "@mui/material/Box"
import { EstimateStatus } from "~/types"
import { useTranslation } from "react-i18next"

function getBackgroundColor(estimateStatus: EstimateStatus) {
  switch (estimateStatus) {
    case EstimateStatus.ACCEPTED:
      return "#f0fdf4"
    case EstimateStatus.CHANGE_REQUESTED:
      return "#fef9c3"
    case EstimateStatus.PENDING_SUBMISSION:
      return "#f3f4f6"
    case EstimateStatus.PENDING_ACCEPTANCE:
      return "#eff6ff"
    default:
      return "white"
  }
}

function getForegroundColor(estimateStatus: EstimateStatus) {
  switch (estimateStatus) {
    case EstimateStatus.ACCEPTED:
      return "#166534"
    case EstimateStatus.CHANGE_REQUESTED:
      return "#854d0e"
    case EstimateStatus.PENDING_SUBMISSION:
      return "#1f2937"
    case EstimateStatus.PENDING_ACCEPTANCE:
      return "#1e3a8a"
    default:
      return "black"
  }
}

interface Props {
  readonly estimateStatus: EstimateStatus
}

export function EstimateStatusPill({ estimateStatus }: Props) {
  const { t } = useTranslation()

  return (
    <Box
      component="span"
      sx={{
        backgroundColor: getBackgroundColor(estimateStatus),
        color: getForegroundColor(estimateStatus),
        padding: "0.125rem 0.625rem",
        fontWeight: "600",
        fontSize: "0.875rem",
        borderRadius: "4px",
        flex: 1,
        whiteSpace: "nowrap",
      }}
    >
      {t(`estimateStatus.${estimateStatus}`)}
    </Box>
  )
}
