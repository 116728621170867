import gql from "graphql-tag"
import TaxRateGroupDetails from "./fragments/taxRateGroupDetails"
import FullEstimateLineItemDetails from "./fragments/FullEstimateLineItemDetails"

export const GET_ESTIMATE_BY_ID = gql`
  query GetEstimateById($id: ID!) {
    getEstimateById(id: $id) {
      id
      number
      currencyCode
      billingContact {
        id
        firstName
        lastName
        email
        phoneNumber
        address {
          addressString
        }
      }
      job {
        id
        number
        description
        address {
          addressString
        }
        organization {
          id
          tradeName
          address {
            addressString
          }
          timeZone
          email
          phoneNumber
          websiteURL
        }
      }
      customerVisibility {
        showExpirationDate
        showContract
        showLineItems
        showLineItemQuantity
        showLineItemUnitPrice
        showLineItemSubtotal
        showLineItemCode
        showLineItemName
        showLineItemDescription
        showLineItemBundleComponents
        showLineItemBundleComponentUnitPrice
        showLineItemBundleComponentQuantity
        showFooter
        showFooterSubtotal
        showFooterDiscount
        showFooterTaxes
        showFooterTotal
      }
      status
      description
      expirationDate
      lineItems {
        id
        number
        organizationItem {
          id
          code
          name
          type
        }
        description
        quantity
        unitPrice
        taxRateGroup {
          ...TaxRateGroupDetails
        }
        total
        showDetails
        lineItemDetails {
          ...FullEstimateLineItemDetails
        }
      }
      subTotal
      discount {
        value
        type
      }
      taxSummary {
        total
        taxRateAmounts {
          amount
          taxRate {
            id
            name
            rate
          }
        }
      }
      total
      footerTitle
      footerBody
      contractJson
      createdAt
      createdBy {
        id
      }
      updatedAt
      updatedBy {
        id
      }
    }
  }
  ${TaxRateGroupDetails}
  ${FullEstimateLineItemDetails}
`
