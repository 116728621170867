import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Divider from "@mui/material/Divider"

import { Address, Organization, User, OrganizationFormInput } from "~/types"
import FieldHelperText from "~/components/FieldHelperText"
import FielderTextField from "~/components/FielderTextField"
import AddressAutocompleteField from "~/components/AddressAutocompleteField"
import PhoneNumberInput from "~/components/CustomPhoneNumberInput"
import { formatPersonName, isBlank } from "~/util"
import { usePrompt } from "~/hooks/usePrompt"
import SaveButton from "~/components/SaveButton"
import StaticField from "~/components/StaticField"

function handleFocus(e) {
  e.target.select()
}

interface Props {
  readonly organization: Organization
  readonly loading?: boolean
  readonly onCancel: () => void
  readonly onSave: (organization: OrganizationFormInput) => void
}

function OrganizationBasicInfoInfoForm({ loading = false, organization, onCancel, onSave }: Props) {
  const { t } = useTranslation()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const tradeName = organization.tradeName ?? ""
  const [legalName, setLegalName] = useState<string>(organization.legalName ?? "")
  const [legalNameSameAsTradeName, setLegalNameSameAsTradeName] = useState<boolean>(
    organization?.legalName === organization?.tradeName
  )
  const email = organization.email ?? ""
  const [phoneNumber, setPhoneNumber] = useState<string>(organization?.phoneNumber ?? "")
  const [websiteURL, setWebsiteURL] = useState<string>(organization.websiteURL ?? "")
  const [address, setAddress] = useState<Address>(() => {
    return organization.address
      ? {
          addressString: organization.address.addressString,
          latitude: organization.address.latitude,
          longitude: organization.address.longitude,
          streetNumber: organization.address.streetNumber,
          route: organization.address.route,
          locality: organization.address.locality,
          administrativeAreaLevel1: organization.address.administrativeAreaLevel1,
          administrativeAreaLevel2: organization.address.administrativeAreaLevel2,
          postalCode: organization.address.postalCode,
          country: organization.address.country,
        }
      : { addressString: "" }
  })
  const [owner, setOwner] = useState<User>(() => organization.owner)
  const [errors, setErrors] = useState<{
    tradeName?: string | null
    legalName?: string | null
    email?: string | null
    owner?: string | null
  }>({
    tradeName: null,
    legalName: null,
    email: null, // must be valid email
    owner: null, // required
  })

  const handleChangeAddress = useCallback((val: Partial<Address> | null) => {
    setIsDirty(true)
    setAddress(val ?? { addressString: "" })
  }, [])

  const handleChangePhoneNumber = useCallback((event) => {
    setIsDirty(true)
    const val = event.target.value
    setPhoneNumber(val)
  }, [])

  const handleChangeWebsiteURL = useCallback((e) => {
    const value = e.target.value
    setWebsiteURL(value)
    setIsDirty(true)
  }, [])

  function isValid() {
    return !errors.tradeName && !errors.email && !errors.owner && !isBlank(tradeName)
  }

  function handleSubmit() {
    onSave({
      id: organization.id,
      tradeName,
      legalName: legalNameSameAsTradeName ? tradeName : legalName,
      email,
      ownerUserId: owner.id,
      timeZone: organization.timeZone,
      currencyCode: organization.currencyCode,
      websiteURL,
      phoneNumber,
      address,
    })
  }

  usePrompt(t("messages.unsavedChangesNavPrompt"), isDirty)

  return (
    <Box>
      <StaticField
        label={t("tradeName") as string}
        renderHelpText={() => (
          <FieldHelperText
            message={t("page.organizationDetails.helperText.tradeName")}
            style={{ marginLeft: 0 }}
          />
        )}
        showBottomDivider
        value={organization.tradeName}
      />
      <Box sx={[classes.fieldContainer, { marginTop: "1.25rem" }]}>
        {!legalNameSameAsTradeName ? (
          <FielderTextField
            error={!!errors.legalName}
            fullWidth
            inputProps={{ maxLength: 255 }}
            label={t("legalName")}
            margin="normal"
            name="legalName"
            onBlur={() => {
              if (isBlank(legalName)) {
                setErrors({
                  ...errors,
                  legalName: t("page.organizationDetails.validation.legalName.required"),
                })
              } else {
                if (errors.legalName) {
                  setErrors({ ...errors, legalName: null })
                }
                setLegalName(legalName)
              }
            }}
            onChange={(e) => {
              const updatedTradeName = e.target.value
              setLegalName(updatedTradeName)
              if (isBlank(updatedTradeName)) {
                setErrors({
                  ...errors,
                  legalName: t("page.organizationDetails.validation.legalName.required"),
                })
              } else if (errors.legalName) {
                setErrors({ ...errors, legalName: null })
              }
              setIsDirty(true)
            }}
            onFocus={handleFocus}
            type="legalName"
            value={legalName}
            variant="outlined"
          />
        ) : (
          <Box sx={{ fontWeight: 700, marginBottom: "0.25rem", fontSize: "0.875rem" }}>
            {t("legalName")}
          </Box>
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={legalNameSameAsTradeName}
              name="legalNameSameAsTradeName"
              onChange={(e) => {
                setLegalNameSameAsTradeName(e.target.checked)
              }}
            />
          }
          label={t("legalNameSameAsTradeName") as string}
        />
        {errors.legalName ? (
          <FieldHelperText error message={errors.legalName} />
        ) : (
          <FieldHelperText message={t("page.organizationDetails.helperText.legalName")} />
        )}
      </Box>
      <Divider />
      {organization.level >= 2 ? (
        <>
          <Box sx={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
            <StaticField
              label={t("owner") as string}
              showBottomDivider
              value={formatPersonName(organization.owner)}
            />
          </Box>
          <Box sx={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
            <StaticField
              label={t("accountManager") as string}
              showBottomDivider
              value={
                organization.accountManager ? (
                  formatPersonName(organization.accountManager)
                ) : (
                  <Box component="span" sx={{ fontSize: "0.875rem", fontStyle: "italic" }}>
                    {t("page.organizationDetails.helperText.accountManagerNotAssigned")}
                  </Box>
                )
              }
            />
          </Box>
        </>
      ) : null}
      <StaticField
        label={t("email") as string}
        renderHelpText={() => (
          <FieldHelperText
            message={t("page.organizationDetails.helperText.email")}
            style={{ marginLeft: 0 }}
          />
        )}
        showBottomDivider={false}
        style={{ marginBottom: "1.25rem" }}
        value={organization.email}
      />
      <Box sx={classes.fieldContainer}>
        <AddressAutocompleteField
          defaultValue={address?.addressString}
          label={t("address") as string}
          name="address"
          onChange={handleChangeAddress}
        />
      </Box>

      <Box sx={classes.fieldContainer}>
        <PhoneNumberInput
          label={t("phoneNumber")}
          onChange={handleChangePhoneNumber}
          value={phoneNumber}
        />
      </Box>
      <Box sx={classes.fieldContainer}>
        <FielderTextField
          data-testid="websiteURL-Field"
          fullWidth
          id="websiteURL"
          inputProps={{ maxLength: 255 }}
          label={t("websiteAddress")}
          name="websiteURL"
          onChange={handleChangeWebsiteURL}
          onFocus={handleFocus}
          type="url"
          value={websiteURL}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "3rem",
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onCancel}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <SaveButton disabled={!isValid()} loading={loading} onClick={handleSubmit} />
      </Box>
    </Box>
  )
}

const classes = {
  fieldContainer: {
    marginBottom: "1.25rem",
  },
} as const

export default OrganizationBasicInfoInfoForm
