import gql from "graphql-tag"

export const GET_ESTIMATE_REQUEST_BY_ID = gql`
  query GetEstimateRequestById($id: ID!) {
    getEstimateRequestById(id: $id) {
      id
      isArchived
      address {
        addressString
        latitude
        longitude
      }
      attachments {
        id
        name
        contentType
        signedUrl
        previews(sizes: [SMALL, MEDIUM]) {
          id
          signedUrl
          previewSize
        }
      }
      businessName
      description
      email
      firstName
      lastName
      phoneNumber
      status
      customerType
      desiredCompletionDate
      createdAt
      updatedAt
      updatedBy {
        id
        firstName
        lastName
        email
      }
    }
  }
`
