import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import FormHelperText from "@mui/material/FormHelperText"

import PhoneNumberInput from "~/components/CustomPhoneNumberInput"
import FielderTextField from "~/components/FielderTextField"
import { isBlank } from "~/util"
import { usePrompt } from "~/hooks/usePrompt"
import { User, MyProfileFormFields, LanguageCode } from "~/types"
import SaveButton from "~/components/SaveButton"
import StaticField from "~/components/StaticField"
import LanguageSelect from "~/components/LanguageSelect"
import FieldHelperText from "~/components/FieldHelperText"

interface Props {
  readonly loading?: boolean
  readonly onCancel?: () => void
  readonly onSave: (userProfile: MyProfileFormFields) => void
  readonly userProfile: User
}

function MyProfileBasicInfoForm({ userProfile, loading = false, onCancel, onSave }: Props) {
  const { t } = useTranslation()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [firstName, setFirstName] = useState<string>(userProfile?.firstName ?? "")
  const [lastName, setLastName] = useState<string>(userProfile?.lastName ?? "")
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState<string>(
    userProfile?.mobilePhoneNumber ?? ""
  )
  const [languageCode, setLanguageCode] = useState<LanguageCode | undefined | null>(
    userProfile?.languageCode
  )
  const [errors, setErrors] = useState<{
    firstName?: string | null
    lastName?: string | null
  }>({
    firstName: null,
    lastName: null,
  })
  const [touched, setTouched] = useState({
    firstName: false,
    lastName: false,
  })

  const handleBlurFirstName = () => {
    setTouched((prev) => ({
      ...prev,
      firstName: true,
    }))
    if (isBlank(firstName)) {
      setErrors((prev) => ({
        ...prev,
        firstName: t("component.myProfileBasicInfoForm.validation.firstName.required"),
      }))
    } else {
      setErrors((prev) => ({
        ...prev,
        firstName: null,
      }))
    }
  }

  const handleChangeFirstName = useCallback((e) => {
    const value = e.target.value
    setFirstName(value)
    setIsDirty(true)
    setErrors((prev) => ({
      ...prev,
      firstName: null,
    }))
  }, [])

  const handleBlurLastName = () => {
    setTouched((prev) => ({
      ...prev,
      lastName: true,
    }))
    if (isBlank(lastName)) {
      setErrors((prev) => ({
        ...prev,
        lastName: t("component.myProfileBasicInfoForm.validation.lastName.required"),
      }))
    } else {
      setErrors((prev) => ({
        ...prev,
        lastName: null,
      }))
    }
  }

  const handleChangeLastName = useCallback((e) => {
    const value = e.target.value
    setLastName(value)
    setIsDirty(true)
    setErrors((prev) => ({
      ...prev,
      lastName: null,
    }))
  }, [])

  const handleChangeMobilePhoneNumber = useCallback((event) => {
    setMobilePhoneNumber(event.target.value)
    setIsDirty(true)
  }, [])

  const handleChangeLanguageCode = useCallback((selection?: LanguageCode | null) => {
    setIsDirty(true)
    setLanguageCode(selection)
  }, [])

  function isValid() {
    return !errors.firstName && !errors.lastName && !isBlank(firstName) && !isBlank(lastName)
  }

  function handleSubmit() {
    onSave?.({ firstName, lastName, mobilePhoneNumber, languageCode })
  }

  usePrompt(t("messages.unsavedChangesNavPrompt"), isDirty)

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <StaticField label={t("email")} value={userProfile.email} />
      </Grid>
      <Grid item>
        <FielderTextField
          data-testid="firstName-Field"
          error={!!errors.firstName}
          fullWidth
          id="firstName"
          inputProps={{ maxLength: 255 }}
          label={t("firstName")}
          name="firstName"
          onBlur={handleBlurFirstName}
          onChange={handleChangeFirstName}
          onFocus={(e) => e.target.select()}
          required
          value={firstName}
        />
        {errors.firstName && touched.firstName ? (
          <FormHelperText data-testid="firstName-Error" error>
            {errors.firstName}
          </FormHelperText>
        ) : null}
      </Grid>
      <Grid item>
        <FielderTextField
          data-testid="lastName-Field"
          error={!!errors.lastName}
          fullWidth
          id="lastName"
          inputProps={{ maxLength: 255 }}
          label={t("lastName")}
          name="lastName"
          onBlur={handleBlurLastName}
          onChange={handleChangeLastName}
          onFocus={(e) => e.target.select()}
          required
          value={lastName}
        />
        {errors.lastName && touched.lastName ? (
          <FormHelperText data-testid="lastName-Error" error>
            {errors.lastName}
          </FormHelperText>
        ) : null}
      </Grid>
      <Grid item>
        <PhoneNumberInput
          label={t("mobilePhone")}
          onChange={handleChangeMobilePhoneNumber}
          value={mobilePhoneNumber}
        />
      </Grid>
      <Grid item>
        <LanguageSelect
          label={t("language") as string}
          name="language"
          onChange={handleChangeLanguageCode}
          required={false}
          value={languageCode ?? userProfile.organization?.languageCode}
        />
        <FieldHelperText message={t("component.myProfileBasicInfoForm.languageCode.helperText")} />
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1.25rem",
            paddingBottom: 0,
          }}
        >
          <Grid item>
            <Button
              color="secondary"
              data-testid="cancelButton"
              disabled={loading}
              onClick={onCancel}
              variant="outlined"
            >
              {t("cancel")}
            </Button>
          </Grid>
          <Grid item>
            <SaveButton disabled={loading || !isValid()} loading={loading} onClick={handleSubmit} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MyProfileBasicInfoForm
