import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useParams, NavigateProps, Navigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "@apollo/client"
import { Theme } from "@mui/material/styles"
import Card from "@mui/material/Card"
import CardMedia from "@mui/material/CardMedia"
import CardContent from "@mui/material/CardContent"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Divider from "@mui/material/Divider"
import Modal from "@mui/material/Modal"
import Paper from "@mui/material/Paper"
import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import SectionContent from "~/components/SectionContent"
import { ARCHIVE_ESTIMATE_REQUEST } from "~/queries/archiveEstimateRequest"
import { CREATE_JOB_FROM_ESTIMATE_REQUEST } from "~/queries/createJobFromEstimateRequest"
import { GET_ESTIMATE_REQUEST_BY_ID } from "~/queries/getEstimateRequestById"
import {
  capitalize,
  ESTIMATE_REQUESTS,
  formatAddress,
  formatPhoneNumber,
  parseGraphQLErrorCode,
  NOT_SPECIFIED,
  getFilename,
  formatDate,
} from "~/util"
import { useAuth } from "~/context/AuthContext"
import type { Attachment, EstimateRequest, Snack } from "~/types"
import { AttachmentPreviewSize, DefaultPermission } from "~/types"
import { FileTypeIcon } from "~/components/icons/FileTypeIcon"
import SectionHeader from "~/components/SectionHeader"
import FielderIconButton from "~/components/FielderIconButton"

function getContactName(estimateRequest: EstimateRequest) {
  let name = ""
  if (estimateRequest.firstName) {
    name = estimateRequest.firstName
  }

  if (estimateRequest.lastName) {
    name = `${name} ${estimateRequest.lastName}`
  }

  return name
}

function EstimateRequestDetail() {
  const { t } = useTranslation()
  const { id } = useParams()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const { hasPermissions, user } = useAuth()
  const [carouselFile, setCarouselFile] = useState<Attachment | null>()
  const [snack, setSnack] = useState<Snack>()

  const { data, loading: queryLoading } = useQuery(GET_ESTIMATE_REQUEST_BY_ID, {
    variables: { id },
  })

  const [archiveEstimateRequest, { loading: archiveMutationLoading }] = useMutation(
    ARCHIVE_ESTIMATE_REQUEST,
    {
      onCompleted: () => {
        setRedirectTo({
          to: "/app/estimate-requests",
          replace: false,
          state: {
            snack: {
              messageKey: "messages.estimateRequestDeleted",
              variant: "success",
            },
          },
        })
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({
          messageKey: errorCode,
          variant: "error",
        })
      },
    }
  )

  const [createJobFromEstimateRequest, { loading: createJobFromEstimateRequestLoading }] =
    useMutation(CREATE_JOB_FROM_ESTIMATE_REQUEST, {
      onCompleted: (data) => {
        setRedirectTo({
          to: `/app/jobs/edit/${data.createJobFromEstimateRequest.job.id}`,
          replace: true, // the Estimate Request may not be available any longer
          state: {
            snack: {
              messageKey: "messages.convertJobFromEstimateRequest.success",
              messageOptions: {
                jobNumber: data.createJobFromEstimateRequest.job.number,
              },
              variant: "success",
            },
          },
        })
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({
          messageKey: errorCode,
          variant: "error",
        })
      },
    })

  function openFile(file: Attachment) {
    setCarouselFile(file)
  }

  function closeCarousel() {
    setCarouselFile(null)
  }

  function handleDelete() {
    const variables = {
      id: estimateRequest.id,
    }
    archiveEstimateRequest({ variables })
  }

  function handleConvertToJob() {
    if (estimateRequest?.id) {
      createJobFromEstimateRequest({ variables: { id: estimateRequest.id } })
    }
  }

  const loading = queryLoading || archiveMutationLoading || createJobFromEstimateRequestLoading
  const estimateRequest = data?.getEstimateRequestById

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t("sectionTitle.estimateRequests")} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      {carouselFile ? (
        <Modal onClose={closeCarousel} open>
          <Box
            sx={{
              position: "absolute",
              width: "1200px",
              height: "1000px",
              maxWidth: "90vw",
              maxHeight: "80vh",
              backgroundColor: "#212121",
              borderRadius: 1,
              boxShadow: (theme: Theme) => theme.shadows[5],
              padding: "0.5rem",
              top: `50%`,
              left: `50%`,
              transform: `translate(-50%, -50%)`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  flexShrink: 1,
                  maxHeight: "calc(100% - 36px)",
                  width: "100%",
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={
                    carouselFile.previews?.find(
                      (p) => p.previewSize === AttachmentPreviewSize.MEDIUM
                    )?.signedUrl ?? carouselFile.signedUrl
                  }
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "calc(100% - 0.5rem)",
                    objectFit: "contain",
                    backgroundColor: "#212121",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  justifySelf: "flex-end",
                }}
              >
                <Button
                  color="primary"
                  onClick={() => window.open(carouselFile.signedUrl, "_blank")}
                  variant="contained"
                >
                  {t("openInNewTab")}
                </Button>
                <Button onClick={closeCarousel} variant="contained">
                  {t("close")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : null}
      <MainLayout activeSection={ESTIMATE_REQUESTS}>
        <Box
          sx={{
            margin: "0 1.25rem",
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: ESTIMATE_REQUESTS.path, titleKey: ESTIMATE_REQUESTS.titleKey }]}
            icon={ESTIMATE_REQUESTS.icon}
            leafTitleKey="estimateRequestDetails"
          />
          <Box sx={{ display: "flex" }}>
            <Paper sx={{ flex: 1, maxWidth: "800px" }}>
              <SectionHeader>
                <label>{t("estimateRequest")}</label>
                <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                  {hasPermissions?.([DefaultPermission.UpdateEstimateRequest]) ? (
                    <FielderIconButton
                      aria-label={t("delete") as string}
                      data-testid="deleteEstimateRequestBtn"
                      onClick={handleDelete}
                      sx={(theme) => ({
                        [theme.breakpoints.down("xs")]: {
                          marginLeft: 0,
                        },
                      })}
                      title={t("delete") as string}
                    >
                      <DeleteIcon />
                    </FielderIconButton>
                  ) : null}
                </Box>
              </SectionHeader>
              <Divider />
              <SectionContent>
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignContent: "center",
                      paddingTop: "0.125rem",
                      paddingBottom: "1.25rem",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : null}
                {!loading && estimateRequest ? (
                  <>
                    <Box sx={classes.staticFieldContainer}>
                      <label>{t("dateSubmitted")}</label>
                      <Box sx={classes.value}>
                        {formatDate(
                          estimateRequest.createdAt,
                          t("format:dateFormat.longWithTime"),
                          user?.organization?.timeZone
                        )}
                      </Box>
                      <Divider />
                    </Box>
                    <Box sx={classes.staticFieldContainer}>
                      <label>{t("customerType")}</label>
                      <Box sx={classes.value}>{capitalize(estimateRequest.customerType)}</Box>
                      <Divider />
                    </Box>
                    {estimateRequest.customerType === "BUSINESS" && (
                      <Box sx={classes.staticFieldContainer}>
                        <label>{t("companyName")}</label>
                        <Box sx={classes.value}>{capitalize(estimateRequest.businessName)}</Box>
                        <Divider />
                      </Box>
                    )}
                    <Box sx={classes.staticFieldContainer}>
                      <label>{t("contactName")}</label>
                      <Box sx={classes.value}>{getContactName(estimateRequest)}</Box>
                      <Divider />
                    </Box>
                    <Box sx={classes.staticFieldContainer}>
                      <label>{t("email")}</label>
                      <Box sx={classes.value}>{estimateRequest.email}</Box>
                      <Divider />
                    </Box>
                    <Box sx={classes.staticFieldContainer}>
                      <label>{t("phone")}</label>
                      <Box sx={classes.value}>
                        {formatPhoneNumber(estimateRequest.phoneNumber) || NOT_SPECIFIED}
                      </Box>
                      <Divider />
                    </Box>
                    <Box sx={classes.staticFieldContainer}>
                      <label>{t("jobAddress")}</label>
                      <Box sx={classes.value}>
                        {formatAddress(estimateRequest.address.addressString)}
                      </Box>
                      <Divider />
                    </Box>
                    <Box sx={classes.staticFieldContainer}>
                      <label>{t("description")}</label>
                      <Box sx={classes.value}>{estimateRequest.description}</Box>
                      <Divider />
                    </Box>
                    <Box sx={classes.staticFieldContainer}>
                      <label>{t("desiredCompletionDate")}</label>
                      <Box sx={classes.value}>
                        {estimateRequest.desiredCompletionDate
                          ? formatDate(
                              estimateRequest.desiredCompletionDate,
                              t("format:dateFormat.short"),
                              user?.organization?.timeZone
                            )
                          : NOT_SPECIFIED}
                      </Box>
                      <Divider />
                    </Box>
                    <Box sx={classes.staticFieldContainer}>
                      <label>{t("page.estimateRequest.fields.attachments.label")}</label>
                      <Box sx={classes.value}>
                        {estimateRequest.attachments?.length > 0 && (
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
                            }}
                          >
                            {estimateRequest.attachments.map((attachment: Attachment) => {
                              const smallPreview = attachment.previews?.find(
                                (p) => p.previewSize === AttachmentPreviewSize.SMALL
                              )
                              const isImage = attachment.contentType?.startsWith("image")
                              return (
                                <Card
                                  key={attachment.id}
                                  onClick={() => {
                                    if (isImage) {
                                      openFile(attachment)
                                    } else {
                                      window.open(
                                        attachment.signedUrl,
                                        "_blank",
                                        "width=1200,height=900"
                                      )
                                    }
                                  }}
                                  sx={{
                                    margin: "0.3125rem",
                                    border: "1px solid #c9c9c9",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {smallPreview?.signedUrl || isImage ? (
                                    <img
                                      alt={attachment.name}
                                      height={150}
                                      loading="lazy"
                                      src={smallPreview?.signedUrl ?? attachment.signedUrl}
                                      style={{
                                        ...classes.cardMedia,
                                        display: "block",
                                        objectPosition: isImage ? "center center" : "top center",
                                        objectFit: "cover",
                                        backgroundColor: "#212121",
                                      }}
                                      title={attachment.name}
                                    />
                                  ) : (
                                    <CardMedia sx={classes.cardMedia}>
                                      <FileTypeIcon
                                        fileName={attachment.name}
                                        style={{
                                          color: "black",
                                          width: "100%",
                                          height: "100%",
                                          padding: "1.875rem",
                                          background: "#FFC601",
                                        }}
                                      />
                                    </CardMedia>
                                  )}
                                  <CardContent sx={classes.cardContent}>
                                    <Box
                                      sx={{
                                        fontSize: "0.75rem",
                                        fontWeight: "500",
                                        color: (theme: Theme) => theme.fielderColors.black,
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      {getFilename(attachment, t)}
                                    </Box>
                                  </CardContent>
                                </Card>
                              )
                            })}
                          </Box>
                        )}
                        {estimateRequest.attachments?.length == 0 && (
                          <Box style={{ fontStyle: "italic" }}>
                            {t("page.estimateRequest.fields.attachments.noneProvided")}
                          </Box>
                        )}
                      </Box>
                      <Divider style={{ marginTop: 20 }} />
                    </Box>
                    <Box sx={classes.staticFieldContainer}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          paddingTop: "0.625rem",
                          paddingBottom: "0.625rem",
                        }}
                      >
                        <Box>
                          {hasPermissions?.([DefaultPermission.CreateJob]) ? (
                            <Button
                              color="primary"
                              data-testid="convertToJobButton"
                              onClick={handleConvertToJob}
                              sx={{
                                fontWeight: "bold",
                                "& svg": {
                                  fontSize: "1.0rem",
                                },
                                "& div": {
                                  marginLeft: "0.625rem",
                                  marginRight: "0.625rem",
                                },
                              }}
                              variant="contained"
                            >
                              <Box>{t("convertToJob")}</Box>
                            </Button>
                          ) : null}
                        </Box>
                      </Box>
                    </Box>
                  </>
                ) : null}
              </SectionContent>
            </Paper>
          </Box>
        </Box>
      </MainLayout>
    </>
  )
}

const classes = {
  staticFieldContainer: {
    padding: "1.25rem",
    paddingTop: "0.3125rem",
    paddingBottom: "0.3125rem",
    "& label": {
      fontWeight: 700,
      marginBottom: "0.25rem",
    },
    "& hr": {
      marginTop: "0.625rem",
    },
  },
  value: {
    // color: theme.palette.grey[700],
    whiteSpace: "pre-wrap",
  },
  cardContent: {
    paddingLeft: "0.75rem",
    paddingRight: "0.25rem",
    paddingTop: "0.25rem",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,

    // override MUI styling
    "&:last-child": {
      paddingBottom: "0.25rem",
    },
  },
  cardMedia: {
    height: "9rem",
    cursor: "pointer",
  },
} as const

export default EstimateRequestDetail
