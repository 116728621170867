/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useMemo } from "react"
import { Navigate, useLocation, NavigateProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useQuery, useMutation } from "@apollo/client"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import TableContainer from "@mui/material/TableContainer"
import TablePagination from "@mui/material/TablePagination"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Skeleton from "@mui/material/Skeleton"
import EditIcon from "@mui/icons-material/EditOutlined"
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined"
import AddIcon from "@mui/icons-material/AddOutlined"
import LoginIcon from "@mui/icons-material/LoginOutlined"

import ConfirmationDialog from "~/components/ConfirmationDialog"
import EmptyState from "~/components/EmptyState"
import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import SearchField from "~/components/SearchField"
import FielderIconButton from "~/components/FielderIconButton"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import SortableTableHeader from "~/components/SortableTableHeader"
import TablePaginationActions from "~/components/TablePaginationActions"
import { ALL_ORGANIZATIONS } from "~/queries/allOrganizations"
import { LOGIN_AS_FRANCHISEE } from "~/queries/loginAsFranchisee"
import {
  formatPersonName,
  formatPhoneNumber,
  isBlank,
  FRANCHISEES,
  NOT_SPECIFIED,
  useDebounce,
} from "~/util"
import { useAuth } from "~/context/AuthContext"
import {
  DefaultPermission,
  Organization,
  OrganizationStatus,
  Snack,
  SortDirection as SortDirectionType,
} from "~/types"
import useStore, {
  franchiseeListFiltersSelector,
  setFranchiseeListFiltersSelector,
  franchiseeListSettingsSelector,
  setFranchiseeListSettingsSelector,
} from "~/store"
import StatusPill from "./components/OrganizationStatusPill"
import NoResultsRow from "~/components/NoResultsRow"
import UserAvatar from "~/components/UserAvatar"
import FilterMenu, { FilterMenuOption } from "~/components/FilterMenu"
import AccountManagerFilterMenu from "~/components/AccountManagerFilterMenu"
import RegionFilterMenu from "~/components/RegionFilterMenu"

const NUM_COLUMNS = 7
const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100]

// breakpoints for responsive layout
const BREAKPOINT_1 = 600

function FranchiseeListPage() {
  const { hasPermissions, enterImpersonateMode } = useAuth()
  const franchiseeListFilters = useStore(franchiseeListFiltersSelector)
  const setFranchiseeListFilters = useStore(setFranchiseeListFiltersSelector)
  const franchiseeListSettings = useStore(franchiseeListSettingsSelector)
  const setFranchiseeListSettings = useStore(setFranchiseeListSettingsSelector)
  const location = useLocation()
  const { t } = useTranslation()
  const [selectedFranchisee, setSelectedFranchisee] = useState<Organization | undefined>()
  const [showLoginAsPrompt, setShowLoginAsPrompt] = useState<boolean>(false)
  const [snack, setSnack] = useState<Snack | undefined>(() => {
    const { state } = location
    return state?.snack
  })
  const [after, setAfter] = useState<number | null>()
  const [before, setBefore] = useState<number | null>()
  const [first, setFirst] = useState<number | null>(franchiseeListSettings.rowsPerPage)
  const [last, setLast] = useState<number | null>()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialSearchTerm = useMemo(() => franchiseeListSettings.searchTerm, [])
  const [filter, setFilter] = useState<string>(initialSearchTerm)
  const debouncedSearchTerm = useDebounce(filter, 500)
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const [statusFilterMenuAnchorEl, setStatusFilterMenuAnchorEl] = useState<
    EventTarget & HTMLButtonElement
  >()
  const showStatusFiltersMenu = Boolean(statusFilterMenuAnchorEl)
  const [regionFilterMenuAnchorEl, setRegionFilterMenuAnchorEl] = useState<
    EventTarget & HTMLButtonElement
  >()
  const showRegionFiltersMenu = Boolean(regionFilterMenuAnchorEl)
  const [accountManagerFilterMenuAnchorEl, setAccountManagerFilterMenuAnchorEl] = useState<
    EventTarget & HTMLButtonElement
  >()
  const showAccountManagerFiltersMenu = Boolean(accountManagerFilterMenuAnchorEl)

  useEffect(() => {
    if (debouncedSearchTerm !== franchiseeListSettings.searchTerm) {
      setFranchiseeListSettings({
        ...franchiseeListSettings,
        searchTerm: debouncedSearchTerm,
      })
      setAfter(null)
      setBefore(null)
      setFirst(franchiseeListSettings.rowsPerPage)
      setLast(null)
    }
  }, [debouncedSearchTerm, franchiseeListSettings, setFranchiseeListSettings])

  const { loading, error, data } = useQuery(ALL_ORGANIZATIONS, {
    variables: {
      filter: debouncedSearchTerm,
      after,
      before,
      first,
      last,
      sortBy: franchiseeListSettings.sortBy,
      sortDir: franchiseeListSettings.sortDir,
      statuses: franchiseeListFilters.statuses,
      regionIds: franchiseeListFilters.regionIds,
      accountManagerIds: franchiseeListFilters.accountManagerIds,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    context: {
      debounceKey: "ALL_FRANCHISEES",
      debounceTimeout: 50,
    },
  })

  const [loginAsFranchisee, { loading: loginAsFranchiseeLoading }] = useMutation(
    LOGIN_AS_FRANCHISEE,
    {
      onCompleted: (data) => {
        const { impersonateFranchisee } = data
        setShowLoginAsPrompt(false)
        enterImpersonateMode?.(impersonateFranchisee.organization, impersonateFranchisee.authToken)
      },
    }
  )

  function onGoToFirstPage() {
    setAfter(null)
    setBefore(null)
    setFirst(franchiseeListSettings.rowsPerPage)
    setLast(null)
  }

  function onGoToLastPage() {
    setAfter(null)
    setBefore(null)
    setFirst(null)
    setLast(franchiseeListSettings.rowsPerPage)
  }

  function onGoToPrevious() {
    setAfter(null)
    setBefore(pageInfo.startCursor)
    setFirst(null)
    setLast(franchiseeListSettings.rowsPerPage)
  }

  function onGoToNext() {
    setAfter(pageInfo.endCursor)
    setBefore(null)
    setFirst(franchiseeListSettings.rowsPerPage)
    setLast(null)
  }

  function handleChangeRowsPerPage(event: any) {
    const newPageSize = +event.target.value

    setFranchiseeListSettings({
      ...franchiseeListSettings,
      rowsPerPage: newPageSize,
      currentPage: 0,
    })

    setAfter(null)
    setBefore(null)
    setFirst(newPageSize)
    setLast(null)
  }

  function sort(propertyName: string) {
    const newSortBy = propertyName
    const newSortDir =
      propertyName !== franchiseeListSettings.sortBy
        ? SortDirectionType.ASC
        : franchiseeListSettings.sortDir === SortDirectionType.ASC
          ? SortDirectionType.DESC
          : SortDirectionType.ASC

    setFranchiseeListSettings({
      ...franchiseeListSettings,
      sortBy: newSortBy,
      sortDir: newSortDir,
      currentPage: 0,
    })

    setAfter(null)
    setBefore(null)
    setFirst(franchiseeListSettings.rowsPerPage)
    setLast(null)
  }

  function handleRowClick(id: string) {
    setRedirectTo({ to: `/app/franchisees/edit/${id}`, replace: false })
  }

  const pageInfo = data?.allOrganizations?.pageInfo || {}
  const franchisees = data?.allOrganizations?.edges
    ?.filter((edge) => edge.node.level > 1)
    ?.map((edge) => edge.node)

  const statusFilterOptions = [
    {
      value: OrganizationStatus.DRAFT,
      label: t(`organizationStatusOption.DRAFT`),
    },
    {
      value: OrganizationStatus.PENDING,
      label: t(`organizationStatusOption.PENDING`),
    },
    {
      value: OrganizationStatus.ACTIVE,
      label: t(`organizationStatusOption.ACTIVE`),
    },
    {
      value: OrganizationStatus.SUSPENDED,
      label: t(`organizationStatusOption.SUSPENDED`),
    },
    {
      value: OrganizationStatus.ARCHIVED,
      label: t(`organizationStatusOption.ARCHIVED`),
    },
  ]

  function areFiltersApplied(): boolean {
    return (
      !isBlank(filter) ||
      franchiseeListFilters.statuses?.length > 0 ||
      franchiseeListFilters.regionIds?.length > 0 ||
      franchiseeListFilters.accountManagerIds?.length > 0
    )
  }

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t(FRANCHISEES.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={FRANCHISEES}>
        <Box
          sx={(theme) => {
            return {
              margin: "0 0.5rem",
              paddingBottom: "3rem",
              [theme.breakpoints.up("md")]: {
                // margin: "0 1.25rem",
                // marginRight: "80px", // make sure the intercom chat bubble doesn't block the pagination controls
                paddingBottom: "12.5rem",
              },
            }
          }}
        >
          <PageHeader icon={FRANCHISEES.icon} leafTitleKey={FRANCHISEES.titleKey} />
          <Paper
            sx={{
              overflowX: "auto",
              minHeight: "calc(100vh - 200px)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1.25rem",
                height: "100%",
                flex: 1,
              }}
            >
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  padding: "1rem",
                  gap: "0.625rem",
                  [theme.breakpoints.up(BREAKPOINT_1)]: {
                    flexDirection: "row",
                  },
                })}
              >
                <Box
                  sx={(theme) => ({
                    flex: 1,
                    [theme.breakpoints.up(BREAKPOINT_1)]: {
                      maxWidth: "300px",
                      marginTop: 0,
                    },
                  })}
                >
                  <SearchField
                    onChange={setFilter}
                    placeholder={t("searchFranchisees")}
                    sx={{ borderRadius: "4px" }}
                    term={filter}
                    testID="SearchField"
                  />
                </Box>
                <Box
                  sx={(theme) => ({
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: "1rem",
                    [theme.breakpoints.up(BREAKPOINT_1)]: {
                      justifyContent: "flex-start",
                    },
                  })}
                >
                  <FilterMenu
                    anchorEl={statusFilterMenuAnchorEl}
                    id="status-filters"
                    isOpen={showStatusFiltersMenu}
                    label={`${t("status")}`}
                    mutuallyExclusive={false}
                    onClickOption={(option: FilterMenuOption) => {
                      const status = option.value as OrganizationStatus
                      setFranchiseeListFilters({
                        ...franchiseeListFilters,
                        statuses: franchiseeListFilters.statuses?.includes(status)
                          ? franchiseeListFilters.statuses.filter((t) => t !== status)
                          : [...(franchiseeListFilters.statuses ?? []), status],
                      })
                    }}
                    onClose={() => setStatusFilterMenuAnchorEl(undefined)}
                    onOpen={(event) => {
                      setStatusFilterMenuAnchorEl(event.currentTarget)
                    }}
                    options={statusFilterOptions}
                    selectedOptions={franchiseeListFilters.statuses ?? []}
                  />
                  <AccountManagerFilterMenu
                    anchorEl={accountManagerFilterMenuAnchorEl}
                    id="account-manager-filters"
                    isOpen={showAccountManagerFiltersMenu}
                    label={`${t("accountManager")}`}
                    mutuallyExclusive={false}
                    onClickOption={(option: FilterMenuOption) => {
                      const accountManagerId = option.value as string
                      setFranchiseeListFilters({
                        ...franchiseeListFilters,
                        accountManagerIds: franchiseeListFilters.accountManagerIds?.includes(
                          accountManagerId
                        )
                          ? franchiseeListFilters.accountManagerIds.filter(
                              (t) => t !== accountManagerId
                            )
                          : [...(franchiseeListFilters.accountManagerIds ?? []), accountManagerId],
                      })
                    }}
                    onClose={() => setAccountManagerFilterMenuAnchorEl(undefined)}
                    onOpen={(event) => {
                      setAccountManagerFilterMenuAnchorEl(event.currentTarget)
                    }}
                    selectedOptions={franchiseeListFilters.accountManagerIds ?? []}
                  />
                  <RegionFilterMenu
                    anchorEl={regionFilterMenuAnchorEl}
                    id="region-filters"
                    isOpen={showRegionFiltersMenu}
                    label={`${t("region")}`}
                    mutuallyExclusive={false}
                    onClickOption={(option: FilterMenuOption) => {
                      const regionId = option.value as string
                      setFranchiseeListFilters({
                        ...franchiseeListFilters,
                        regionIds: franchiseeListFilters.regionIds?.includes(regionId)
                          ? franchiseeListFilters.regionIds.filter((t) => t !== regionId)
                          : [...(franchiseeListFilters.regionIds ?? []), regionId],
                      })
                    }}
                    onClose={() => setRegionFilterMenuAnchorEl(undefined)}
                    onOpen={(event) => {
                      setRegionFilterMenuAnchorEl(event.currentTarget)
                    }}
                    selectedOptions={franchiseeListFilters.regionIds ?? []}
                  />
                  {areFiltersApplied() ? (
                    <Button
                      onClick={() => {
                        setFilter("")
                        setFranchiseeListFilters({
                          statuses: [],
                          accountManagerIds: [],
                          regionIds: [],
                        })
                      }}
                      sx={{ textDecoration: "underline" }}
                      variant="text"
                    >
                      {t("clearFilters")}
                    </Button>
                  ) : null}
                </Box>
                {hasPermissions?.([DefaultPermission.CreateOrganization]) ? (
                  <Box
                    sx={(theme) => ({
                      flex: 1,
                      [theme.breakpoints.up(BREAKPOINT_1)]: {
                        maxWidth: "200px",
                        marginTop: 0,
                      },
                    })}
                  >
                    <Button
                      aria-label={t("createFranchisee") as string}
                      color="primary"
                      data-testid="createFranchiseeButton"
                      onClick={() => {
                        setRedirectTo({ to: "/app/franchisees/create", replace: false })
                      }}
                      sx={{
                        width: "100%",
                        fontWeight: "bold",
                        "& svg": {
                          fontSize: "1.0rem",
                        },
                        "& div": {
                          marginLeft: "0.625rem",
                          marginRight: "0.625rem",
                        },
                      }}
                      variant="contained"
                    >
                      <AddIcon fontSize="small" />
                      <div>{t("createFranchisee")}</div>
                    </Button>
                  </Box>
                ) : null}
              </Box>
              {!error && !loading && franchisees?.length === 0 && (
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      margin: "0 1rem",
                      padding: "1.875rem",
                      backgroundColor: "#f3f4f6", // Tailwind Gray 100
                      alignSelf: "center",
                      justifySelf: "center",
                      borderRadius: "4px",
                    }}
                  >
                    {isBlank(franchiseeListSettings.searchTerm) && !areFiltersApplied() ? (
                      <EmptyState title={t("page.franchiseeList.emptyState.title")}>
                        {hasPermissions?.([DefaultPermission.CreateOrganization]) ? (
                          <Box>{t("page.franchiseeList.emptyState.message")}</Box>
                        ) : null}
                      </EmptyState>
                    ) : (
                      <EmptyState title={t("page.franchiseeList.noMatchingResults.title")}>
                        <Box>{t("page.franchiseeList.noMatchingResults.message")}</Box>
                      </EmptyState>
                    )}
                  </Box>
                </Box>
              )}
              {!error && (loading || franchisees?.length > 0) ? (
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <TableContainer
                    sx={{
                      flex: 1,
                      height: "100%",
                      maxHeight: "100%",
                      overflowY: "auto",
                    }}
                  >
                    <Table
                      stickyHeader
                      sx={[
                        {
                          width: "100%",
                          minWidth: "1024px",
                          overflowX: "scroll",
                          "& td": {
                            padding: "0.5rem",
                            "&:first-child": {
                              paddingLeft: "1rem",
                            },
                            "&:last-child": {
                              paddingRight: "1rem",
                            },
                          },
                          "& th": {
                            lineHeight: "inherit",
                            fontSize: "0.8rem",
                            padding: "0.5rem",
                            "&:first-child": {
                              paddingLeft: "1rem",
                            },
                            "&:last-child": {
                              paddingRight: "1rem",
                            },
                          },
                        },
                        franchisees?.length === 0 && { height: "100%" },
                      ]}
                    >
                      <TableHead>
                        <TableRow>
                          <SortableTableHeader
                            isActiveSort={franchiseeListSettings.sortBy === "tradeName"}
                            label={t("tradeName")}
                            onClick={() => sort("tradeName")}
                            sortDir={franchiseeListSettings.sortDir}
                            sx={{
                              backgroundColor: "white",
                              minWidth: "18rem",
                              paddingLeft: "1rem",
                            }}
                          />
                          <SortableTableHeader
                            isActiveSort={franchiseeListSettings.sortBy === "status"}
                            label={t("status")}
                            onClick={() => sort("status")}
                            sortDir={franchiseeListSettings.sortDir}
                            sx={{ backgroundColor: "white" }}
                          />
                          <TableCell sx={{ backgroundColor: "white", whiteSpace: "nowrap" }}>
                            <Box>{t("accountManager")}</Box>
                          </TableCell>
                          <SortableTableHeader
                            isActiveSort={franchiseeListSettings.sortBy === "region.name"}
                            label={t("region")}
                            onClick={() => sort("region.name")}
                            sortDir={franchiseeListSettings.sortDir}
                            sx={{ backgroundColor: "white" }}
                          />
                          <SortableTableHeader
                            isActiveSort={franchiseeListSettings.sortBy === "phone"}
                            label={t("phone")}
                            onClick={() => sort("phoneNumber")}
                            sortDir={franchiseeListSettings.sortDir}
                            sx={{ backgroundColor: "white" }}
                          />
                          <TableCell sx={[classes.tableHead, {}]}>{t("owner")}</TableCell>
                          <TableCell sx={[classes.tableHead, {}]} />
                        </TableRow>
                      </TableHead>
                      <TableBody sx={franchisees?.length == 0 ? { height: "100%" } : {}}>
                        {!error && !loading && franchisees?.length === 0 && (
                          <NoResultsRow colSpan={NUM_COLUMNS} />
                        )}
                        {!error &&
                          !loading &&
                          franchisees?.map((node) => {
                            return (
                              <TableRow
                                key={node.id}
                                onClick={() => handleRowClick(node.id)}
                                sx={[
                                  classes.dataRow,
                                  hasPermissions?.([DefaultPermission.UpdateUser])
                                    ? {
                                        "&:hover": {
                                          cursor: "pointer",
                                          backgroundColor: "#f8f8f8",
                                        },
                                      }
                                    : null,
                                ]}
                              >
                                <TableCell>{node.tradeName}</TableCell>
                                <TableCell>
                                  <Box>
                                    <StatusPill status={node.status} />
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "0.5rem",
                                      alignItems: "center",
                                    }}
                                  >
                                    {node.accountManager ? (
                                      <UserAvatar size="24px" user={node.accountManager} />
                                    ) : null}
                                    <Box sx={{ whiteSpace: "nowrap" }}>
                                      {node.accountManager
                                        ? formatPersonName(node.accountManager)
                                        : NOT_SPECIFIED}
                                    </Box>
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  <Box sx={{ whiteSpace: "nowrap" }}>{node.region.name}</Box>
                                </TableCell>
                                <TableCell>
                                  <Box sx={{ whiteSpace: "nowrap" }}>
                                    {node.phoneNumber
                                      ? formatPhoneNumber(node.phoneNumber)
                                      : NOT_SPECIFIED}
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  <Box>{formatPersonName(node.owner)}</Box>
                                  <Box
                                    sx={(theme) => ({
                                      whiteSpace: "nowrap",
                                      fontSize: "0.75rem",
                                      color: theme.fielderColors.mutedText,
                                    })}
                                  >
                                    {node.owner?.email ?? NOT_SPECIFIED}
                                  </Box>
                                </TableCell>
                                <TableCell align="right" sx={{ paddingRight: "1rem" }}>
                                  <Box sx={classes.rowButtonContainer}>
                                    {node.status === OrganizationStatus.ACTIVE &&
                                    hasPermissions?.([
                                      DefaultPermission.ImpersonateOrganization,
                                    ]) ? (
                                      <FielderIconButton
                                        aria-label={t("loginAsFranchisee") as string}
                                        onClick={(e) => {
                                          e.preventDefault()
                                          e.stopPropagation()
                                          setSelectedFranchisee(node)
                                          setShowLoginAsPrompt(true)
                                        }}
                                        sx={classes.button}
                                        title={t("impersonateFranchisee") as string}
                                      >
                                        <LoginIcon />
                                      </FielderIconButton>
                                    ) : null}
                                    {hasPermissions?.([DefaultPermission.UpdateOrganization]) ? (
                                      <FielderIconButton
                                        aria-label={t("editFranchisee") as string}
                                        sx={classes.button}
                                        title={t("editFranchisee") as string}
                                      >
                                        <EditIcon />
                                      </FielderIconButton>
                                    ) : (
                                      <FielderIconButton
                                        aria-label={t("viewFranchisee") as string}
                                        sx={classes.button}
                                        title={t("viewFranchisee") as string}
                                      >
                                        <VisibilityIcon />
                                      </FielderIconButton>
                                    )}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        {loading
                          ? [...Array(franchiseeListSettings.rowsPerPage).keys()].map((i) => (
                              <TableRow key={i} sx={classes.dataRow}>
                                {[...Array(NUM_COLUMNS).keys()].map((j) => (
                                  <TableCell key={j}>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))
                          : null}
                        {error ? (
                          <TableRow>
                            <TableCell align="center" colSpan={NUM_COLUMNS}>
                              <Box
                                style={{
                                  color: "rgb(244, 67, 54)",
                                  fontWeight: 500,
                                  fontSize: "1.25rem",
                                  lineHeight: 1.6,
                                }}
                              >
                                {t("errorLabel")}
                                {": "}
                                {error.message}
                              </Box>
                            </TableCell>
                          </TableRow>
                        ) : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    ActionsComponent={() => (
                      <TablePaginationActions
                        hasNextPage={pageInfo.hasNextPage ?? false}
                        hasPreviousPage={pageInfo.hasPreviousPage ?? false}
                        onGoToFirstPage={onGoToFirstPage}
                        onGoToLastPage={onGoToLastPage}
                        onGoToNext={onGoToNext}
                        onGoToPrevious={onGoToPrevious}
                      />
                    )}
                    component="div"
                    count={-1}
                    labelDisplayedRows={() => ``}
                    labelRowsPerPage={t("rowsPerPage") as string}
                    onPageChange={() => {}}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    page={franchiseeListSettings.currentPage}
                    rowsPerPage={franchiseeListSettings.rowsPerPage}
                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                  />
                </Box>
              ) : null}
            </Box>
          </Paper>
        </Box>
      </MainLayout>
      {showLoginAsPrompt && selectedFranchisee ? (
        <ConfirmationDialog
          description={t("component.loginAsFranchiseeConfirmation.message", {
            franchiseeName: selectedFranchisee.tradeName,
          })}
          id="login-as-franchisee-confirmation"
          isLoading={loginAsFranchiseeLoading}
          negativeButtonTitle={t("cancel")}
          onCancel={() => {
            setSelectedFranchisee(undefined)
            setShowLoginAsPrompt(false)
          }}
          onConfirm={() => {
            loginAsFranchisee({ variables: { organizationId: selectedFranchisee.id } })
          }}
          positiveButtonTitle={t("proceed")}
          title={t("component.loginAsFranchiseeConfirmation.title", {
            franchiseeName: selectedFranchisee.tradeName,
          })}
          titleBackgroundColor="#ffffff"
        />
      ) : null}
    </>
  )
}

const classes = {
  tableHead: {
    zIndex: 1000,
    position: "sticky",
    top: 0,
    backgroundColor: "white",
  },
  dataRow: {
    height: "3.75rem",
  },
  rowButtonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    flexWrap: "nowrap",
    paddingLeft: "1rem",
  },
  button: {
    marginLeft: "0.625rem",
    "& span": { whiteSpace: "nowrap" },
  },
} as const

export default FranchiseeListPage
